import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoHangLoTrinh } from './LoHangLoTrinh.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class LoHangLoTrinhService extends BaseService{

    DisplayColumns001: string[] = ['STT', 'Name', 'KinhDo', 'ViDo', 'Note', 'Save'];
        
    List: LoHangLoTrinh[] | undefined;
    ListFilter: LoHangLoTrinh[] | undefined;
    FormData!: LoHangLoTrinh;
    
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "LoHangLoTrinh";
    }
}

