import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditorModule } from 'ngx-ckeditor';
import { ChartsModule } from 'ng2-charts';
import { CookieService } from 'ngx-cookie-service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MaterialModule } from './material/material.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { NotificationService } from './shared/Notification.service';
import { AppComponent } from './app.component';
import { LoadingComponent } from './loading/loading.component';
import { HomepageComponent } from './homepage/homepage.component';
import { LoginComponent } from './login/login.component';
import { DanhMucThanhVienComponent } from './danh-muc-thanh-vien/danh-muc-thanh-vien.component';
import { ThanhVienComponent } from './thanh-vien/thanh-vien.component';
import { ThanhVienChucNangComponent } from './thanh-vien-chuc-nang/thanh-vien-chuc-nang.component';
import { ThanhVienLichSuTruyCapComponent } from './thanh-vien-lich-su-truy-cap/thanh-vien-lich-su-truy-cap.component';
import { ThanhVienTokenComponent } from './thanh-vien-token/thanh-vien-token.component';
import { DanhMucChucNangComponent } from './danh-muc-chuc-nang/danh-muc-chuc-nang.component';
import { ThanhVienDetailComponent } from './thanh-vien-detail/thanh-vien-detail.component';
import { ThanhVienThongTinComponent } from './thanh-vien-thong-tin/thanh-vien-thong-tin.component';
import { HuongDanComponent } from './huong-dan/huong-dan.component';
import { GioiThieuComponent } from './gioi-thieu/gioi-thieu.component';
import { ThanhVienChucNangDetailComponent } from './thanh-vien-chuc-nang-detail/thanh-vien-chuc-nang-detail.component';
import { UploadComponent } from './upload/upload.component';
import { ThanhVien002Component } from './thanh-vien002/thanh-vien002.component';
import { ThanhVienDetail002Component } from './thanh-vien-detail002/thanh-vien-detail002.component';
import { DanhMucChungChiComponent } from './danh-muc-chung-chi/danh-muc-chung-chi.component';
import { DanhMucDanTocComponent } from './danh-muc-dan-toc/danh-muc-dan-toc.component';
import { DanhMucNgonNguComponent } from './danh-muc-ngon-ngu/danh-muc-ngon-ngu.component';
import { ThanhVienVungTrongToaDoDetailComponent } from './thanh-vien-vung-trong-toa-do-detail/thanh-vien-vung-trong-toa-do-detail.component';
import { TruyXuatNguonGocKhoTemComponent } from './truy-xuat-nguon-goc-kho-tem/truy-xuat-nguon-goc-kho-tem.component';
import { TruyXuatNguonGocKhoTem001Component } from './truy-xuat-nguon-goc-kho-tem001/truy-xuat-nguon-goc-kho-tem001.component';
import { TruyXuatNguonGocLoHangComponent } from './truy-xuat-nguon-goc-lo-hang/truy-xuat-nguon-goc-lo-hang.component';
import { TruyXuatNguonGocLoHangDetailComponent } from './truy-xuat-nguon-goc-lo-hang-detail/truy-xuat-nguon-goc-lo-hang-detail.component';
import { TruyXuatNguonGocLoHangMapComponent } from './truy-xuat-nguon-goc-lo-hang-map/truy-xuat-nguon-goc-lo-hang-map.component';
import { TruyXuatNguonGocTemPhieuKhoiTaoComponent } from './truy-xuat-nguon-goc-tem-phieu-khoi-tao/truy-xuat-nguon-goc-tem-phieu-khoi-tao.component';
import { TruyXuatNguonGocTemPhieuKhoiTaoDetailComponent } from './truy-xuat-nguon-goc-tem-phieu-khoi-tao-detail/truy-xuat-nguon-goc-tem-phieu-khoi-tao-detail.component';
import { TruyXuatNguonGocTemPhieuLichSuTruyCapDetailComponent } from './truy-xuat-nguon-goc-tem-phieu-lich-su-truy-cap-detail/truy-xuat-nguon-goc-tem-phieu-lich-su-truy-cap-detail.component';
import { DanhMucVungTrongComponent } from './danh-muc-vung-trong/danh-muc-vung-trong.component';
import { TruyXuatNguonGocLoHangDetailRutGonComponent } from './truy-xuat-nguon-goc-lo-hang-detail-rut-gon/truy-xuat-nguon-goc-lo-hang-detail-rut-gon.component';
import { ThanhVienVungTrongDetailComponent } from './thanh-vien-vung-trong-detail/thanh-vien-vung-trong-detail.component';
import { TruyXuatNguonGocLoHangInfoComponent } from './truy-xuat-nguon-goc-lo-hang-info/truy-xuat-nguon-goc-lo-hang-info.component';
import { TruyXuatNguonGocTemPhieuKhoiTaoDetail001Component } from './truy-xuat-nguon-goc-tem-phieu-khoi-tao-detail001/truy-xuat-nguon-goc-tem-phieu-khoi-tao-detail001.component';
import { TruyXuatNguonGocLoHangDetailRutGon001Component } from './truy-xuat-nguon-goc-lo-hang-detail-rut-gon001/truy-xuat-nguon-goc-lo-hang-detail-rut-gon001.component';
import { TruyXuatNguonGocDanhMucPhuongThucMuaBanComponent } from './truy-xuat-nguon-goc-danh-muc-phuong-thuc-mua-ban/truy-xuat-nguon-goc-danh-muc-phuong-thuc-mua-ban.component';
import { TruyXuatNguonGocLoHangMuaBanComponent } from './truy-xuat-nguon-goc-lo-hang-mua-ban/truy-xuat-nguon-goc-lo-hang-mua-ban.component';
import { DanhMucBaiVietComponent } from './danh-muc-bai-viet/danh-muc-bai-viet.component';
import { BaiVietComponent } from './bai-viet/bai-viet.component';
import { BaiVietDetailComponent } from './bai-viet-detail/bai-viet-detail.component';
import { BanDoComponent } from './ban-do/ban-do.component';
import { BanDo001Component } from './ban-do001/ban-do001.component';
import { BanDo002Component } from './ban-do002/ban-do002.component';
import { BanDo003Component } from './ban-do003/ban-do003.component';





@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    HomepageComponent,
    LoginComponent, 
    DanhMucThanhVienComponent, 
    ThanhVienComponent, ThanhVienChucNangComponent, 
    ThanhVienLichSuTruyCapComponent, ThanhVienTokenComponent, DanhMucChucNangComponent, 
    ThanhVienDetailComponent, ThanhVienThongTinComponent, 
    HuongDanComponent, GioiThieuComponent, 
    ThanhVienChucNangDetailComponent, UploadComponent, ThanhVien002Component, ThanhVienDetail002Component, DanhMucChungChiComponent, 
    DanhMucDanTocComponent, DanhMucNgonNguComponent, ThanhVienVungTrongToaDoDetailComponent, 
    TruyXuatNguonGocKhoTemComponent, TruyXuatNguonGocKhoTem001Component, TruyXuatNguonGocLoHangComponent, 
    TruyXuatNguonGocLoHangDetailComponent, TruyXuatNguonGocLoHangMapComponent, TruyXuatNguonGocTemPhieuKhoiTaoComponent, 
    TruyXuatNguonGocTemPhieuKhoiTaoDetailComponent, TruyXuatNguonGocTemPhieuLichSuTruyCapDetailComponent, DanhMucVungTrongComponent, 
    TruyXuatNguonGocLoHangDetailRutGonComponent, ThanhVienVungTrongDetailComponent, TruyXuatNguonGocLoHangInfoComponent, 
    TruyXuatNguonGocTemPhieuKhoiTaoDetail001Component, TruyXuatNguonGocLoHangDetailRutGon001Component, 
    TruyXuatNguonGocDanhMucPhuongThucMuaBanComponent, TruyXuatNguonGocLoHangMuaBanComponent, DanhMucBaiVietComponent, 
    BaiVietComponent, BaiVietDetailComponent, BanDoComponent, BanDo001Component, BanDo002Component, BanDo003Component,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    GoogleMapsModule,
    ChartsModule,
    CKEditorModule,
  ],
  providers: [
    CookieService,
    NotificationService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
