import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { from } from 'rxjs';
import { distinct } from 'rxjs/operators';


import { ThanhVienVungTrong } from 'src/app/shared/ThanhVienVungTrong.model';
import { ThanhVienVungTrongService } from 'src/app/shared/ThanhVienVungTrong.service';

import { DanhMucVungTrong } from 'src/app/shared/DanhMucVungTrong.model';
import { DanhMucVungTrongService } from 'src/app/shared/DanhMucVungTrong.service';

import { TruyXuatNguonGocDanhMucUngDung } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.model';
import { TruyXuatNguonGocDanhMucUngDungService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.service';
import { DanhMucTinhThanh } from 'src/app/shared/TruyXuatNguonGoc/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/TruyXuatNguonGoc/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/TruyXuatNguonGoc/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucXaPhuong.service';




@Component({
  selector: 'app-thanh-vien-vung-trong-detail',
  templateUrl: './thanh-vien-vung-trong-detail.component.html',
  styleUrls: ['./thanh-vien-vung-trong-detail.component.css']
})
export class ThanhVienVungTrongDetailComponent implements OnInit {

  @ViewChild('ThanhVienVungTrongSort') ThanhVienVungTrongSort: MatSort;
  @ViewChild('ThanhVienVungTrongPaginator') ThanhVienVungTrongPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ThanhVienVungTrongDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public ThanhVienVungTrongService: ThanhVienVungTrongService,

    public DanhMucVungTrongService: DanhMucVungTrongService,

    public TruyXuatNguonGocDanhMucUngDungService: TruyXuatNguonGocDanhMucUngDungService,
    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,
  ) { }

  ngOnInit(): void {
    this.TruyXuatNguonGocDanhMucUngDungService.GetLogin();
    this.ThanhVienVungTrongSearch();
  }
  DateVungTrongNgayCap(value) {
    this.ThanhVienVungTrongService.FormData.VungTrongNgayCap = new Date(value);
  }
  DateVuonSamNgayCap(value) {
    this.ThanhVienVungTrongService.FormData.VuonSamNgayCap = new Date(value);
  }
  DateChiDanDiaLyNgayCap(value) {
    this.ThanhVienVungTrongService.FormData.VuonSamNgayCap = new Date(value);
  }
  DanhMucVungTrongSearch() {
    this.DanhMucVungTrongService.ComponentGetAllToListAsync(this.ThanhVienVungTrongService);
  }
  ThanhVienVungTrongSearchList() {
    this.ThanhVienVungTrongService.IsShowLoading = true;
    this.ThanhVienVungTrongService.BaseParameter.ParentID = this.ThanhVienVungTrongService.FormData.ParentID;
    this.ThanhVienVungTrongService.GetByParentIDAndEmptyToListAsync().subscribe(
      res => {
        this.ThanhVienVungTrongService.List = (res as ThanhVienVungTrong[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        this.ThanhVienVungTrongService.IsShowLoading = false;
      },
      err => {
        this.ThanhVienVungTrongService.IsShowLoading = false;
      }
    );
  }
  DanhMucTinhThanhSearch() {
    if (this.DanhMucTinhThanhService.List.length == 0) {
      this.ThanhVienVungTrongService.IsShowLoading = true;
      this.DanhMucTinhThanhService.BaseParameter.ParentID = environment.DanhMucQuocGiaIDVietNam;
      this.DanhMucTinhThanhService.GetByParentIDToListAsync().subscribe(
        res => {
          this.DanhMucTinhThanhService.List = (res as DanhMucTinhThanh[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
          this.DanhMucQuanHuyenSearch();
          this.ThanhVienVungTrongService.IsShowLoading = false;
        },
        err => {
          this.ThanhVienVungTrongService.IsShowLoading = false;
        }
      );
    }
  }
  DanhMucQuanHuyenSearch() {
    this.ThanhVienVungTrongService.IsShowLoading = true;

    for (let i = 0; i < this.DanhMucTinhThanhService.List.length; i++) {
      if (this.DanhMucTinhThanhService.List[i].ID == this.ThanhVienVungTrongService.FormData.DanhMucTinhThanhID) {
        this.ThanhVienVungTrongService.FormData.DanhMucTinhThanhName = this.DanhMucTinhThanhService.List[i].Name;
        i = this.DanhMucTinhThanhService.List.length;
      }
    }

    this.DanhMucQuanHuyenService.BaseParameter.ParentID = this.ThanhVienVungTrongService.FormData.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.List = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        this.DanhMucXaPhuongSearch();
        this.ThanhVienVungTrongService.IsShowLoading = false;
      },
      err => {
        this.ThanhVienVungTrongService.IsShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongSearch() {
    this.ThanhVienVungTrongService.IsShowLoading = true;

    for (let i = 0; i < this.DanhMucQuanHuyenService.List.length; i++) {
      if (this.DanhMucQuanHuyenService.List[i].ID == this.ThanhVienVungTrongService.FormData.DanhMucQuanHuyenID) {
        this.ThanhVienVungTrongService.FormData.DanhMucQuanHuyenName = this.DanhMucQuanHuyenService.List[i].Name;
        i = this.DanhMucQuanHuyenService.List.length;
      }
    }

    this.DanhMucXaPhuongService.BaseParameter.ParentID = this.ThanhVienVungTrongService.FormData.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.List = (res as DanhMucXaPhuong[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        this.ThanhVienVungTrongService.IsShowLoading = false;
      },
      err => {
        this.ThanhVienVungTrongService.IsShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongChange() {
    for (let i = 0; i < this.DanhMucXaPhuongService.List.length; i++) {
      if (this.DanhMucXaPhuongService.List[i].ID == this.ThanhVienVungTrongService.FormData.DanhMucXaPhuongID) {
        this.ThanhVienVungTrongService.FormData.DanhMucXaPhuongName = this.DanhMucXaPhuongService.List[i].Name;
        i = this.DanhMucXaPhuongService.List.length;
      }
    }
  }
  ThanhVienVungTrongSearch() {
    this.ThanhVienVungTrongService.BaseParameter.ID = this.ThanhVienVungTrongService.FormData.ID;
    this.ThanhVienVungTrongService.BaseParameter.ParentID = this.ThanhVienVungTrongService.FormData.ParentID;
    this.ThanhVienVungTrongService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienVungTrongService.FormData = res as ThanhVienVungTrong;
        this.ThanhVienVungTrongService.FormData.ParentID = this.ThanhVienVungTrongService.BaseParameter.ParentID;
        if (this.ThanhVienVungTrongService.FormData.DanhMucTinhThanhID) {
        }
        else {
          this.ThanhVienVungTrongService.FormData.DanhMucTinhThanhID = environment.DanhMucTinhThanhID;
        }
        if (this.ThanhVienVungTrongService.FormData.DanhMucQuanHuyenID) {
        }
        else {
          this.ThanhVienVungTrongService.FormData.DanhMucQuanHuyenID = environment.DanhMucQuanHuyenID;
        }
        this.DanhMucTinhThanhSearch();
        this.DanhMucVungTrongSearch();
        this.ThanhVienVungTrongSearchList();
      },
      err => {
      }
    );
  }
  ThanhVienVungTrongSave() {
    this.ThanhVienVungTrongService.IsShowLoading = true;
    this.ThanhVienVungTrongService.SaveAsync().subscribe(
      res => {
        this.ThanhVienVungTrongService.FormData = res as ThanhVienVungTrong;
        this.NotificationService.warn(environment.SaveSuccess);
        this.ThanhVienVungTrongService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.ThanhVienVungTrongService.IsShowLoading = false;
      }
    );
  }
  Close() {
    this.dialogRef.close();
  }
}
