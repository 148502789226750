<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-LoHangMuaBan" data-bs-toggle="tab"
                                    href="#LoHangMuaBan" role="tab" aria-controls="LoHangMuaBan" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Mua bán | Trao đổi</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="LoHangMuaBan" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Đơn vị
                                            [{{LoHangMuaBanService.BaseParameter.ToChucID}}]</label>
                                        <mat-select class="form-control" name="LoHangService.BaseParameter.ToChucID"
                                            [(ngModel)]="LoHangMuaBanService.BaseParameter.ToChucID">
                                            <input placeholder="Tìm..." class="form-control"
                                                (keyup)="ToChucFilter($event.target.value)">
                                            <mat-option *ngFor="let item of TruyXuatNguonGocToChucService.ListFilter"
                                                [value]="item.ID">
                                                <b>{{item.Name}}</b> - {{item.DienThoai}} - {{item.MaSoThue}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-5 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input [(ngModel)]="LoHangMuaBanService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="LoHangMuaBanSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="LoHangMuaBanSearch()"><i class="bi bi-search"></i></a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span *ngIf="LoHangMuaBanService.List">({{LoHangMuaBanService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #LoHangMuaBanSort="matSort"
                                                    [dataSource]="LoHangMuaBanService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i"
                                                            style="text-align: center">
                                                            <div *ngIf="LoHangMuaBanPaginator">
                                                                {{
                                                                LoHangMuaBanPaginator.pageSize *
                                                                LoHangMuaBanPaginator.pageIndex +
                                                                i +
                                                                1
                                                                }}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ToChucID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Đơn vị bán
                                                            sản phẩm truy xuất nguồn gốc
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div *ngIf="element.ID>0"><b>{{element.ToChucName}}</b></div>
                                                            <mat-select class="form-control" *ngIf="element.ID==0"
                                                                [(ngModel)]="element.ToChucID" (selectionChange)="ToChucChange(element.ToChucID)">
                                                                <input placeholder="Tìm..." class="form-control"
                                                                    (keyup)="ToChucFilter($event.target.value)">
                                                                <mat-option
                                                                    *ngFor="let item of TruyXuatNguonGocToChucService.ListFilter"
                                                                    [value]="item.ID">
                                                                    <b>{{item.Name}}</b> - {{item.DienThoai}} -
                                                                    {{item.MaSoThue}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ParentID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kho sâm đã đăng ký truy xuất nguồn gốc (TXNG)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div *ngIf="element.ID>0"><b>{{element.Description}}</b></div>
                                                            <mat-select class="form-control" *ngIf="element.ID==0"
                                                                [(ngModel)]="element.ParentID">
                                                                <input placeholder="Tìm..." class="form-control"
                                                                    (keyup)="LoHangFilter($event.target.value)">
                                                                <mat-option
                                                                    *ngFor="let item of LoHangService.ListFilter"
                                                                    [value]="item.ID">
                                                                    <b>{{item.MaLoHang}}</b>
                                                                </mat-option>
                                                            </mat-select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucPhuongThucMuaBanID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phương
                                                            thức mua bán
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                [(ngModel)]="element.DanhMucPhuongThucMuaBanID">
                                                                <option
                                                                    *ngFor="let item of DanhMucPhuongThucMuaBanService.List;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayGhiNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                                style="text-align: right;"
                                                                (ngModelChange)="DateLoHangMuaBanNgayGhiNhan(element,$event)"
                                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                                class="form-control" autocomplete="off">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DonGia">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Đơn giá sản phẩm
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input type="number" placeholder="Đơn giá sản phẩm"
                                                                name="DonGia{{element.ID}}" [(ngModel)]="element.DonGia"
                                                                class="form-control" style="text-align: right;">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Display">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã số đơn hàng (nếu có)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input type="text" placeholder="Mã số đơn hàng (nếu có)"
                                                                name="Display{{element.ID}}"
                                                                [(ngModel)]="element.Display" class="form-control">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Người mua | Trao đổi
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input type="text" placeholder="Người mua | Trao đổi"
                                                                name="Name{{element.ID}}" [(ngModel)]="element.Name"
                                                                class="form-control">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienThoai">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại liên hệ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input type="text" placeholder="Điện thoại liên hệ"
                                                                name="DienThoai{{element.ID}}"
                                                                [(ngModel)]="element.DienThoai" class="form-control">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú (nếu có)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input type="text" placeholder="Ghi chú"
                                                                name="Note{{element.ID}}" [(ngModel)]="element.Note"
                                                                class="form-control">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="LoHangMuaBanSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="LoHangMuaBanDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="LoHangMuaBanService.DisplayColumns003">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: LoHangMuaBanService.DisplayColumns003;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #LoHangMuaBanPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #LoHangMuaBanSort="matSort"
                                                    [dataSource]="LoHangMuaBanService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="LoHangMuaBanService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: LoHangMuaBanService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #LoHangMuaBanPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="LoHangMuaBanService.IsShowLoading"></app-loading>