import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoHang } from './LoHang.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class LoHangService extends BaseService {

    DisplayColumns001: string[] = ['ID', 'ParentID', 'Name', 'DangKy'];
    DisplayColumns002: string[] = ['STT', 'ID', 'TypeName', 'Code', 'PhienBan', 'LastUpdatedDate', 'Active'];

    DisplayColumns003: string[] = ['STT', 'ParentID', 'ID', 'MaLoHang', 'DangKy'];

    DisplayColumns004: string[] = ['ID', 'DoTuoi', 'Name', 'HoSo', 'Copy', 'Delete'];

    DisplayColumns005: string[] = ['STT', 'ID', 'ParentName', 'TypeName', 'Code', 'PhienBan', 'LastUpdatedDate', 'Active'];

    List: LoHang[] | undefined;
    ListFilter: LoHang[] | undefined;
    FormData!: LoHang;


    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "LoHang";
    }
    GetBySearchString_BatDau_KetThuc_Active_DanhMucUngDungIDToListAsync() {
        this.InitializationHeaders();       
        this.Initialization();        
        let url = this.APIURL + this.Controller + '/GetBySearchString_BatDau_KetThuc_Active_DanhMucUngDungIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    GetByTypeNameToListAsync() {
        let url = this.APIURL + this.Controller + '/GetByTypeNameToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    GetByTypeNameAndActiveAsync() {
        let url = this.APIURL + this.Controller + '/GetByTypeNameAndActiveAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    GetBySearchString_BatDau_KetThuc_Active_ParentIDToListAsync() {
        let url = this.APIURL + this.Controller + '/GetBySearchString_BatDau_KetThuc_Active_ParentIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
}

