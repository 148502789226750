import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThanhVienVungTrong } from 'src/app/shared/ThanhVienVungTrong.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThanhVienVungTrongService extends BaseService{

    DisplayColumns001: string[] = ['STT', 'DanhMucVungTrongID', 'ThanhVienVungTrongID', 'ID', 'Code', 'Name', 'Note', 'SortOrder', 'Active', 'TypeName', 'Save'];  
    DisplayColumns002: string[] = ['STT', 'ID', 'DanhMucVungTrongID', 'VuonSamMaSo', 'Name', 'SortOrder', 'Active', 'TypeName', 'Save']; 
    
    List: ThanhVienVungTrong[] | undefined;
    ListFilter: ThanhVienVungTrong[] | undefined;
    FormData!: ThanhVienVungTrong;
    FormDataLogin!: ThanhVienVungTrong;


    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "ThanhVienVungTrong";
    }
}

