import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { DanhMucQuanHuyen } from 'src/app/shared/TruyXuatNguonGoc/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucQuanHuyen.service';

import { DanhMucXaPhuong } from 'src/app/shared/TruyXuatNguonGoc/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucXaPhuong.service';

import { DanhMucTinhThanhToaDo } from 'src/app/shared/TruyXuatNguonGoc/DanhMucTinhThanhToaDo.model';
import { DanhMucTinhThanhToaDoService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucTinhThanhToaDo.service';

import { Report } from 'src/app/shared/Report.model';
import { ReportService } from 'src/app/shared/Report.service';

import * as maplibregl from 'maplibre-gl';

@Component({
  selector: 'app-ban-do',
  templateUrl: './ban-do.component.html',
  styleUrls: ['./ban-do.component.css']
})
export class BanDoComponent implements OnInit {

  map: maplibregl.Map | undefined;

  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,
    public DanhMucTinhThanhToaDoService: DanhMucTinhThanhToaDoService,

    public ReportService: ReportService,

  ) { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.MapLoad();
  }
  ngOnDestroy() {
    this.map?.remove();
  }
  MapInitialization(longitude, latitude) {

    let zoom = environment.MapZoom;
    if ((latitude > 90) || (latitude == 0)) {
      latitude = environment.Latitude;
      longitude = environment.Longitude;
    }

    this.map = new maplibregl.Map({
      container: this.mapContainer.nativeElement,
      style: 'https://api.maptiler.com/maps/streets/style.json?key=' + environment.MaptilerAPIKey,
      center: [longitude, latitude],
      zoom: zoom,
      pitch: 45,
    });

    this.map.addControl(
      new maplibregl.NavigationControl({
        visualizePitch: true,
        showZoom: true,
        showCompass: true
      })
    );
    let mapSub = this.map;
    this.map.on('load', () => {
      this.map = mapSub;
      this.map.addSource("HoangSa", {
        "type": "image",
        "url": environment.DomainURL + "assets/image/HoangSa01.png",
        "coordinates": [
          [111.09665858054495, 17.432475898867523],
          [113.11720985517763, 17.38420482529338],
          [112.79285037220984, 15.643938718432054],
          [110.88537855035554, 15.672592116966598],
        ]
      });
      this.map.addLayer({
        "id": "HoangSa",
        "source": "HoangSa",
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

      this.map.addSource("TruongSa", {
        "type": "image",
        "url": environment.DomainURL + "assets/image/TruongSa01.png",
        "coordinates": [
          [112.32373278444106, 12.236103169381323],
          [117.4620551483049, 11.606334626304161],
          [115.59654957671216, 7.357025445897818],
          [110.62186805246108, 7.811210355974268],


        ]
      });
      this.map.addLayer({
        "id": "TruongSa",
        "source": "TruongSa",
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

    });
  }

  MapLoad() {
    this.DanhMucTinhThanhToaDoService.IsShowLoading = true;
    this.DanhMucQuanHuyenService.BaseParameter.ID = environment.DanhMucQuanHuyenID;
    this.DanhMucQuanHuyenService.GetByIDAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.FormData = (res as DanhMucQuanHuyen);
        let latitude = environment.Latitude;
        let longitude = environment.Longitude;
        if (this.DanhMucQuanHuyenService.FormData.KinhDo) {
          latitude = Number(this.DanhMucQuanHuyenService.FormData.KinhDo);
        }
        if (this.DanhMucQuanHuyenService.FormData.ViDo) {
          longitude = Number(this.DanhMucQuanHuyenService.FormData.ViDo);
        }
        this.MapInitialization(longitude, latitude);

        // this.DanhMucTinhThanhToaDoService.BaseParameter.DanhMucQuanHuyenID = environment.DanhMucQuanHuyenID;
        // this.DanhMucTinhThanhToaDoService.GetByDanhMucQuanHuyenIDToListAsync().subscribe(
        //   res => {
        //     this.DanhMucTinhThanhToaDoService.ListDanhMucQuanHuyen = (res as DanhMucTinhThanhToaDo[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        //     let listToChucToaDoPolygon = [];
        //     let listPolygon = [];
        //     for (let i = 0; i < this.DanhMucTinhThanhToaDoService.ListDanhMucQuanHuyen.length; i++) {
        //       let latitudeSub1 = Number(this.DanhMucTinhThanhToaDoService.ListDanhMucQuanHuyen[i].ViDo);
        //       let longitudeSub1 = Number(this.DanhMucTinhThanhToaDoService.ListDanhMucQuanHuyen[i].KinhDo);
        //       listToChucToaDoPolygon.push([longitudeSub1, latitudeSub1]);
        //     }
        //     if (listToChucToaDoPolygon.length > 0) {
        //       listPolygon.push(listToChucToaDoPolygon);
        //       let layerID = 'Layer_' + this.DanhMucQuanHuyenService.FormData.ID;
        //       let sourceID = 'Source_' + this.DanhMucQuanHuyenService.FormData.ID;
        //       this.map.addSource(sourceID, {
        //         'type': 'geojson',
        //         'data': {
        //           'type': 'Feature',
        //           'properties': {
        //             "name": this.DanhMucQuanHuyenService.FormData.Name,
        //             "address": this.DanhMucQuanHuyenService.FormData.Name,
        //           },
        //           'geometry': {
        //             'type': 'Polygon',
        //             'coordinates': listPolygon,
        //           }
        //         }
        //       });
        //       let color = this.DownloadService.GetRandomColor(listToChucToaDoPolygon.length);
        //       this.map.addLayer({
        //         'id': layerID,
        //         'type': 'fill',
        //         'source': sourceID,
        //         'paint': {                                    
        //           'fill-outline-color': '#000000',   
        //           'fill-color': 'transparent',               
        //         }
        //       });

        //     }
        //   },
        //   err => {
        //   },
        //   () => {
        //     this.DanhMucTinhThanhToaDoService.IsShowLoading = false;
        //   }
        // );



        this.DanhMucXaPhuongService.BaseParameter.ParentID = environment.DanhMucQuanHuyenID;
        this.DanhMucXaPhuongService.GetByParentIDToListAsync().subscribe(
          res => {
            this.DanhMucXaPhuongService.List = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));

            // this.ReportService.BaseParameter.DanhMucQuanHuyenID = environment.DanhMucQuanHuyenID;
            // this.ReportService.Report0002ToListAsync().subscribe(
            //   res => {
            //     this.ReportService.List = (res as Report[]);
            //     for (let j = 0; j < this.DanhMucXaPhuongService.List.length; j++) {
            //       for (let i = 0; i < this.ReportService.List.length; i++) {
            //         if (this.DanhMucXaPhuongService.List[j].ID == this.ReportService.List[i].DanhMucXaPhuongID) {
            //           let DanhMucXaPhuong = this.DanhMucXaPhuongService.List[j];
            //           this.ReportService.FormData = this.ReportService.List[i];
            //           let html = "<div style='opacity: 0.8; background-color: transparent; text-align: center; padding: 5px; font-size: 14px;'>";
            //           html = html + "<div style='padding: 5px;'><b style='color: red; font-size: 24px;'>" + DanhMucXaPhuong.Name + "</b></div>";
            //           html = html + "<div style='padding: 5px;'>Người trồng sâm: <b>" + new Intl.NumberFormat('vi-vn', { minimumFractionDigits: 0 }).format(this.ReportService.FormData.ThongKe020) + "</b></div>";
            //           html = html + "<div style='padding: 5px;'>Hạt sâm: <b>" + new Intl.NumberFormat('vi-vn', { minimumFractionDigits: 0 }).format(this.ReportService.FormData.ThongKe000) + "</b></div>";
            //           html = html + "<div style='padding: 5px;'>Sâm 01 năm: <b>" + new Intl.NumberFormat('vi-vn', { minimumFractionDigits: 0 }).format(this.ReportService.FormData.ThongKe001) + "</b></div>";
            //           html = html + "<div style='padding: 5px;'>Sâm 02 năm: <b>" + new Intl.NumberFormat('vi-vn', { minimumFractionDigits: 0 }).format(this.ReportService.FormData.ThongKe002) + "</b></div>";
            //           html = html + "<div style='padding: 5px;'>Sâm 03 năm: <b>" + new Intl.NumberFormat('vi-vn', { minimumFractionDigits: 0 }).format(this.ReportService.FormData.ThongKe003) + "</b></div>";
            //           html = html + "<div style='padding: 5px;'>Sâm 04 năm: <b>" + new Intl.NumberFormat('vi-vn', { minimumFractionDigits: 0 }).format(this.ReportService.FormData.ThongKe004) + "</b></div>";
            //           html = html + "<div style='padding: 5px;'>Sâm 05 năm: <b>" + new Intl.NumberFormat('vi-vn', { minimumFractionDigits: 0 }).format(this.ReportService.FormData.ThongKe005) + "</b></div>";
            //           html = html + "<div style='padding: 5px;'>Sâm 06 năm: <b>" + new Intl.NumberFormat('vi-vn', { minimumFractionDigits: 0 }).format(this.ReportService.FormData.ThongKe006) + "</b></div>";
            //           html = html + "<div style='padding: 5px;'>Sâm 07 năm: <b>" + new Intl.NumberFormat('vi-vn', { minimumFractionDigits: 0 }).format(this.ReportService.FormData.ThongKe007) + "</b></div>";
            //           html = html + "<div style='padding: 5px;'>Sâm 08 năm: <b>" + new Intl.NumberFormat('vi-vn', { minimumFractionDigits: 0 }).format(this.ReportService.FormData.ThongKe008) + "</b></div>";
            //           html = html + "<div style='padding: 5px;'>Sâm 09 năm: <b>" + new Intl.NumberFormat('vi-vn', { minimumFractionDigits: 0 }).format(this.ReportService.FormData.ThongKe009) + "</b></div>";
            //           html = html + "<div style='padding: 5px;'>Sâm 10 năm: <b>" + new Intl.NumberFormat('vi-vn', { minimumFractionDigits: 0 }).format(this.ReportService.FormData.ThongKe010) + "</b></div>";
            //           html = html + "<div style='padding: 5px;'>Sâm trên 10 năm: <b>" + new Intl.NumberFormat('vi-vn', { minimumFractionDigits: 0 }).format(this.ReportService.FormData.ThongKe011) + "</b></div>";
            //           html = html + "<div style='padding: 5px;'>Sâm trên 20 năm: <b>" + new Intl.NumberFormat('vi-vn', { minimumFractionDigits: 0 }).format(this.ReportService.FormData.ThongKe012) + "</b></div>";
            //           html = html + "</div>";

            //           let popup = new maplibregl.Popup({ offset: 25 }).setHTML(html)
            //             .setMaxWidth("600px");

            //           html = "<div style='width: 100px; padding-top: 32px; margin-left: -24px;'>";
            //           html = html + "<div><b style='color: red; font-size: 20px;'>" + DanhMucXaPhuong.Name + "</b></div>";
            //           html = html + "</div>";

            //           var el = document.createElement('div');
            //           el.innerHTML = html;
            //           el.style.backgroundImage = "url(assets/image/logo_30_2024.png)";
            //           el.style.width = '30px';
            //           el.style.height = '30px';

            //           let DanhMucXaPhuongViDo = Number(DanhMucXaPhuong.ViDo);
            //           let DanhMucXaPhuongKinhDo = Number(DanhMucXaPhuong.KinhDo);
            //           let marker = new maplibregl.Marker({ element: el })
            //             .setLngLat([DanhMucXaPhuongKinhDo, DanhMucXaPhuongViDo])
            //             .setPopup(popup)
            //             .addTo(this.map);

            //         }
            //       }
            //     }

            //   },
            //   err => {
            //   },
            //   () => {
            //   }
            // );

            for (let j = 0; j < this.DanhMucXaPhuongService.List.length; j++) {
              let DanhMucXaPhuong = this.DanhMucXaPhuongService.List[j];

              this.ReportService.BaseParameter.DanhMucXaPhuongID = DanhMucXaPhuong.ID;
              this.ReportService.Report0001Async().subscribe(
                res => {
                  this.ReportService.FormData = (res as Report);
                  let html = "<div style='opacity: 0.8; background-color: transparent; text-align: center; padding: 5px; font-size: 14px;'>";
                  html = html + "<div style='padding: 5px;'><b style='color: red; font-size: 24px;'>" + DanhMucXaPhuong.Name + "</b></div>";                  
                  html = html + "<div style='padding: 5px;'>Người trồng sâm: <b>" + new Intl.NumberFormat('vi-vn', {minimumFractionDigits: 0}).format(this.ReportService.FormData.ThongKe020) + "</b></div>";
                  html = html + "<div style='padding: 5px;'>Hạt sâm: <b>" + new Intl.NumberFormat('vi-vn', {minimumFractionDigits: 0}).format(this.ReportService.FormData.ThongKe000) + "</b></div>";
                  html = html + "<div style='padding: 5px;'>Sâm 01 năm: <b>" + new Intl.NumberFormat('vi-vn', {minimumFractionDigits: 0}).format(this.ReportService.FormData.ThongKe001) + "</b></div>";
                  html = html + "<div style='padding: 5px;'>Sâm 02 năm: <b>" + new Intl.NumberFormat('vi-vn', {minimumFractionDigits: 0}).format(this.ReportService.FormData.ThongKe002) + "</b></div>";
                  html = html + "<div style='padding: 5px;'>Sâm 03 năm: <b>" + new Intl.NumberFormat('vi-vn', {minimumFractionDigits: 0}).format(this.ReportService.FormData.ThongKe003) + "</b></div>";
                  html = html + "<div style='padding: 5px;'>Sâm 04 năm: <b>" + new Intl.NumberFormat('vi-vn', {minimumFractionDigits: 0}).format(this.ReportService.FormData.ThongKe004) + "</b></div>";
                  html = html + "<div style='padding: 5px;'>Sâm 05 năm: <b>" + new Intl.NumberFormat('vi-vn', {minimumFractionDigits: 0}).format(this.ReportService.FormData.ThongKe005) + "</b></div>";
                  html = html + "<div style='padding: 5px;'>Sâm 06 năm: <b>" + new Intl.NumberFormat('vi-vn', {minimumFractionDigits: 0}).format(this.ReportService.FormData.ThongKe006) + "</b></div>";
                  html = html + "<div style='padding: 5px;'>Sâm 07 năm: <b>" + new Intl.NumberFormat('vi-vn', {minimumFractionDigits: 0}).format(this.ReportService.FormData.ThongKe007) + "</b></div>";
                  html = html + "<div style='padding: 5px;'>Sâm 08 năm: <b>" + new Intl.NumberFormat('vi-vn', {minimumFractionDigits: 0}).format(this.ReportService.FormData.ThongKe008) + "</b></div>";
                  html = html + "<div style='padding: 5px;'>Sâm 09 năm: <b>" + new Intl.NumberFormat('vi-vn', {minimumFractionDigits: 0}).format(this.ReportService.FormData.ThongKe009) + "</b></div>";
                  html = html + "<div style='padding: 5px;'>Sâm 10 năm: <b>" + new Intl.NumberFormat('vi-vn', {minimumFractionDigits: 0}).format(this.ReportService.FormData.ThongKe010) + "</b></div>";
                  html = html + "<div style='padding: 5px;'>Sâm trên 10 năm: <b>" + new Intl.NumberFormat('vi-vn', {minimumFractionDigits: 0}).format(this.ReportService.FormData.ThongKe011) + "</b></div>";
                  html = html + "<div style='padding: 5px;'>Sâm trên 20 năm: <b>" + new Intl.NumberFormat('vi-vn', {minimumFractionDigits: 0}).format(this.ReportService.FormData.ThongKe012) + "</b></div>";
                  html = html + "</div>";
                  let popup = new maplibregl.Popup({ offset: 25 }).setHTML(html)
                    .setMaxWidth("600px");
                  html = "<div style='width: 100px; padding-top: 32px; margin-left: -24px;'>";
                  html = html + "<div><b style='color: red; font-size: 20px;'>" + DanhMucXaPhuong.Name + "</b></div>";
                  html = html + "</div>";
                  var el = document.createElement('div');
                  el.innerHTML = html;
                  el.style.backgroundImage = "url(assets/image/logo_30_2024.png)";
                  el.style.width = '30px';
                  el.style.height = '30px';
                  let DanhMucXaPhuongViDo = Number(DanhMucXaPhuong.ViDo);
                  let DanhMucXaPhuongKinhDo = Number(DanhMucXaPhuong.KinhDo);
                  let marker = new maplibregl.Marker({ element: el })
                    .setLngLat([DanhMucXaPhuongKinhDo, DanhMucXaPhuongViDo])
                    .setPopup(popup)
                    .addTo(this.map);
                },
                err => {
                },
                () => {
                }
              );

              this.DanhMucTinhThanhToaDoService.BaseParameter.DanhMucXaPhuongID = DanhMucXaPhuong.ID;
              this.DanhMucTinhThanhToaDoService.GetByDanhMucXaPhuongIDToListAsync().subscribe(
                res => {
                  this.DanhMucTinhThanhToaDoService.ListDanhMucXaPhuong = (res as DanhMucTinhThanhToaDo[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
                  let listToChucToaDoPolygon = [];
                  let listPolygon = [];
                  for (let i = 0; i < this.DanhMucTinhThanhToaDoService.ListDanhMucXaPhuong.length; i++) {

                    let latitudeSub1 = Number(this.DanhMucTinhThanhToaDoService.ListDanhMucXaPhuong[i].ViDo);
                    let longitudeSub1 = Number(this.DanhMucTinhThanhToaDoService.ListDanhMucXaPhuong[i].KinhDo);
                    listToChucToaDoPolygon.push([longitudeSub1, latitudeSub1]);

                  }


                  if (listToChucToaDoPolygon.length > 0) {
                    listPolygon.push(listToChucToaDoPolygon);


                    let layerID = 'Layer_' + DanhMucXaPhuong.ID;
                    let sourceID = 'Source_' + DanhMucXaPhuong.ID;
                    this.map.addSource(sourceID, {
                      'type': 'geojson',
                      'data': {
                        'type': 'Feature',
                        'properties': {
                          "name": DanhMucXaPhuong.Name,
                          "address": DanhMucXaPhuong.Name,
                        },
                        'geometry': {
                          'type': 'Polygon',
                          'coordinates': listPolygon,
                        }
                      }
                    });
                    let color = this.DownloadService.GetRandomColor(DanhMucXaPhuong.ID);
                    this.map.addLayer({
                      'id': layerID,
                      'type': 'line',
                      'source': sourceID,
                      'paint': {
                        'line-dasharray': [3, 1],
                        "line-color": color,
                        "line-width": environment.LineWidth,
                      }
                    });

                  }
                },
                err => {
                },
                () => {
                  this.DanhMucTinhThanhToaDoService.IsShowLoading = false;
                }
              );
            }
          },
          err => {
          },
          () => {
          }
        );

      },
      err => {

      },
      () => {
        this.DanhMucTinhThanhToaDoService.IsShowLoading = false;
      }
    );

  }
}
