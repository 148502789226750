<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-BaiVietDetail" data-bs-toggle="tab" href="#BaiVietDetail" role="tab"
                aria-controls="BaiVietDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Bài viết
                    [{{BaiVietService.FormData.ID}}]</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-BaiVietTapTinDinhKem" data-bs-toggle="tab" href="#BaiVietTapTinDinhKem"
                role="tab" aria-controls="BaiVietTapTinDinhKem" aria-selected="false">
                <span class="badge bg-primary" style="font-size: 16px;">Tập tin đính kèm</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="BaiVietDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="BaiVietSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="Active"
                            [(ngModel)]="BaiVietService.FormData.Active" />
                        <label class="form-check-label" for="Active">Kích hoạt</label>
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Danh mục</label>
                        <select class="form-select" [(ngModel)]="BaiVietService.FormData.ParentID">
                            <option *ngFor="let item of DanhMucBaiVietService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiêu đề</label>
                        <input name="Name" [(ngModel)]="BaiVietService.FormData.Name" placeholder="Tiêu đề" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mô tả</label>
                        <input name="Description" [(ngModel)]="BaiVietService.FormData.Description" placeholder="Mô tả"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đường dẫn | Slug (Tùy chọn)</label>
                        <input name="Code" [(ngModel)]="BaiVietService.FormData.Code"
                            placeholder="Đường dẫn | Slug (Tùy chọn)" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày ghi nhận</label>
                        <input [ngModel]="BaiVietService.FormData.NgayGhiNhan | date:'yyyy-MM-dd'"
                            style="text-align: right;" (ngModelChange)="DateNgayGhiNhan($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Hình đại diện: </label>
                        <br />
                        <input type="file" (change)="ChangeFileName($event.target.files)">
                        <br />
                        <br />
                        <img src="{{BaiVietService.FormData.FileName}}" class="img-fluid rounded">
                    </div>
                </div>
                <div class="col-lg-8 col-sm-12 col-12">
                    <ck-editor [config]="{'height': 500, 'width': 800}" name="HTMLContent"
                        [(ngModel)]="BaiVietService.FormData.HTMLContent" skin="moono-lisa" language="en"
                        [fullPage]="true"></ck-editor>
                </div>
            </div>
        </div>
        <div *ngIf="BaiVietService.FormData.ID>0" class="tab-pane fade" id="BaiVietTapTinDinhKem" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-11 col-sm-12 col-12">
                    <input [(ngModel)]="BaiVietTapTinDinhKemService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="BaiVietTapTinDinhKemSearch()">
                </div>
                <div class="col-lg-1 col-sm-6 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm"
                        (click)="BaiVietTapTinDinhKemSearch()"><i class="bi bi-search"></i></a>
                </div>
            </div>
            <div class="card mb-4 card-primary">
                <div class="card-header">
                    <h5 class="card-title text-white bg-primary">Danh sách
                        <span *ngIf="BaiVietTapTinDinhKemService.List">({{BaiVietTapTinDinhKemService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body bg-primary">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                #BaiVietTapTinDinhKemSort="matSort"
                                [dataSource]="BaiVietTapTinDinhKemService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                        <div *ngIf="BaiVietTapTinDinhKemPaginator">
                                            {{BaiVietTapTinDinhKemPaginator.pageSize *
                                            BaiVietTapTinDinhKemPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Hình ảnh
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a *ngIf="element.ID > 0" href="{{element.FileName}}" title="{{element.FileName}}" target="_blank"><img
                                                src="{{element.FileName}}" title="{{element.FileName}}"
                                                alt="{{element.FileName}}" width="180" height="120" /></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="FileName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Đường dẫn (Tùy chọn)
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="text" placeholder="Đường dẫn"
                                            name="FileName{{element.ID}}" [(ngModel)]="element.FileName" />
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="text" placeholder="Tiêu đề"
                                            name="Name{{element.ID}}" [(ngModel)]="element.Name" />
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Note">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải lên
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input type="file" style="width: 200px;"
                                            (change)="BaiVietTapTinDinhKemChangeFileName($event.target.files)">
                                    </td>
                                </ng-container>                               
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="btn btn-info" (click)="BaiVietTapTinDinhKemSave(element)"><i
                                                class="bi bi-sd-card"></i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-danger" (click)="BaiVietTapTinDinhKemDelete(element)"
                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="BaiVietTapTinDinhKemService.DisplayColumns001">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: BaiVietTapTinDinhKemService.DisplayColumns001;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile"
                                #BaiVietTapTinDinhKemPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="6" [showFirstLastButtons]></mat-paginator>

                            <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                #BaiVietTapTinDinhKemSort="matSort"
                                [dataSource]="BaiVietTapTinDinhKemService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Tiêu đề</label>
                                                <input class="form-control" type="text" placeholder="Tiêu đề"
                                                    name="Name{{element.ID}}" [(ngModel)]="element.Name">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Tải lên</label>
                                                <input type="file" style="width: 200px;"
                                                    (change)="BaiVietTapTinDinhKemChangeFileName($event.target.files)">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <a *ngIf="element.ID > 0 && element.FileName" class="link-primary"
                                                    target="_blank" href="{{element.FileName}}"><b>Tải về</b></a>
                                            </div>
                                        </div>
                                        <div class="row gx-4">
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a class="btn btn-info" style="width: 100%;"
                                                    (click)="BaiVietTapTinDinhKemSave(element)"><i
                                                        class="bi bi-sd-card"></i>
                                                </a>
                                            </div>
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a class="btn btn-danger" style="width: 100%;"
                                                    (click)="BaiVietTapTinDinhKemDelete(element)"
                                                    *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                </a>
                                            </div>
                                            <br />
                                            <br />
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="BaiVietTapTinDinhKemService.DisplayColumnsMobile">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: BaiVietTapTinDinhKemService.DisplayColumnsMobile;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile"
                                #BaiVietTapTinDinhKemPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="BaiVietService.IsShowLoading"></app-loading>