<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-LoHangDetail" data-bs-toggle="tab" href="#LoHangDetail" role="tab"
                aria-controls="LoHangDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">01. Truy xuất nguồn gốc
                    [{{LoHangService.FormData.ID}}]</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-TemPhieuKhoiTaoChiTietDetail" data-bs-toggle="tab"
                href="#TemPhieuKhoiTaoChiTietDetail" role="tab" aria-controls="TemPhieuKhoiTaoChiTietDetail"
                aria-selected="false">
                <span class="badge bg-warning" style="font-size: 16px;">02. Kho tem</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-TemPhieuDetail" data-bs-toggle="tab" href="#TemPhieuDetail" role="tab"
                aria-controls="TemPhieuDetail" aria-selected="false">
                <span class="badge bg-success" style="font-size: 16px;">03. Mã số truy vết</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-LoHangBinhChonDetail" data-bs-toggle="tab" href="#LoHangBinhChonDetail"
                role="tab" aria-controls="LoHangBinhChonDetail" aria-selected="false">
                <span class="badge bg-warning" style="font-size: 16px;">04. Bình chọn</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-LoHangPhanAnhDetail" data-bs-toggle="tab" href="#LoHangPhanAnhDetail" role="tab"
                aria-controls="LoHangPhanAnhDetail" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;">05. Phản ánh</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-LoHang001Detail" data-bs-toggle="tab" href="#LoHang001Detail" role="tab"
                aria-controls="LoHang001Detail" aria-selected="false">
                <span class="badge bg-primary" style="font-size: 16px;">06. Phiên bản</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-TemPhieuLichSuTruyCapDetail" data-bs-toggle="tab"
                href="#TemPhieuLichSuTruyCapDetail" role="tab" aria-controls="TemPhieuLichSuTruyCapDetail"
                aria-selected="false">
                <span class="badge bg-success" style="font-size: 16px;">07. Truy cập</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="LoHangDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4 style="text-align: center;"><b>Bước 01: Nhập thông tin đăng ký phát hành mã Truy xuất nguồn gốc
                            (TXNG) của lô hàng</b></h4>
                    <h4 style="text-align: center; color: brown;"><b>{{LoHangService.FormData.Name}}</b></h4>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="Active"
                            [(ngModel)]="LoHangService.FormData.Active" />
                        <label class="form-check-label" for="Active">Đăng ký</label>
                    </div>
                </div>
                <!-- <div class="col-lg-3 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="IsTiepNhan"
                            [(ngModel)]="LoHangService.FormData.IsTiepNhan" />
                        <label class="form-check-label" for="IsTiepNhan">Tiếp nhận</label>
                    </div>
                </div> -->
                <div class="col-lg-3 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="IsKiemTra" disabled
                            [(ngModel)]="LoHangService.FormData.IsKiemTra" />
                        <label class="form-check-label" for="IsKiemTra">Kiểm tra</label>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="IsPheDuyet" disabled
                            [(ngModel)]="LoHangService.FormData.IsPheDuyet" />
                        <label class="form-check-label" for="IsPheDuyet">Phê duyệt (Lô hàng được cấp mã TXNG)</label>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="IsThuHoi" disabled
                            [(ngModel)]="LoHangService.FormData.IsThuHoi" />
                        <label class="form-check-label" for="IsThuHoi">Thu hồi (nếu có)</label>
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đơn vị đăng ký phát hành mã TXNG (Chủ sở hữu)
                            [{{LoHangService.FormData.ParentID}}]</label>
                        <input name="ParentName" [(ngModel)]="LoHangService.FormData.ParentName"
                            placeholder="Đơn vị đăng ký phát hành mã TXNG" type="text" disabled class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Vùng nguyên liệu
                            [{{LoHangService.FormData.VungNguyenLieuMaSoVungTrong}}]</label>
                        <select class="form-select" name="VungNguyenLieuMaSoVungTrong"
                            [(ngModel)]="LoHangService.FormData.VungNguyenLieuMaSoVungTrong">
                            <option *ngFor="let item of ThanhVienVungTrongService.List;" [value]="item.VuonSamMaSo">
                                {{item.VuonSamMaSo}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Loại hình mã TXNG cần phát hành
                            [{{LoHangService.FormData.DanhMucLoaiHinhID}}]</label>
                        <select class="form-select" name="DanhMucLoaiHinhID"
                            [(ngModel)]="LoHangService.FormData.DanhMucLoaiHinhID">
                            <option *ngFor="let item of DanhMucLoaiHinhService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Phương thức sản xuất tem TXNG
                            [{{LoHangService.FormData.DanhMucPhuongThucSanXuatID}}]</label>
                        <select class="form-select" name="DanhMucPhuongThucSanXuatID"
                            [(ngModel)]="LoHangService.FormData.DanhMucPhuongThucSanXuatID">
                            <option *ngFor="let item of DanhMucPhuongThucSanXuatService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày Đăng ký khai báo thông tin lô hàng</label>
                        <input [ngModel]="LoHangService.FormData.DangKy | date:'yyyy-MM-dd'" style="text-align: right;"
                            (ngModelChange)="DateDangKy($event)" [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                            class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày Kiểm tra (Cơ quan quản lý)</label>
                        <input [ngModel]="LoHangService.FormData.KiemTra | date:'yyyy-MM-dd'" style="text-align: right;"
                            disabled (ngModelChange)="DateKiemTra($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                            type="date" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày Phê duyệt (Cơ quan quản lý)</label>
                        <input [ngModel]="LoHangService.FormData.PheDuyet | date:'yyyy-MM-dd'" disabled
                            style="text-align: right;" (ngModelChange)="DatePheDuyet($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày Thu hồi (nếu có của Cơ quan quản lý)</label>
                        <input [ngModel]="LoHangService.FormData.ThuHoi | date:'yyyy-MM-dd'" style="text-align: right;"
                            disabled (ngModelChange)="DateThuHoi($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                            type="date" class="form-control" autocomplete="off">
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Phiên bản</label>
                        <input name="PhienBan" [(ngModel)]="LoHangService.FormData.PhienBan" placeholder="0"
                            type="number" disabled style="text-align: right;" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã hồ sơ</label>
                        <input name="MaHoSo" [(ngModel)]="LoHangService.FormData.MaHoSo" placeholder="Mã hồ sơ"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Lô hàng</label>
                        <input name="Name" [(ngModel)]="LoHangService.FormData.Name" placeholder="Lô hàng" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label" style="color: brown;">Mã lô đóng gói hàng hóa | Mã sâm (Dùng cho tra
                            cứu)</label>
                        <input name="MaLoHang" [(ngModel)]="LoHangService.FormData.MaLoHang" placeholder="Mã lô hàng"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Sản phẩm <span (click)="DanhMucSanPhamIsShowChange()" style="color: blue; cursor: pointer;">(Thêm mới, nếu thiếu)</span></label>
                        <input *ngIf="DanhMucSanPhamIsShow" name="DanhMucSanPhamName" [(ngModel)]="LoHangService.FormData.DanhMucSanPhamName"
                            placeholder="Sản phẩm (Thêm mới, nếu thiếu)" type="text" class="form-control">
                        <select *ngIf="!DanhMucSanPhamIsShow" class="form-select" name="DanhMucSanPhamID"
                            [(ngModel)]="LoHangService.FormData.DanhMucSanPhamID">
                            <option *ngFor="let item of DanhMucSanPhamService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tuổi sâm</label>
                        <input name="DoTuoi" [(ngModel)]="LoHangService.FormData.DoTuoi" placeholder="Năm tuổi"
                            type="number" class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số lượng sản phẩm (dưới 20.000)</label>
                        <input name="SanPhamSoLuong" [(ngModel)]="LoHangService.FormData.SanPhamSoLuong"
                            style="text-align: right;" placeholder="0" type="number" min="0" max="20000"
                            class="form-control" (change)="ChangeSanPhamSoLuongMa()">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số lượng mã TXNG cần phát hành</label>
                        <input name="SanPhamSoLuongMa" [(ngModel)]="LoHangService.FormData.SanPhamSoLuongMa"
                            style="text-align: right;" placeholder="0" type="number" min="0" max="20000"
                            class="form-control" (change)="ChangeSanPhamSoLuongMa()">
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu Bước 01" (click)="LoHangSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu Bước 01</a>
                </div>
            </div>
            <hr />
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4 style="text-align: center;"><b>Bước 05: Nhật ký và mua bán</b></h4>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4 style="text-align: center;"><b>5.1: Nhật ký</b></h4>
                </div>
                <div class="card mb-4 card-success">
                    <div class="card-header">
                        <h5 class="card-title text-white">Điền vào những nội dung phù hợp với hoạt động trong nhật ký mà
                            bạn cần tạo, thông tin này sẽ được công bố trong hệ thống TXNG cho người dùng xem khi quét
                            mã TXNG
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="table-outer">
                            <div class="table-responsive">
                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                    #LoHangNhatKySort="matSort" [dataSource]="LoHangNhatKyService.DataSource"
                                    class="table table-striped m-0">
                                    <ng-container matColumnDef="Name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Thông tin
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <div class="row gx-4">
                                                <div class="col-lg-8 col-sm-12 col-12">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ghi nhận</label>
                                                        <input [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                            style="text-align: right;"
                                                            (ngModelChange)="DateLoHangNhatKyNgayGhiNhan(element,$event)"
                                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                            class="form-control" autocomplete="off">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Quy trình</label>
                                                        <select class="form-select"
                                                            name="DanhMucQuyTrinhID{{element.ID}}"
                                                            [(ngModel)]="element.DanhMucQuyTrinhID">
                                                            <option *ngFor="let item of DanhMucQuyTrinhService.List;"
                                                                [value]="item.ID">
                                                                {{item.Name}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Vùng nguyên liệu</label>
                                                        <select class="form-select" name="VungTrongMaSo"
                                                            [(ngModel)]="element.VungTrongMaSo">
                                                            <option *ngFor="let item of ThanhVienVungTrongService.List;"
                                                                [value]="item.VuonSamMaSo">
                                                                {{item.VuonSamMaSo}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Tiêu đề</label>
                                                        <input type="text" placeholder="Tiêu đề"
                                                            name="Name{{element.ID}}" [(ngModel)]="element.Name"
                                                            class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Mô tả ngắn hoạt động</label>
                                                        <input type="text" placeholder="Mô tả ngắn hoạt động"
                                                            name="Note{{element.ID}}" [(ngModel)]="element.Note"
                                                            class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-sm-12 col-12">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a style="width: 100%;" class="btn btn-info" title="Lưu"
                                                            (click)="LoHangNhatKySave(element)"><i
                                                                class="bi bi-sd-card"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a style="width: 100%;" class="btn btn-info" title="Copy"
                                                            (click)="LoHangNhatKyCopy(element)" *ngIf="element.ID>0"><i
                                                                class="bi bi-subtract"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a style="width: 100%;" class="btn btn-danger" title="Xóa"
                                                            (click)="LoHangNhatKyDelete(element)"
                                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <div class="form-check form-check-inline mb-6">
                                                            <input type="checkbox" class="form-check-input"
                                                                name="Active{{element.ID}}"
                                                                [(ngModel)]="element.Active" />
                                                            <label class="form-check-label" for="Active">Hoàn
                                                                thành</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <div class="form-check form-check-inline mb-6">
                                                            <input type="checkbox" class="form-check-input"
                                                                name="IsCongBo{{element.ID}}"
                                                                [(ngModel)]="element.IsCongBo" />
                                                            <label class="form-check-label" for="IsCongBo">Công bố
                                                                lên hệ thống TXNG</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Tài liệu đính kèm</label>
                                                        <br />
                                                        <input type="file"
                                                            (change)="ChangeFileNameLoHangNhatKy($event.target.files)">
                                                        <br />
                                                        <br />
                                                        <img src="{{element.FileName}}" class="img-fluid rounded"
                                                            style="max-width: 200px;">
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="LoHangNhatKyService.DisplayColumns001">
                                    </tr>
                                    <tr mat-row *matRowDef="let row;columns: LoHangNhatKyService.DisplayColumns001">
                                    </tr>
                                </table>
                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                    #LoHangNhatKyPaginator="matPaginator"
                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                    [showFirstLastButtons]></mat-paginator>

                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                    #LoHangNhatKySort="matSort" [dataSource]="LoHangNhatKyService.DataSource"
                                    class="table table-striped m-0">
                                    <ng-container matColumnDef="STT">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Danh sách
                                        </th>
                                        <td mat-cell *matCellDef="let element; index as i">
                                            <div class="row gx-4">
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Quy trình</label>
                                                    <select class="form-select" name="DanhMucQuyTrinhID"
                                                        [(ngModel)]="element.DanhMucQuyTrinhID">
                                                        <option *ngFor="let item of DanhMucQuyTrinhService.List;"
                                                            [value]="item.ID">
                                                            {{item.Name}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Vùng nguyên liệu</label>
                                                    <select class="form-select" name="VungTrongMaSo"
                                                        [(ngModel)]="element.VungTrongMaSo">
                                                        <option *ngFor="let item of ThanhVienVungTrongService.List;"
                                                            [value]="item.VuonSamMaSo">
                                                            {{item.VuonSamMaSo}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Tiêu đề</label>
                                                    <input type="text" placeholder="Tiêu đề" name="Name{{element.ID}}"
                                                        [(ngModel)]="element.Name" class="form-control">
                                                </div>
                                                <div *ngIf="element.ID>0" class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Ghi nhận</label>
                                                    <input [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                        style="text-align: right;"
                                                        (ngModelChange)="DateLoHangNhatKyNgayGhiNhan(element,$event)"
                                                        [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                        class="form-control" autocomplete="off">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Mô tả ngắn hoạt động</label>
                                                    <input type="text" placeholder="Mô tả ngắn hoạt động"
                                                        name="Note{{element.ID}}" [(ngModel)]="element.Note"
                                                        class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <div class="form-check form-check-inline mb-6">
                                                        <input type="checkbox" class="form-check-input" name="Active"
                                                            [(ngModel)]="LoHangService.FormData.Active" />
                                                        <label class="form-check-label" for="Active">Hoàn
                                                            thành</label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <div class="form-check form-check-inline mb-6">
                                                        <input type="checkbox" class="form-check-input" name="IsCongBo"
                                                            [(ngModel)]="LoHangService.FormData.IsCongBo" />
                                                        <label class="form-check-label" for="IsCongBo">Công bố lên hệ
                                                            thống (Phê duyệt)</label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Tài liệu đính kèm</label>
                                                    <br />
                                                    <input type="file"
                                                        (change)="ChangeFileNameLoHangNhatKy(element, $event.target.files)">
                                                    <br />
                                                    <br />
                                                    <img src="{{element.FileName}}" class="img-fluid rounded"
                                                        style="max-width: 200px;">
                                                </div>
                                                <div class="row gx-4">
                                                    <div class="col-lg-4 col-sm-12 col-12">
                                                        <a class="btn btn-info" style="width: 100%" title="Lưu"
                                                            (click)="LoHangNhatKySave(element)"><i
                                                                class="bi bi-sd-card"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-4 col-sm-12 col-12">
                                                        <a style="width: 100%;" class="btn btn-info" title="Copy"
                                                            (click)="LoHangNhatKyCopy(element)" *ngIf="element.ID>0"><i
                                                                class="bi bi-subtract"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-4 col-sm-12 col-12">
                                                        <a class="btn btn-danger" style="width: 100%" title="Xóa"
                                                            (click)="LoHangNhatKyDelete(element)"
                                                            *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                        </a>
                                                    </div>
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="LoHangNhatKyService.DisplayColumnsMobile">
                                    </tr>
                                    <tr mat-row *matRowDef="let row;columns: LoHangNhatKyService.DisplayColumnsMobile">
                                    </tr>
                                </table>
                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                    #LoHangNhatKyPaginator="matPaginator"
                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                    [showFirstLastButtons]></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4 style="text-align: center;"><b>5.2: Mua bán | Trao đổi</b></h4>
                </div>
                <div class="card mb-4 card-warning">
                    <div class="card-header">
                        <h5 class="card-title text-white">Danh sách <span
                                *ngIf="LoHangMuaBanService.List">({{LoHangMuaBanService.List.length}}
                                items)</span>
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="table-outer">
                            <div class="table-responsive">
                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                    #LoHangMuaBanSort="matSort" [dataSource]="LoHangMuaBanService.DataSource"
                                    class="table table-striped m-0">
                                    <ng-container matColumnDef="STT">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            STT
                                        </th>
                                        <td mat-cell *matCellDef="let element; index as i" style="text-align: center">
                                            <div *ngIf="LoHangMuaBanPaginator">
                                                {{
                                                LoHangMuaBanPaginator.pageSize *
                                                LoHangMuaBanPaginator.pageIndex +
                                                i +
                                                1
                                                }}
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="ID">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.ID}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="DanhMucPhuongThucMuaBanID">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phương thức mua bán
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <select class="form-select" name="DanhMucPhuongThucMuaBanID"
                                                [(ngModel)]="element.DanhMucPhuongThucMuaBanID">
                                                <option *ngFor="let item of DanhMucPhuongThucMuaBanService.List;"
                                                    [value]="item.ID">
                                                    {{item.Name}}</option>
                                            </select>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="NgayGhiNhan">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <input [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                style="text-align: right;"
                                                (ngModelChange)="DateLoHangMuaBanNgayGhiNhan(element,$event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                autocomplete="off">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="DonGia">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Đơn giá
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <input type="number" placeholder="Đơn giá" name="DonGia{{element.ID}}"
                                                [(ngModel)]="element.DonGia" class="form-control"
                                                style="text-align: right;">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Display">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã số
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <input type="text" placeholder="Mã số" name="Display{{element.ID}}"
                                                [(ngModel)]="element.Display" class="form-control">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Người mua
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <input type="text" placeholder="Người mua" name="Name{{element.ID}}"
                                                [(ngModel)]="element.Name" class="form-control">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="DienThoai">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <input type="text" placeholder="Điện thoại" name="DienThoai{{element.ID}}"
                                                [(ngModel)]="element.DienThoai" class="form-control">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Note">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <input type="text" placeholder="Ghi chú" name="Note{{element.ID}}"
                                                [(ngModel)]="element.Note" class="form-control">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Save">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                        <td mat-cell *matCellDef="let element">
                                            <a class="btn btn-info" (click)="LoHangMuaBanSave(element)"><i
                                                    class="bi bi-sd-card"></i>
                                            </a>&nbsp;&nbsp;&nbsp;
                                            <a class="btn btn-danger" (click)="LoHangMuaBanDelete(element)"
                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                            </a>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="LoHangMuaBanService.DisplayColumns002">
                                    </tr>
                                    <tr mat-row *matRowDef="let row;columns: LoHangMuaBanService.DisplayColumns002">
                                    </tr>
                                </table>
                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                    #LoHangMuaBanPaginator="matPaginator"
                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                    [showFirstLastButtons]></mat-paginator>


                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                    #LoHangMuaBanSort="matSort" [dataSource]="LoHangMuaBanService.DataSource"
                                    class="table table-striped m-0">
                                    <ng-container matColumnDef="STT">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Danh sách
                                        </th>
                                        <td mat-cell *matCellDef="let element; index as i">
                                            <div class="row gx-4">
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label>Phương thức mua bán</label>
                                                    <select class="form-select" name="DanhMucPhuongThucMuaBanID"
                                                        [(ngModel)]="element.DanhMucPhuongThucMuaBanID">
                                                        <option
                                                            *ngFor="let item of DanhMucPhuongThucMuaBanService.List;"
                                                            [value]="item.ID">
                                                            {{item.Name}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label>Ghi nhận</label>
                                                    <input [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                        style="text-align: right;"
                                                        (ngModelChange)="DateLoHangMuaBanNgayGhiNhan(element,$event)"
                                                        [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                        class="form-control" autocomplete="off">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label>Đơn giá</label>
                                                    <input type="number" placeholder="Đơn giá"
                                                        name="DonGia{{element.ID}}" [(ngModel)]="element.DonGia"
                                                        class="form-control" style="text-align: right;">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label>Mã số</label>
                                                    <input name="Code" [(ngModel)]="element.Code" placeholder="Mã số"
                                                        type="text" class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label>Người mua</label>
                                                    <input name="Name" [(ngModel)]="element.Name"
                                                        placeholder="Người mua" type="text" class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label>Điện thoại</label>
                                                    <input type="text" placeholder="Điện thoại"
                                                        name="DienThoai{{element.ID}}" [(ngModel)]="element.DienThoai"
                                                        class="form-control">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label>Ghi chú</label>
                                                    <input name="Note" [(ngModel)]="element.Note" placeholder="Ghi chú"
                                                        type="text" class="form-control">
                                                </div>
                                                <div class="row gx-4">
                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                        <a class="btn btn-info" style="width: 100%"
                                                            (click)="LoHangMuaBanSave(element)"><i
                                                                class="bi bi-sd-card"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                        <a class="btn btn-danger" style="width: 100%"
                                                            (click)="LoHangMuaBanDelete(element)"
                                                            *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                        </a>
                                                    </div>
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="LoHangMuaBanService.DisplayColumnsMobile">
                                    </tr>
                                    <tr mat-row *matRowDef="let row;columns: LoHangMuaBanService.DisplayColumnsMobile">
                                    </tr>
                                </table>
                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                    #LoHangMuaBanPaginator="matPaginator"
                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                    [showFirstLastButtons]></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4 style="text-align: center;"><b>Bước 06: Danh sách tập tin công bố và hình ảnh</b></h4>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-primary">
                        <div class="card-header">
                            <h5 class="card-title text-white bg-primary">Thêm Tài liệu (nếu có)
                            </h5>
                        </div>
                        <div class="card-body bg-primary">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #LoHangTapTinDinhKemSort="matSort"
                                        [dataSource]="LoHangTapTinDinhKemService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="DanhMucTapTinDinhKemPhanLoaiID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân loại
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Phân loại</label>
                                                        <select class="form-select"
                                                            name="element.DanhMucTapTinDinhKemPhanLoaiID"
                                                            [(ngModel)]="element.DanhMucTapTinDinhKemPhanLoaiID">
                                                            <option
                                                                *ngFor="let item of DanhMucTapTinDinhKemPhanLoaiService.List;"
                                                                [value]="item.ID">
                                                                {{item.Name}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Tiêu đề</label>
                                                        <input class="form-control" type="text" placeholder="Tiêu đề"
                                                            name="Name{{element.ID}}" [(ngModel)]="element.Name">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Mã số</label>
                                                        <input class="form-control" type="text" placeholder="Mã số"
                                                            name="MaSo{{element.ID}}" [(ngModel)]="element.MaSo">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Nơi cấp</label>
                                                        <input class="form-control" type="text" placeholder="Nơi cấp"
                                                            name="NoiCap{{element.ID}}" [(ngModel)]="element.NoiCap">
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="NgayCap">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ngày cấp
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ngày cấp</label>
                                                        <input [ngModel]="element.NgayCap | date:'yyyy-MM-dd'"
                                                            style="text-align: right;"
                                                            (ngModelChange)="DateLoHangTapTinDinhKemNgayCap(element, $event)"
                                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                            class="form-control" autocomplete="off">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Hết hạn</label>
                                                        <input [ngModel]="element.HetHan | date:'yyyy-MM-dd'"
                                                            style="text-align: right;"
                                                            (ngModelChange)="DateLoHangTapTinDinhKemHetHan(element, $event)"
                                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                            class="form-control" autocomplete="off">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ghi chú</label>
                                                        <input class="form-control" type="text" placeholder="Ghi chú"
                                                            name="Note{{element.ID}}" [(ngModel)]="element.Note">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Tài liệu đính kèm</label>
                                                        <br />
                                                        <input type="file" style="width: 300px;"
                                                            (change)="ChangeFileNameLoHangTapTinDinhKem($event.target.files)">
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a class="btn btn-info" style="width: 100%;"
                                                            (click)="LoHangTapTinDinhKemSave(element)"><i
                                                                class="bi bi-sd-card"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a class="btn btn-danger" style="width: 100%;"
                                                            (click)="LoHangTapTinDinhKemDelete(element)"
                                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a class="btn btn-success" style="width: 100%;"
                                                            href="{{element.FileName}}" target="_blank"
                                                            *ngIf="element.ID>0 && element.FileName"><i
                                                                class="bi bi bi-download"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="LoHangTapTinDinhKemService.DisplayColumns001">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: LoHangTapTinDinhKemService.DisplayColumns001;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #LoHangTapTinDinhKemPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                        [pageSize]="1000" [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #LoHangTapTinDinhKemSort="matSort"
                                        [dataSource]="LoHangTapTinDinhKemService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Phân loại</label>
                                                        <select class="form-select"
                                                            name="element.DanhMucTapTinDinhKemPhanLoaiID"
                                                            [(ngModel)]="element.DanhMucTapTinDinhKemPhanLoaiID">
                                                            <option
                                                                *ngFor="let item of DanhMucTapTinDinhKemPhanLoaiService.List;"
                                                                [value]="item.ID">
                                                                {{item.Name}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Tiêu đề</label>
                                                        <input class="form-control" type="text" placeholder="Tiêu đề"
                                                            name="Name{{element.ID}}" [(ngModel)]="element.Name">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Mã số</label>
                                                        <input class="form-control" type="text" placeholder="Mã số"
                                                            name="MaSo{{element.ID}}" [(ngModel)]="element.MaSo">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Nơi cấp</label>
                                                        <input class="form-control" type="text" placeholder="Nơi cấp"
                                                            name="NoiCap{{element.ID}}" [(ngModel)]="element.NoiCap">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ngày cấp</label>
                                                        <input [ngModel]="element.NgayCap | date:'yyyy-MM-dd'"
                                                            style="text-align: right;"
                                                            (ngModelChange)="DateLoHangTapTinDinhKemNgayCap(element, $event)"
                                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                            class="form-control" autocomplete="off">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Hết hạn</label>
                                                        <input [ngModel]="element.HetHan | date:'yyyy-MM-dd'"
                                                            style="text-align: right;"
                                                            (ngModelChange)="DateLoHangTapTinDinhKemHetHan(element, $event)"
                                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                            class="form-control" autocomplete="off">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ghi chú</label>
                                                        <input class="form-control" type="text" placeholder="Ghi chú"
                                                            name="Note{{element.ID}}" [(ngModel)]="element.Note">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Tài liệu đính kèm</label>
                                                        <br />
                                                        <input type="file" style="width: 100px;"
                                                            (change)="ChangeFileNameLoHangTapTinDinhKem($event.target.files)">
                                                    </div>
                                                    <div class="row gx-4">
                                                        <div class="col-lg-4 col-sm-4 col-4">
                                                            <a class="btn btn-info" style="width: 100%;"
                                                                (click)="LoHangTapTinDinhKemSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>
                                                        </div>
                                                        <div class="col-lg-4 col-sm-4 col-4">
                                                            <a class="btn btn-danger" style="width: 100%;"
                                                                (click)="LoHangTapTinDinhKemDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </div>
                                                        <div class="col-lg-4 col-sm-4 col-4">
                                                            <a class="btn btn-success" style="width: 100%;"
                                                                href="{{element.FileName}}" target="_blank"
                                                                *ngIf="element.ID>0 && element.FileName"><i
                                                                    class="bi bi bi-download"></i>
                                                            </a>
                                                        </div>
                                                        <br />
                                                        <br />
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="LoHangTapTinDinhKemService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: LoHangTapTinDinhKemService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #LoHangTapTinDinhKemPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                        [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <h4 style="text-align: center;"><b>Bước 08: Nội dung phê duyệt</b></h4>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-danger" title="Kiểm tra trực tuyến"
                        (click)="LoHangKiemTra()">Kiểm tra trực tuyến</a>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-danger">
                        <div class="card-header">
                            <h5 class="card-title text-white">Cơ quan quản lý
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #LoHangPheDuyetSort="matSort" [dataSource]="LoHangPheDuyetService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                STT
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i"
                                                style="text-align: center">
                                                <div *ngIf="LoHangPheDuyetPaginator">
                                                    {{
                                                    LoHangPheDuyetPaginator.pageSize *
                                                    LoHangPheDuyetPaginator.pageIndex +
                                                    i +
                                                    1
                                                    }}
                                                </div>
                                                <br />
                                                [{{element.ID}}]
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="NgayGhiNhan">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Ghi nhận: &nbsp;</label>
                                                        <label class="form-label">{{element.NgayGhiNhan
                                                            | date:'dd/MM/yyyy HH:mm:ss'}}</label>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Tài liệu đính kèm</label>
                                                        <br />
                                                        <input type="file" style="width: 300px;"
                                                            (change)="ChangeFileNameLoHangPheDuyet($event.target.files)">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a *ngIf="element.ID==0" class="btn btn-info"
                                                            style="width: 100%;"
                                                            (click)="LoHangPheDuyetSave(element)"><i
                                                                class="bi bi-sd-card"></i>
                                                        </a>
                                                    </div>
                                                    <!-- <div class="col-lg-12 col-sm-12 col-12">
                                                        <a class="btn btn-danger" style="width: 100%;"
                                                            (click)="LoHangPheDuyetDelete(element)"
                                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                        </a>
                                                    </div> -->
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a class="btn btn-success" style="width: 100%;"
                                                            href="{{element.FileName}}" target="_blank"
                                                            *ngIf="element.ID>0 && element.FileName"><i
                                                                class="bi bi bi-download"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="TiepNhan">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Kiểm tra
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ngày Kiểm tra</label>
                                                        <input [ngModel]="element.KiemTra | date:'yyyy-MM-dd'"
                                                            style="text-align: right;"
                                                            (ngModelChange)="DateLoHangPheDuyetKiemTra(element, $event)"
                                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                            class="form-control" autocomplete="off">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ghi chú Kiểm tra</label>
                                                        <input type="text" placeholder="Ghi chú Kiểm tra"
                                                            name="Description{{element.ID}}"
                                                            [(ngModel)]="element.Description" class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="checkbox"
                                                                name="IsKiemTra{{element.ID}}"
                                                                [(ngModel)]="element.IsKiemTra">
                                                            <label class="form-check-label" for="IsKiemTra">Kiểm
                                                                tra</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="PheDuyet">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Phê duyệt
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ngày Phê duyệt</label>
                                                        <input [ngModel]="element.PheDuyet | date:'yyyy-MM-dd'"
                                                            style="text-align: right;"
                                                            (ngModelChange)="DateLoHangPheDuyetPheDuyet(element, $event)"
                                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                            class="form-control" autocomplete="off">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ghi chú Phê duyệt</label>
                                                        <input type="text" placeholder="Ghi chú Phê duyệt"
                                                            name="Note{{element.ID}}" [(ngModel)]="element.Note"
                                                            class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="checkbox"
                                                                name="IsPheDuyet{{element.ID}}"
                                                                [(ngModel)]="element.IsPheDuyet">
                                                            <label class="form-check-label" for="IsPheDuyet">Phê
                                                                duyệt</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="ThuHoi">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Thu hồi (nếu có)
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ngày Thu hồi</label>
                                                        <input [ngModel]="element.ThuHoi | date:'yyyy-MM-dd'"
                                                            style="text-align: right;"
                                                            (ngModelChange)="DateLoHangPheDuyetThuHoi(element, $event)"
                                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                            class="form-control" autocomplete="off">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ghi chú Thu hồi</label>
                                                        <input type="text" placeholder="Ghi chú Thu hồi"
                                                            name="HTMLContent{{element.ID}}"
                                                            [(ngModel)]="element.HTMLContent" class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="checkbox"
                                                                name="IsThuHoi{{element.ID}}"
                                                                [(ngModel)]="element.IsThuHoi">
                                                            <label class="form-check-label" for="IsThuHoi">Thu
                                                                hồi</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="LoHangPheDuyetService.DisplayColumns001">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row;columns: LoHangPheDuyetService.DisplayColumns001">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #LoHangPheDuyetPaginator="matPaginator"
                                        [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #LoHangPheDuyetSort="matSort" [dataSource]="LoHangPheDuyetService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ngày Kiểm tra</label>
                                                        <input [ngModel]="element.KiemTra | date:'yyyy-MM-dd'"
                                                            style="text-align: right;"
                                                            (ngModelChange)="DateLoHangPheDuyetKiemTra(element, $event)"
                                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                            class="form-control" autocomplete="off">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ghi chú Kiểm tra</label>
                                                        <input type="text" placeholder="Ghi chú Kiểm tra"
                                                            name="Description{{element.ID}}"
                                                            [(ngModel)]="element.Description" class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="checkbox"
                                                                name="IsKiemTra{{element.ID}}"
                                                                [(ngModel)]="element.IsKiemTra">
                                                            <label class="form-check-label" for="IsKiemTra">Kiểm
                                                                tra</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ngày Phê duyệt</label>
                                                        <input [ngModel]="element.PheDuyet | date:'yyyy-MM-dd'"
                                                            style="text-align: right;"
                                                            (ngModelChange)="DateLoHangPheDuyetPheDuyet(element, $event)"
                                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                            class="form-control" autocomplete="off">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ghi chú Phê duyệt</label>
                                                        <input type="text" placeholder="Ghi chú Phê duyệt"
                                                            name="Note{{element.ID}}" [(ngModel)]="element.Note"
                                                            class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="checkbox"
                                                                name="IsPheDuyet{{element.ID}}"
                                                                [(ngModel)]="element.IsPheDuyet">
                                                            <label class="form-check-label" for="IsPheDuyet">Phê
                                                                duyệt</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ngày Thu hồi (nếu có)</label>
                                                        <input [ngModel]="element.ThuHoi | date:'yyyy-MM-dd'"
                                                            style="text-align: right;"
                                                            (ngModelChange)="DateLoHangPheDuyetThuHoi(element, $event)"
                                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                            class="form-control" autocomplete="off">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Ghi chú Thu hồi</label>
                                                        <input type="text" placeholder="Ghi chú Thu hồi"
                                                            name="Note{{element.ID}}" [(ngModel)]="element.Note"
                                                            class="form-control">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="checkbox"
                                                                name="IsThuHoi{{element.ID}}"
                                                                [(ngModel)]="element.IsThuHoi">
                                                            <label class="form-check-label" for="IsThuHoi">Thu
                                                                hồi</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Tài liệu đính kèm</label>
                                                        <br />
                                                        <input type="file" style="width: 100px;"
                                                            (change)="ChangeFileNameLoHangTapTinDinhKem($event.target.files)">
                                                    </div>
                                                    <div class="row gx-4">
                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                            <a *ngIf="element.ID==0" class="btn btn-info"
                                                                style="width: 100%"
                                                                (click)="LoHangPheDuyetSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>
                                                        </div>
                                                        <!-- <div class="col-lg-4 col-sm-4 col-4">
                                                            <a class="btn btn-danger" style="width: 100%"
                                                                (click)="LoHangPheDuyetDelete(element)"
                                                                *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </div> -->
                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                            <a class="btn btn-success" style="width: 100%;"
                                                                href="{{element.FileName}}" target="_blank"
                                                                *ngIf="element.ID>0 && element.FileName"><i
                                                                    class="bi bi bi-download"></i>
                                                            </a>
                                                        </div>
                                                        <br />
                                                        <br />
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="LoHangPheDuyetService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row;columns: LoHangPheDuyetService.DisplayColumnsMobile">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #LoHangPheDuyetPaginator="matPaginator"
                                        [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="LoHangService.FormData.ID>0" class="tab-pane fade" id="TemPhieuKhoiTaoChiTietDetail"
            role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <h6 style="color: red;">+ Lô hàng phải được <b>Phê duyệt</b>. Vui lòng
                        kiểm tra ở bước 01</h6>
                    <h6 style="color: red;">+ Chuyển mã từ <b>[Kho tem]</b> sang <b>[Mã số truy vết sản phẩm]</b> chỉ áp
                        dụng đối
                        với <b>Phương thức sản xuất tem TXNG</b> là <b>Tem được cấp từ Cơ Quan Quản Lý</b>. Vui lòng
                        kiểm tra ở bước 01</h6>
                    <h6 style="color: red;">+ <b>Số lượng mã TXNG cần phát hành</b> phải nhỏ hơn Số lượng tem chưa sử
                        dụng
                        trong <b>[Kho tem]</b></h6>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <label class="form-label">Số lượng sản phẩm (dưới 20.000)</label>
                    <input name="SanPhamSoLuong" [(ngModel)]="LoHangService.FormData.SanPhamSoLuong"
                        style="text-align: right;" placeholder="0" type="number" min="0" max="20000"
                        class="form-control" (change)="ChangeSanPhamSoLuongMa()">
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <label class="form-label">Số lượng mã TXNG cần phát hành</label>
                    <input name="SanPhamSoLuongMa" [(ngModel)]="LoHangService.FormData.SanPhamSoLuongMa"
                        style="text-align: right; color: red; font-weight: bold;" placeholder="0" type="number" min="0"
                        max="20000" class="form-control" (change)="ChangeSanPhamSoLuongMa()">
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <label class="form-label">Kho tem chưa sử dụng</label>
                    <input style="text-align: right; color: green; font-weight: bold;" class="form-control"
                        value="{{TemPhieuKhoiTaoChiTietService.List.length}}" disabled>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <label class="form-label">Chọn tự động</label>
                    <br />
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" name="TemPhieuKhoiTaoChiTietIsActiveAll"
                            [(ngModel)]="TemPhieuKhoiTaoChiTietIsActiveAll"
                            (change)="TemPhieuKhoiTaoChiTietIsActiveAllChange()">
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-3 col-sm-12 col-12">
                    <label class="form-label">Lô tem</label>
                    <select class="form-select" name="DanhMucPhuongThucSanXuatID"
                        [(ngModel)]="TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString">
                        <option *ngFor="let item of TemPhieuKhoiTaoService.List;" [value]="item.Code">
                            {{item.Code}}: Số lượng cấp {{item.SoLuong}}</option>
                    </select>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <label class="form-label">Tìm...</label>
                    <input [(ngModel)]="TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString001" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="TemPhieuKhoiTaoChiTietSearch()">
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <label class="form-label">.</label>
                    <a style="width: 100%;" class="btn btn-success" title="Tìm"
                        (click)="TemPhieuKhoiTaoChiTietSearch()"><i class="bi bi-search"></i></a>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <label class="form-label">.</label>
                    <button style="width: 100%;" class="btn btn-info" title="Chuyển sang [Mã số truy vết]"
                        [disabled]="LoHangService.FormData.ID==0 || LoHangService.FormData.DanhMucPhuongThucSanXuatID!=2 || LoHangService.FormData.IsPheDuyet!=true || TemPhieuKhoiTaoChiTietService.List.length==0"
                        (click)="TemPhieuKhoiTaoChiTietSaveList()">Chuyển sang [Mã số truy vết]</button>
                </div>
            </div>
            <div class="card mb-4 card-warning">
                <div class="card-header">
                    <h5 class="card-title text-white">
                        Số lượng tem chưa sử dụng
                        <span *ngIf="TemPhieuKhoiTaoChiTietPaginator">({{TemPhieuKhoiTaoChiTietPaginator.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                #TemPhieuKhoiTaoChiTietSort="matSort"
                                [dataSource]="TemPhieuKhoiTaoChiTietService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i" style="text-align: center">
                                        <div *ngIf="TemPhieuKhoiTaoChiTietPaginator">
                                            {{TemPhieuKhoiTaoChiTietPaginator.pageSize *
                                            TemPhieuKhoiTaoChiTietPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ID}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Code">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Lô tem
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.Code}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="MaSo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Mã số
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.MaSo}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="URL">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        URL
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.URL}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Active">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sử dụng
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" name="Active{{element.ID}}"
                                                [(ngModel)]="element.Active">
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="TemPhieuKhoiTaoChiTietService.DisplayColumns002">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: TemPhieuKhoiTaoChiTietService.DisplayColumns002">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile"
                                #TemPhieuKhoiTaoChiTietPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                [showFirstLastButtons]></mat-paginator>

                            <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                #TemPhieuKhoiTaoChiTietort="matSort"
                                [dataSource]="TemPhieuKhoiTaoChiTietService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Lô tem: </label>
                                                <label class="form-label">{{element.Code}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Mã số: </label>
                                                <label class="form-label">{{element.MaSo}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>URL: </label>
                                                <label class="form-label">{{element.URL}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox"
                                                        name="Active{{element.ID}}" [(ngModel)]="element.Active">
                                                    <label class="form-label">Chưa sử dụng</label>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row
                                    *matHeaderRowDef="TemPhieuKhoiTaoChiTietService.DisplayColumnsMobile"></tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: TemPhieuKhoiTaoChiTietService.DisplayColumnsMobile">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile"
                                #TemPhieuKhoiTaoChiTietPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="LoHangService.FormData.ID>0" class="tab-pane fade" id="TemPhieuDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-10 col-sm-12 col-12">
                    <input [(ngModel)]="TemPhieuService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="TemPhieuSearch()">
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="TemPhieuSearch()"><i
                            class="bi bi-search"></i></a>
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tải về" (click)="TemPhieuDownload()"><i
                            class="bi bi-cloud-download"></i></a>
                </div>
            </div>
            <div class="card mb-4 card-success">
                <div class="card-header">
                    <h5 class="card-title text-white">
                        Danh sách
                        <span *ngIf="TemPhieuService.List">({{TemPhieuService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort #TemPhieuSort="matSort"
                                [dataSource]="TemPhieuService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i" style="text-align: center">
                                        <div *ngIf="TemPhieuPaginator">
                                            {{TemPhieuPaginator.pageSize *
                                            TemPhieuPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ID}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="MaSo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Mã số truy vết sản phẩm (Dùng cho tra cứu)
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer;" title="{{element.MaSo}}" class="link-primary"
                                            (click)="TemPhieuLichSuTruyCapAdd(element.ID)"><b>{{element.MaSo}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="URL">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        URL
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a title="{{element.URL}}" href="{{element.URL}}"
                                            target="_blank">{{element.URL}}</a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="SoLanKiemTra">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Hiển thị
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="text-align: right;">
                                        {{element.SoLanKiemTra}}
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="TemPhieuService.DisplayColumns001"></tr>
                                <tr mat-row *matRowDef="let row; columns: TemPhieuService.DisplayColumns001">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile" #TemPhieuPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                [showFirstLastButtons]></mat-paginator>

                            <table *ngIf="NotificationService.IsMobile" mat-table matSort #TemPhieuSort="matSort"
                                [dataSource]="TemPhieuService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Mã số truy vết sản phẩm (Dùng cho tra cứu): </label>
                                                <label class="form-label">{{element.MaSo}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>URL: </label>
                                                <label class="form-label">{{element.URL}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Hiển thị: </label>
                                                <label class="form-label">{{element.SoLanKiemTra}}</label>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="TemPhieuService.DisplayColumnsMobile"></tr>
                                <tr mat-row *matRowDef="let row; columns: TemPhieuService.DisplayColumnsMobile">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile" #TemPhieuPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="LoHangService.FormData.ID>0" class="tab-pane fade" id="LoHangBinhChonDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-10 col-sm-12 col-12">
                    <input [(ngModel)]="LoHangPhanAnhService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="LoHangBinhChonSearch()">
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="LoHangBinhChonSearch()"><i
                            class="bi bi-search"></i></a>
                </div>
            </div>
            <div class="card mb-4 card-warning">
                <div class="card-header">
                    <h5 class="card-title text-white">
                        Danh sách
                        <span *ngIf="LoHangBinhChonService.List">({{LoHangBinhChonService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort #LoHangBinhChonSort="matSort"
                                [dataSource]="LoHangBinhChonService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i" style="text-align: center">
                                        <div *ngIf="LoHangBinhChonPaginator">
                                            {{LoHangBinhChonPaginator.pageSize *
                                            LoHangBinhChonPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="LastUpdatedDate">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Ghi nhận
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="text-align: right;">
                                        {{element.LastUpdatedDate |
                                        date:'dd/MM/yyyy HH:mm:ss'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Code">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Mã số
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.Code}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="SortOrder">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Bình chọn
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="text-align: right;">
                                        {{element.SortOrder}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Description">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Mô tả
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.Description}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="HoTen">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Họ tên
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.HoTen}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DienThoai">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Điện thoại
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.DienThoai}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Email">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Email
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.Email}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DonViCongTac">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Đơn vị công tác
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.DonViCongTac}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DanhMucQuocGiaName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Quốc gia
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.DanhMucQuocGiaName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DanhMucTinhThanhName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Khu vực
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.DanhMucTinhThanhName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="IPAddress">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Địa chỉ IP
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.IPAddress}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="KinhDo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Kinh độ
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.KinhDo}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ViDo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Vĩ độ
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ViDo}}
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="LoHangBinhChonService.DisplayColumns001"></tr>
                                <tr mat-row *matRowDef="let row; columns: LoHangBinhChonService.DisplayColumns001">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile" #LoHangBinhChonPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                [showFirstLastButtons]></mat-paginator>

                            <table *ngIf="NotificationService.IsMobile" mat-table matSort #LoHangBinhChonSort="matSort"
                                [dataSource]="LoHangBinhChonService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i">
                                        <div class="row gx-4">
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="LoHangBinhChonService.DisplayColumnsMobile"></tr>
                                <tr mat-row *matRowDef="let row; columns: LoHangBinhChonService.DisplayColumnsMobile">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile" #LoHangBinhChonPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="LoHangService.FormData.ID>0" class="tab-pane fade" id="LoHangPhanAnhDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-10 col-sm-12 col-12">
                    <input [(ngModel)]="LoHangPhanAnhService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="LoHangPhanAnhSearch()">
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="LoHangPhanAnhSearch()"><i
                            class="bi bi-search"></i></a>
                </div>
            </div>
            <div class="card mb-4 card-danger">
                <div class="card-header">
                    <h5 class="card-title text-white">
                        Danh sách
                        <span *ngIf="LoHangPhanAnhService.List">({{LoHangPhanAnhService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort #LoHangPhanAnhSort="matSort"
                                [dataSource]="LoHangPhanAnhService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i" style="text-align: center">
                                        <div *ngIf="LoHangBinhChonPaginator">
                                            {{LoHangBinhChonPaginator.pageSize *
                                            LoHangBinhChonPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="LastUpdatedDate">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Ghi nhận
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="text-align: right;">
                                        {{element.LastUpdatedDate |
                                        date:'dd/MM/yyyy HH:mm:ss'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Code">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Mã số
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.Code}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Description">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Mô tả
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.Description}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="HoTen">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Họ tên
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.HoTen}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DienThoai">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Điện thoại
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.DienThoai}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Email">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Email
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.Email}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DonViCongTac">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Đơn vị công tác
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.DonViCongTac}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DanhMucQuocGiaName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Quốc gia
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.DanhMucQuocGiaName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DanhMucTinhThanhName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Khu vực
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.DanhMucTinhThanhName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="IPAddress">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Địa chỉ IP
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.IPAddress}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="KinhDo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Kinh độ
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.KinhDo}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ViDo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Vĩ độ
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ViDo}}
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="LoHangPhanAnhService.DisplayColumns001"></tr>
                                <tr mat-row *matRowDef="let row; columns: LoHangPhanAnhService.DisplayColumns001">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile" #LoHangPhanAnhPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                [showFirstLastButtons]></mat-paginator>

                            <table *ngIf="NotificationService.IsMobile" mat-table matSort #LoHangPhanAnhSort="matSort"
                                [dataSource]="LoHangPhanAnhService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Họ tên: </label>
                                                <label class="form-label">{{element.Display}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Điện thoại: </label>
                                                <label class="form-label">{{element.HTMLContent}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Email: </label>
                                                <label class="form-label">{{element.TypeName}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Đơn vị: </label>
                                                <label class="form-label">{{element.Description}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Phản ánh: </label>
                                                <label class="form-label">{{element.Note}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Ghi nhận: </label>
                                                <label class="form-label">{{element.LastUpdatedDate |
                                                    date:'dd/MM/yyyy HH:mm:ss'}}</label>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="LoHangPhanAnhService.DisplayColumnsMobile"></tr>
                                <tr mat-row *matRowDef="let row; columns: LoHangPhanAnhService.DisplayColumnsMobile">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile" #LoHangPhanAnhPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="LoHangService.FormData.ID>0" class="tab-pane fade" id="LoHang001Detail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-10 col-sm-12 col-12">
                    <input [(ngModel)]="LoHangService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="LoHang001Search()">
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="LoHang001Search()"><i
                            class="bi bi-search"></i></a>
                </div>
            </div>
            <div class="card mb-4 card-primary">
                <div class="card-header">
                    <h5 class="card-title text-white bg-primary">
                        Danh sách
                        <span *ngIf="LoHangService.ListFilter">({{LoHangService.ListFilter.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body bg-primary">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort #LoHang001Sort="matSort"
                                [dataSource]="LoHangService.DataSourceFilter" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i" style="text-align: center">
                                        <div *ngIf="LoHang001Paginator">
                                            {{LoHang001Paginator.pageSize *
                                            LoHang001Paginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ID}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ParentName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Đơn vị đăng ký
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <b>{{element.ParentName}}</b>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="TypeName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Type
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.TypeName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Code">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Code
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer;" title="{{element.Code}}" class="link-primary"
                                            (click)="LoHangAdd(element.ID)"><b>{{element.Code}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="PhienBan">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Phiên bản
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="text-align: right;">
                                        {{element.PhienBan}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="LastUpdatedDate">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Ghi nhận
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="text-align: right;">
                                        {{element.LastUpdatedDate |
                                        date:'dd/MM/yyyy HH:mm:ss'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Active">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" name="Active{{element.ID}}"
                                                [(ngModel)]="element.Active">
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="LoHangService.DisplayColumns005"></tr>
                                <tr mat-row *matRowDef="let row; columns: LoHangService.DisplayColumns005">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile" #LoHang001Paginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                [showFirstLastButtons]></mat-paginator>

                            <table *ngIf="NotificationService.IsMobile" mat-table matSort #LoHang001ort="matSort"
                                [dataSource]="LoHangService.DataSourceFilter" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Đơn vị đăng ký: </label>
                                                <label class="form-label">{{element.ParentName}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Type: </label>
                                                <label class="form-label">{{element.TypeName}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Code: </label>
                                                <label class="form-label">{{element.Code}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Phiên bản: </label>
                                                <label class="form-label">{{element.PhienBan}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Ghi nhận: </label>
                                                <label class="form-label">{{element.LastUpdatedDate |
                                                    date:'dd/MM/yyyy HH:mm:ss'}}</label>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="LoHangService.DisplayColumnsMobile"></tr>
                                <tr mat-row *matRowDef="let row; columns: LoHangService.DisplayColumnsMobile">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile" #LoHang001Paginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="LoHangService.FormData.ID>0" class="tab-pane fade" id="TemPhieuLichSuTruyCapDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-11 col-sm-12 col-12">
                    <input [(ngModel)]="TemPhieuLichSuTruyCapService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="TemPhieuLichSuTruyCapSearch()">
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm"
                        (click)="TemPhieuLichSuTruyCapSearch()"><i class="bi bi-search"></i></a>
                </div>
            </div>
            <div class="card mb-4 card-success">
                <div class="card-header">
                    <h5 class="card-title text-white">
                        Danh sách
                        <span *ngIf="TemPhieuLichSuTruyCapService.List">({{TemPhieuLichSuTruyCapService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                #TemPhieuLichSuTruyCapSort="matSort"
                                [dataSource]="TemPhieuLichSuTruyCapService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i" style="text-align: center">
                                        <div *ngIf="TemPhieuLichSuTruyCapPaginator">
                                            {{TemPhieuLichSuTruyCapPaginator.pageSize *
                                            TemPhieuLichSuTruyCapPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="LastUpdatedDate">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Ghi nhận
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="text-align: right;">
                                        {{element.LastUpdatedDate |
                                        date:'dd/MM/yyyy HH:mm:ss'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Mã số truy vết sản phẩm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.Name}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="TypeName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Thiết bị
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.TypeName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="IPAddress">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Địa chỉ IP
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.IPAddress}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DanhMucQuocGiaName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Quốc gia
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.DanhMucQuocGiaName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DanhMucTinhThanhName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Khu vực
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.DanhMucTinhThanhName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="KinhDo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Kinh độ
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.KinhDo}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ViDo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Vĩ độ
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ViDo}}
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="TemPhieuLichSuTruyCapService.DisplayColumns002">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: TemPhieuLichSuTruyCapService.DisplayColumns002">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile"
                                #TemPhieuLichSuTruyCapPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                [showFirstLastButtons]></mat-paginator>

                            <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                #TemPhieuLichSuTruyCapSort="matSort"
                                [dataSource]="TemPhieuLichSuTruyCapService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i">
                                        <div class="row gx-4">
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="TemPhieuLichSuTruyCapService.DisplayColumnsMobile">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: TemPhieuLichSuTruyCapService.DisplayColumnsMobile">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile"
                                #TemPhieuLichSuTruyCapPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="LoHangService.IsShowLoading"></app-loading>