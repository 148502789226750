import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';


import { ThanhVienVungTrongToaDo } from 'src/app/shared/ThanhVienVungTrongToaDo.model';
import { ThanhVienVungTrongToaDoService } from 'src/app/shared/ThanhVienVungTrongToaDo.service';

@Component({
  selector: 'app-thanh-vien-vung-trong-toa-do-detail',
  templateUrl: './thanh-vien-vung-trong-toa-do-detail.component.html',
  styleUrls: ['./thanh-vien-vung-trong-toa-do-detail.component.css']
})
export class ThanhVienVungTrongToaDoDetailComponent implements OnInit {

  @ViewChild('ThanhVienVungTrongToaDoSort') ThanhVienVungTrongToaDoSort: MatSort;
  @ViewChild('ThanhVienVungTrongToaDoPaginator') ThanhVienVungTrongToaDoPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ThanhVienVungTrongToaDoDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public ThanhVienVungTrongToaDoService: ThanhVienVungTrongToaDoService,
  ) { }

  ngOnInit(): void {
    this.ThanhVienVungTrongToaDoSearch();
  }
  ThanhVienVungTrongToaDoSearch() {    
    this.ThanhVienVungTrongToaDoService.SearchByParentID(this.ThanhVienVungTrongToaDoSort, this.ThanhVienVungTrongToaDoPaginator);
  }
  ThanhVienVungTrongToaDoSave(element: ThanhVienVungTrongToaDo) {
    element.ParentID = this.ThanhVienVungTrongToaDoService.BaseParameter.ParentID;
    this.ThanhVienVungTrongToaDoService.FormData = element;
    this.ThanhVienVungTrongToaDoService.ComponentSaveByParentID(this.ThanhVienVungTrongToaDoSort, this.ThanhVienVungTrongToaDoPaginator);
  }
  ThanhVienVungTrongToaDoDelete(element: ThanhVienVungTrongToaDo) {
    this.ThanhVienVungTrongToaDoService.BaseParameter.ID = element.ID;
    this.ThanhVienVungTrongToaDoService.ComponentDeleteByParentID(this.ThanhVienVungTrongToaDoSort, this.ThanhVienVungTrongToaDoPaginator);
  }
  Close() {
    this.dialogRef.close();
  }
}
