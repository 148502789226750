import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { BaiViet } from 'src/app/shared/BaiViet.model';
import { BaiVietService } from 'src/app/shared/BaiViet.service';

import { BaiVietDetailComponent } from '../bai-viet-detail/bai-viet-detail.component';


@Component({
  selector: 'app-bai-viet',
  templateUrl: './bai-viet.component.html',
  styleUrls: ['./bai-viet.component.css']
})
export class BaiVietComponent implements OnInit {

  @ViewChild('BaiVietSort') BaiVietSort: MatSort;
  @ViewChild('BaiVietPaginator') BaiVietPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
    
    public BaiVietService: BaiVietService,

  ) { }

  ngOnInit(): void {    
    this.BaiVietSearch();
  }
  BaiVietSearch() {
    this.BaiVietService.SearchAllNotEmpty(this.BaiVietSort, this.BaiVietPaginator);
  }
  BaiVietDelete(element: BaiViet) {
    this.BaiVietService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.BaiVietService.ComponentDeleteAllNotEmpty(this.BaiVietSort, this.BaiVietPaginator));
  }  
  BaiVietAdd(ID: number) {
    this.BaiVietService.BaseParameter.ID = ID;
    this.BaiVietService.GetByIDAsync().subscribe(
      res => {
        this.BaiVietService.FormData = res as BaiViet;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(BaiVietDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.BaiVietSearch();
        });
      },
      err => {
      }
    );
  } 
}