import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThanhVienKho } from 'src/app/shared/ThanhVienKho.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThanhVienKhoService extends BaseService{

    DisplayColumns001: string[] = ['STT', 'ID', 'NamGhiNhan', 'VuonSamCayGiongHat', 'VuonSamCayGiongNamTuoi01', 'VuonSamCayGiongNamTuoi02', 'VuonSamCayGiongNamTuoi05', 'VuonSamCayGiongNamTuoi09', 'VuonSamCayGiongNamTuoi10', 'Save'];  

    DisplayColumns002: string[] = ['NamGhiNhan', 'VuonSamCayGiongLa', 'VuonSamCayGiongHat', 'VuonSamCayGiongNamTuoi01', 'VuonSamCayGiongNamTuoi02', 'VuonSamCayGiongNamTuoi03', 'VuonSamCayGiongNamTuoi04', 'VuonSamCayGiongNamTuoi05', 'VuonSamCayGiongNamTuoi06', 'VuonSamCayGiongNamTuoi07', 'VuonSamCayGiongNamTuoi08', 'VuonSamCayGiongNamTuoi09', 'VuonSamCayGiongNamTuoi10', 'VuonSamCayGiongNamTuoi11', 'VuonSamCayGiongNamTuoi21', 'Save'];    
        
    List: ThanhVienKho[] | undefined;
    ListFilter: ThanhVienKho[] | undefined;
    FormData!: ThanhVienKho;
    FormDataLogin!: ThanhVienKho;

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "ThanhVienKho";
    }
}

