import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoHangPheDuyet } from './LoHangPheDuyet.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class LoHangPheDuyetService extends BaseService{

    DisplayColumns001: string[] = ['STT', 'NgayGhiNhan', 'TiepNhan', 'PheDuyet', 'ThuHoi'];
        
    List: LoHangPheDuyet[] | undefined;
    ListFilter: LoHangPheDuyet[] | undefined;
    FormData!: LoHangPheDuyet;

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "LoHangPheDuyet";
    }

    GetByTypeNameToListAsync() {
        let url = this.APIURL + this.Controller + '/GetByTypeNameToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    GetByTypeNameAndEmptyToListAsync() {
        let url = this.APIURL + this.Controller + '/GetByTypeNameAndEmptyToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
}

