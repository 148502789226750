import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

import { ThanhVienDetailComponent } from '../thanh-vien-detail/thanh-vien-detail.component';

import { TruyXuatNguonGocDanhMucUngDung } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.model';
import { TruyXuatNguonGocDanhMucUngDungService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.service';
import { TruyXuatNguonGocToChuc } from 'src/app/shared/TruyXuatNguonGoc/ToChuc.model';
import { TruyXuatNguonGocToChucService } from 'src/app/shared/TruyXuatNguonGoc/ToChuc.service';
import { TruyXuatNguonGocThanhVien } from 'src/app/shared/TruyXuatNguonGoc/ThanhVien.model';
import { TruyXuatNguonGocThanhVienService } from 'src/app/shared/TruyXuatNguonGoc/ThanhVien.service';

import { BlockchainDanhMucUngDung } from 'src/app/shared/Blockchain/DanhMucUngDung.model';
import { BlockchainDanhMucUngDungService } from 'src/app/shared/Blockchain/DanhMucUngDung.service';
import { BlockchainThanhVien } from 'src/app/shared/Blockchain/ThanhVien.model';
import { BlockchainThanhVienService } from 'src/app/shared/Blockchain/ThanhVien.service';

@Component({
  selector: 'app-thanh-vien',
  templateUrl: './thanh-vien.component.html',
  styleUrls: ['./thanh-vien.component.css']
})
export class ThanhVienComponent implements OnInit {

  @ViewChild('ThanhVienSort') ThanhVienSort: MatSort;
  @ViewChild('ThanhVienPaginator') ThanhVienPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public ThanhVienService: ThanhVienService,

    public TruyXuatNguonGocDanhMucUngDungService: TruyXuatNguonGocDanhMucUngDungService,
    public TruyXuatNguonGocToChucService: TruyXuatNguonGocToChucService,
    public TruyXuatNguonGocThanhVienService: TruyXuatNguonGocThanhVienService,

    public BlockchainDanhMucUngDungService: BlockchainDanhMucUngDungService,
    public BlockchainThanhVienService: BlockchainThanhVienService,


  ) { }

  ngOnInit(): void {
    this.TruyXuatNguonGocDanhMucUngDungService.GetLogin();
    this.BlockchainDanhMucUngDungService.GetLogin();
    this.ThanhVienService.List = [];
    //this.ThanhVienSearch();
  }
  ThanhVienSearch() {
    this.ThanhVienService.SearchAllNotEmpty(this.ThanhVienSort, this.ThanhVienPaginator);
  }
  ThanhVienAdd(ID: number) {
    this.ThanhVienService.BaseParameter.ID = ID;
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.FormData = res as ThanhVien;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ThanhVienDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.ThanhVienSearch();
        });
      },
      err => {
      }
    );
  }
  ThanhVienSync() {

    let BlockchainDanhMucUngDungID = Number(localStorage.getItem(environment.BlockchainDanhMucUngDungID));    
    if (BlockchainDanhMucUngDungID > 0) {
      this.ThanhVienService.IsShowLoading = true;
      this.ThanhVienService.GetAllToListAsync().subscribe(
        res => {
          let ListThanhVien = (res as ThanhVien[]);
          this.TruyXuatNguonGocThanhVienService.List = [];
          this.TruyXuatNguonGocToChucService.List = [];
          this.BlockchainThanhVienService.List = [];

          for (let i = 0; i < ListThanhVien.length; i++) {
            let ThanhVien = ListThanhVien[i];

            if (ThanhVien.BlockchainID > 0) {
            }
            else {
              let BlockchainThanhVien: BlockchainThanhVien = {
                ID: environment.InitializationNumber,
                Active: ThanhVien.Active,
                IDToken: ThanhVien.ID,
                Token: localStorage.getItem(environment.BlockchainToKen),
                DanhMucUngDungID: BlockchainDanhMucUngDungID,
                Name: ThanhVien.Name,
                Code: ThanhVien.Code,
                TaiKhoan: ThanhVien.TaiKhoan,
                DienThoai: ThanhVien.DienThoai,
                Email: ThanhVien.Email,
              }
              this.BlockchainThanhVienService.List.push(BlockchainThanhVien);
            }
          }                   
          if (this.BlockchainThanhVienService.List.length > 0) {
            this.ThanhVienService.IsShowLoading = true;
            this.BlockchainThanhVienService.SaveListSyncAsync(this.BlockchainThanhVienService.List).subscribe(
              res => {
                this.BlockchainThanhVienService.List = (res as BlockchainThanhVien[]);                
                this.ThanhVienService.List = [];
                for (let i = 0; i < this.BlockchainThanhVienService.List.length; i++) {
                  let BlockchainThanhVien = this.BlockchainThanhVienService.List[i];
                  if (BlockchainThanhVien.ID > 0) {
                    let ListThanhVienSub = ListThanhVien.filter((item: any) => item.ID == BlockchainThanhVien.IDToken);
                    if (ListThanhVienSub.length > 0) {
                      ListThanhVienSub[0].BlockchainID = BlockchainThanhVien.ID;
                      this.ThanhVienService.List.push(ListThanhVienSub[0]);
                    }
                  }
                }

                if (this.ThanhVienService.List.length > 0) {
                  this.ThanhVienService.IsShowLoading = true;
                  this.ThanhVienService.SaveListSyncAsync(this.ThanhVienService.List).subscribe(
                    res => {
                      this.ThanhVienService.List = (res as ThanhVien[]);                      
                      this.ThanhVienService.IsShowLoading = false;
                    },
                    err => {                     
                      this.ThanhVienService.IsShowLoading = false;
                    }
                  );
                }
              },
              err => {                
                this.ThanhVienService.IsShowLoading = false;
              }
            );
          }
          else {
            this.ThanhVienService.IsShowLoading = false;
          }


        },
        err => {
          this.ThanhVienService.IsShowLoading = false;
        }
      );
    }

    let TruyXuatNguonGocDanhMucUngDungID = Number(localStorage.getItem(environment.TruyXuatNguonGocDanhMucUngDungID));
    if (TruyXuatNguonGocDanhMucUngDungID > 0) {
      this.ThanhVienService.IsShowLoading = true;
      this.ThanhVienService.GetAllToListAsync().subscribe(
        res => {
          let ListThanhVien = (res as ThanhVien[]);
          this.TruyXuatNguonGocThanhVienService.List = [];
          this.TruyXuatNguonGocToChucService.List = [];
          this.BlockchainThanhVienService.List = [];

          for (let i = 0; i < ListThanhVien.length; i++) {
            let ThanhVien = ListThanhVien[i];

            if (ThanhVien.TruyXuatNguonGocID > 0) {
            }
            else {
              let TruyXuatNguonGocThanhVien: TruyXuatNguonGocThanhVien = {
                ID: environment.InitializationNumber,
                Active: ThanhVien.Active,
                IDToken: ThanhVien.ID,
                Token: localStorage.getItem(environment.TruyXuatNguonGocToKen),
                DanhMucUngDungID: TruyXuatNguonGocDanhMucUngDungID,
                Name: ThanhVien.Name,
                Code: ThanhVien.Code,
                TaiKhoan: ThanhVien.TaiKhoan,
                DienThoai: ThanhVien.DienThoai,
                Email: ThanhVien.Email,
              }
              this.TruyXuatNguonGocThanhVienService.List.push(TruyXuatNguonGocThanhVien);
            }

            if (ThanhVien.TruyXuatNguonGocToChucID > 0) {
            }
            else {
              let TruyXuatNguonGocToChuc: TruyXuatNguonGocToChuc = {
                ID: environment.InitializationNumber,
                Active: ThanhVien.Active,
                IDToken: ThanhVien.ID,
                Token: localStorage.getItem(environment.TruyXuatNguonGocToKen),
                DanhMucUngDungID: TruyXuatNguonGocDanhMucUngDungID,
                Name: ThanhVien.Name,
                Code: ThanhVien.Code,
                MaSoThue: ThanhVien.CCCD,
                DienThoai: ThanhVien.DienThoai,
                Email: ThanhVien.Email,
                DanhMucTinhThanhID: ThanhVien.DanhMucTinhThanhID,
                DanhMucQuanHuyenID: ThanhVien.DanhMucQuanHuyenID,
                DanhMucXaPhuongID: ThanhVien.DanhMucXaPhuongID,
                DanhMucXaPhuongName: ThanhVien.DanhMucXaPhuongName,
              }
              this.TruyXuatNguonGocToChucService.List.push(TruyXuatNguonGocToChuc);
            }
          }

          if (this.TruyXuatNguonGocThanhVienService.List.length > 0) {
            this.ThanhVienService.IsShowLoading = true;
            this.TruyXuatNguonGocThanhVienService.SaveListSyncAsync(this.TruyXuatNguonGocThanhVienService.List).subscribe(
              res => {
                this.TruyXuatNguonGocThanhVienService.List = (res as TruyXuatNguonGocThanhVien[]);
                this.ThanhVienService.List = [];
                for (let i = 0; i < this.TruyXuatNguonGocThanhVienService.List.length; i++) {
                  let TruyXuatNguonGocThanhVien = this.TruyXuatNguonGocThanhVienService.List[i];
                  if (TruyXuatNguonGocThanhVien.ID > 0) {
                    let ListThanhVienSub = ListThanhVien.filter((item: any) => item.ID == TruyXuatNguonGocThanhVien.IDToken);
                    if (ListThanhVienSub.length > 0) {
                      ListThanhVienSub[0].TruyXuatNguonGocID = TruyXuatNguonGocThanhVien.ID;
                      this.ThanhVienService.List.push(ListThanhVienSub[0]);
                    }
                  }
                }

                if (this.ThanhVienService.List.length > 0) {
                  this.ThanhVienService.IsShowLoading = true;
                  this.ThanhVienService.SaveListSyncAsync(this.ThanhVienService.List).subscribe(
                    res => {
                      this.ThanhVienService.List = (res as ThanhVien[]);
                      this.ThanhVienService.IsShowLoading = false;
                    },
                    err => {
                      this.ThanhVienService.IsShowLoading = false;
                    }
                  );
                }
              },
              err => {
                this.ThanhVienService.IsShowLoading = false;
              }
            );
          }

          if (this.TruyXuatNguonGocToChucService.List.length > 0) {
            this.ThanhVienService.IsShowLoading = true;
            this.TruyXuatNguonGocToChucService.SaveListAsync(this.TruyXuatNguonGocToChucService.List).subscribe(
              res => {
                this.TruyXuatNguonGocToChucService.List = (res as TruyXuatNguonGocToChuc[]);
                this.ThanhVienService.List = [];
                for (let i = 0; i < this.TruyXuatNguonGocToChucService.List.length; i++) {
                  let TruyXuatNguonGocToChuc = this.TruyXuatNguonGocToChucService.List[i];
                  if (TruyXuatNguonGocToChuc.ID > 0) {
                    let ListThanhVienSub = ListThanhVien.filter((item: any) => item.ID == TruyXuatNguonGocToChuc.IDToken);
                    if (ListThanhVienSub.length > 0) {
                      ListThanhVienSub[0].TruyXuatNguonGocToChucID = TruyXuatNguonGocToChuc.ID;
                      ListThanhVienSub[0].DanhMucTinhThanhName = TruyXuatNguonGocToChuc.DanhMucTinhThanhName;
                      ListThanhVienSub[0].DanhMucQuanHuyenName = TruyXuatNguonGocToChuc.DanhMucQuanHuyenName;
                      ListThanhVienSub[0].DanhMucXaPhuongName = TruyXuatNguonGocToChuc.DanhMucXaPhuongName;
                      this.ThanhVienService.List.push(ListThanhVienSub[0]);
                    }
                  }
                }
                if (this.ThanhVienService.List.length > 0) {
                  this.ThanhVienService.IsShowLoading = true;
                  this.ThanhVienService.SaveListSyncAsync(this.ThanhVienService.List).subscribe(
                    res => {
                      this.ThanhVienService.List = (res as ThanhVien[]);
                      this.ThanhVienService.IsShowLoading = false;
                    },
                    err => {
                      this.ThanhVienService.IsShowLoading = false;
                    }
                  );
                }
              },
              err => {
                this.ThanhVienService.IsShowLoading = false;
              }
            );
          }
        },
        err => {
          this.ThanhVienService.IsShowLoading = false;
        }
      );
    }

  }
}