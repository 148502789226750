import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/TruyXuatNguonGoc/Download.service';

import { TemPhieuKhoiTao } from 'src/app/shared/TruyXuatNguonGoc/TemPhieuKhoiTao.model';
import { TemPhieuKhoiTaoService } from 'src/app/shared/TruyXuatNguonGoc/TemPhieuKhoiTao.service';
import { TemPhieuKhoiTaoChiTiet } from 'src/app/shared/TruyXuatNguonGoc/TemPhieuKhoiTaoChiTiet.model';
import { TemPhieuKhoiTaoChiTietService } from 'src/app/shared/TruyXuatNguonGoc/TemPhieuKhoiTaoChiTiet.service';

import { TruyXuatNguonGocToChuc } from 'src/app/shared/TruyXuatNguonGoc/ToChuc.model';
import { TruyXuatNguonGocToChucService } from 'src/app/shared/TruyXuatNguonGoc/ToChuc.service';

@Component({
  selector: 'app-truy-xuat-nguon-goc-tem-phieu-khoi-tao-detail001',
  templateUrl: './truy-xuat-nguon-goc-tem-phieu-khoi-tao-detail001.component.html',
  styleUrls: ['./truy-xuat-nguon-goc-tem-phieu-khoi-tao-detail001.component.css']
})
export class TruyXuatNguonGocTemPhieuKhoiTaoDetail001Component implements OnInit {

  @ViewChild('TemPhieuKhoiTaoChiTietSort') TemPhieuKhoiTaoChiTietSort: MatSort;
  @ViewChild('TemPhieuKhoiTaoChiTietPaginator') TemPhieuKhoiTaoChiTietPaginator: MatPaginator;


  URLExcel: string = environment.TruyXuatNguonGocAPIRootURL + "Download/TemPhieuKhoiTaoChiTiet.xlsx";

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<TruyXuatNguonGocTemPhieuKhoiTaoDetail001Component>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public TemPhieuKhoiTaoService: TemPhieuKhoiTaoService,
    public TemPhieuKhoiTaoChiTietService: TemPhieuKhoiTaoChiTietService,

    public TruyXuatNguonGocToChucService: TruyXuatNguonGocToChucService,

  ) { }

  ngOnInit(): void {
    this.TemPhieuKhoiTaoSearch();
  }
  DateGhiNhan(value) {
    this.TemPhieuKhoiTaoService.FormData.GhiNhan = new Date(value);
  }
  ChangeFileName(files: FileList) {
    if (files) {
      this.TemPhieuKhoiTaoService.FileToUpload = files;
    }
  }

  ToChucSearch() {
    this.TruyXuatNguonGocToChucService.ComponentGetByDanhMucUngDungIDAndActiveAndPageAndPageSizeToListAsync(this.TemPhieuKhoiTaoService);
    console.log(this.TruyXuatNguonGocToChucService.ListFilter);
  }
  ToChucFilter(searchString: string) {
    this.TruyXuatNguonGocToChucService.FilterDanhMucUngDungIDAndActive(searchString, this.TemPhieuKhoiTaoService);
  }

  TemPhieuKhoiTaoSearch() {
    this.TemPhieuKhoiTaoService.IsShowLoading = true;
    this.TemPhieuKhoiTaoService.BaseParameter.ToChucID = this.TemPhieuKhoiTaoService.FormData.ToChucID;
    this.TemPhieuKhoiTaoService.BaseParameter.Name = this.TemPhieuKhoiTaoService.FormData.ToChucName;
    this.TemPhieuKhoiTaoService.BaseParameter.Code = this.TemPhieuKhoiTaoService.FormData.Code;
    this.TemPhieuKhoiTaoService.BaseParameter.ID = this.TemPhieuKhoiTaoService.FormData.ID;
    this.TemPhieuKhoiTaoService.GetByIDAsync().subscribe(
      res => {
        this.TemPhieuKhoiTaoService.FormData = res as TemPhieuKhoiTao;
        if (this.TemPhieuKhoiTaoService.FormData.ID > 0) {

        }
        else {
          this.TemPhieuKhoiTaoService.FormData.ToChucID = this.TemPhieuKhoiTaoService.BaseParameter.ToChucID;
          this.TemPhieuKhoiTaoService.FormData.ToChucName = this.TemPhieuKhoiTaoService.BaseParameter.Name;          
          this.TemPhieuKhoiTaoService.FormData.SoLuong = 1;
          this.TemPhieuKhoiTaoService.FormData.Active = true;
        }
        this.TemPhieuKhoiTaoChiTietSearch();
        this.ToChucSearch();
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      },
      err => {
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      }
    );
  }
  TemPhieuKhoiTaoSave() {
    this.TemPhieuKhoiTaoService.IsShowLoading = true;
    this.TemPhieuKhoiTaoService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.TemPhieuKhoiTaoService.FormData = res as TemPhieuKhoiTao;
        this.TemPhieuKhoiTaoChiTietSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      }
    );
  }
  TemPhieuKhoiTaoChiTietSearch() {
    if (this.TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString.length > 0) {
      this.TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString = this.TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString.trim();
      if (this.TemPhieuKhoiTaoChiTietService.DataSource) {
        this.TemPhieuKhoiTaoChiTietService.DataSource.filter = this.TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString.toLowerCase();
      }
    }
    else {
      this.TemPhieuKhoiTaoService.IsShowLoading = true;
      this.TemPhieuKhoiTaoChiTietService.BaseParameter.ParentID = this.TemPhieuKhoiTaoService.FormData.ID;
      this.TemPhieuKhoiTaoChiTietService.GetByParentIDToListAsync().subscribe(
        res => {
          this.TemPhieuKhoiTaoChiTietService.List = (res as TemPhieuKhoiTaoChiTiet[]).sort((a, b) => (a.ID > b.ID ? 1 : -1));
          this.TemPhieuKhoiTaoChiTietService.DataSource = new MatTableDataSource(this.TemPhieuKhoiTaoChiTietService.List);
          this.TemPhieuKhoiTaoChiTietService.DataSource.sort = this.TemPhieuKhoiTaoChiTietSort;
          this.TemPhieuKhoiTaoChiTietService.DataSource.paginator = this.TemPhieuKhoiTaoChiTietPaginator;
          if (this.TemPhieuKhoiTaoChiTietService.List) {
            if (this.TemPhieuKhoiTaoChiTietService.List.length > 0) {
              this.TemPhieuKhoiTaoService.FormData.SoLuong = this.TemPhieuKhoiTaoChiTietService.List.length;
            }
          }
          this.TemPhieuKhoiTaoService.IsShowLoading = false;
        },
        err => {
          this.TemPhieuKhoiTaoService.IsShowLoading = false;
        }
      );
    }
  }
  TemPhieuKhoiTaoChiTietDelete(element: TemPhieuKhoiTaoChiTiet) {
    if (confirm(environment.DeleteConfirm)) {
      this.TemPhieuKhoiTaoService.IsShowLoading = true;
      this.TemPhieuKhoiTaoChiTietService.BaseParameter.ID = element.ID;
      this.TemPhieuKhoiTaoChiTietService.RemoveAsync().subscribe(
        res => {
          element = res as TemPhieuKhoiTaoChiTiet;
          this.TemPhieuKhoiTaoChiTietSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
          this.TemPhieuKhoiTaoService.IsShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
          this.TemPhieuKhoiTaoService.IsShowLoading = false;
        }
      );
    }
  }
  Close() {
    this.dialogRef.close();
  }
}