import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/TruyXuatNguonGoc/Download.service';

import { TemPhieuKhoiTao } from 'src/app/shared/TruyXuatNguonGoc/TemPhieuKhoiTao.model';
import { TemPhieuKhoiTaoService } from 'src/app/shared/TruyXuatNguonGoc/TemPhieuKhoiTao.service';

import { TruyXuatNguonGocToChuc } from 'src/app/shared/TruyXuatNguonGoc/ToChuc.model';
import { TruyXuatNguonGocToChucService } from 'src/app/shared/TruyXuatNguonGoc/ToChuc.service';

import { TruyXuatNguonGocDanhMucUngDung } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.model';
import { TruyXuatNguonGocDanhMucUngDungService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.service';

import { TruyXuatNguonGocTemPhieuKhoiTaoDetailComponent } from '../truy-xuat-nguon-goc-tem-phieu-khoi-tao-detail/truy-xuat-nguon-goc-tem-phieu-khoi-tao-detail.component';


import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ThanhVienDetailComponent } from '../thanh-vien-detail/thanh-vien-detail.component';

@Component({
  selector: 'app-truy-xuat-nguon-goc-tem-phieu-khoi-tao',
  templateUrl: './truy-xuat-nguon-goc-tem-phieu-khoi-tao.component.html',
  styleUrls: ['./truy-xuat-nguon-goc-tem-phieu-khoi-tao.component.css']
})
export class TruyXuatNguonGocTemPhieuKhoiTaoComponent implements OnInit {

  @ViewChild('TemPhieuKhoiTaoSort') TemPhieuKhoiTaoSort: MatSort;
  @ViewChild('TemPhieuKhoiTaoPaginator') TemPhieuKhoiTaoPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public TemPhieuKhoiTaoService: TemPhieuKhoiTaoService,
    public TruyXuatNguonGocToChucService: TruyXuatNguonGocToChucService,

    public TruyXuatNguonGocDanhMucUngDungService: TruyXuatNguonGocDanhMucUngDungService,

    public ThanhVienService: ThanhVienService,


  ) { }

  ngOnInit(): void {
    this.TruyXuatNguonGocDanhMucUngDungService.GetLogin();
    this.ToChucSearch();
  }

  ToChucSearch() {
    this.TruyXuatNguonGocToChucService.ComponentGetByDanhMucUngDungIDAndActiveAndPageAndPageSizeToListAsync(this.TemPhieuKhoiTaoService);
  }
  ToChucFilter(searchString: string) {
    this.TruyXuatNguonGocToChucService.FilterDanhMucUngDungIDAndActive(searchString, this.TemPhieuKhoiTaoService);
  }

  TemPhieuKhoiTaoSearch() {

    if (this.TemPhieuKhoiTaoService.BaseParameter.SearchString.length > 0) {
      this.TemPhieuKhoiTaoService.IsShowLoading = true;
      this.TemPhieuKhoiTaoService.BaseParameter.DanhMucUngDungID = Number(localStorage.getItem(environment.TruyXuatNguonGocDanhMucUngDungID));
      this.TemPhieuKhoiTaoService.GetBySearchString_DanhMucUngDungIDToListAsync().subscribe(
        res => {
          this.TemPhieuKhoiTaoService.List = (res as TemPhieuKhoiTao[]).sort((a, b) => (a.GhiNhan < b.GhiNhan ? 1 : -1));
          this.TemPhieuKhoiTaoService.DataSource = new MatTableDataSource(this.TemPhieuKhoiTaoService.List);
          this.TemPhieuKhoiTaoService.DataSource.sort = this.TemPhieuKhoiTaoSort;
          this.TemPhieuKhoiTaoService.DataSource.paginator = this.TemPhieuKhoiTaoPaginator;
          this.TemPhieuKhoiTaoService.IsShowLoading = false;
        },
        err => {
          this.TemPhieuKhoiTaoService.IsShowLoading = false;
        }
      );
    }
    else {
      this.TemPhieuKhoiTaoService.IsShowLoading = true;
      this.TemPhieuKhoiTaoService.GetByToChucIDToListAsync().subscribe(
        res => {
          this.TemPhieuKhoiTaoService.List = (res as TemPhieuKhoiTao[]).sort((a, b) => (a.GhiNhan < b.GhiNhan ? 1 : -1));
          this.TemPhieuKhoiTaoService.DataSource = new MatTableDataSource(this.TemPhieuKhoiTaoService.List);
          this.TemPhieuKhoiTaoService.DataSource.sort = this.TemPhieuKhoiTaoSort;
          this.TemPhieuKhoiTaoService.DataSource.paginator = this.TemPhieuKhoiTaoPaginator;
          this.TemPhieuKhoiTaoService.IsShowLoading = false;
        },
        err => {
          this.TemPhieuKhoiTaoService.IsShowLoading = false;
        }
      );
    }
  }
  TemPhieuKhoiTaoDelete(element: TemPhieuKhoiTao) {
    if (confirm(environment.DeleteConfirm)) {
      if (element.ToChucID > 0) {
        this.NotificationService.warn(environment.TruyXuatNguonGocToChucExists);
      }
      else {
        this.TemPhieuKhoiTaoService.IsShowLoading = true;
        this.TemPhieuKhoiTaoService.BaseParameter.ID = element.ID;
        this.TemPhieuKhoiTaoService.RemoveAsync().subscribe(
          res => {
            this.TemPhieuKhoiTaoSearch();
            this.NotificationService.warn(environment.DeleteSuccess);
            this.TemPhieuKhoiTaoService.IsShowLoading = false;
          },
          err => {
            this.NotificationService.warn(environment.DeleteNotSuccess);
            this.TemPhieuKhoiTaoService.IsShowLoading = false;
          }
        );
      }
    }
  }
  TemPhieuKhoiTaoAdd(ID: number) {
    this.TemPhieuKhoiTaoService.IsShowLoading = true;
    this.TemPhieuKhoiTaoService.BaseParameter.ID = ID;
    this.TemPhieuKhoiTaoService.GetByIDAsync().subscribe(
      res => {
        this.TemPhieuKhoiTaoService.FormData = res as TemPhieuKhoiTao;        
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(TruyXuatNguonGocTemPhieuKhoiTaoDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.TemPhieuKhoiTaoSearch();
        });
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      },
      err => {
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      }
    );
  }
  ToChucAdd(ID: number) {
    this.TemPhieuKhoiTaoService.IsShowLoading = true;
    this.TruyXuatNguonGocToChucService.BaseParameter.ID = ID;
    this.TruyXuatNguonGocToChucService.GetByIDAsync().subscribe(
      res => {
        this.TruyXuatNguonGocToChucService.FormData = res as TruyXuatNguonGocToChuc;
        this.ThanhVienService.BaseParameter.ID = this.TruyXuatNguonGocToChucService.FormData.IDToken;
        this.ThanhVienService.GetByIDAsync().subscribe(
          res => {
            this.ThanhVienService.FormData = res as ThanhVien;
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.width = environment.DialogConfigWidth;
            dialogConfig.data = { ID: ID };
            const dialog = this.dialog.open(ThanhVienDetailComponent, dialogConfig);
            dialog.afterClosed().subscribe(() => {
            });
          },
          err => {
          }
        );        
      },
      err => {        
      },
      ()=>{
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      },
    );
  }
}