import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';


import { DanhMucChungChi } from 'src/app/shared/DanhMucChungChi.model';
import { DanhMucChungChiService } from 'src/app/shared/DanhMucChungChi.service';

@Component({
  selector: 'app-danh-muc-chung-chi',
  templateUrl: './danh-muc-chung-chi.component.html',
  styleUrls: ['./danh-muc-chung-chi.component.css']
})
export class DanhMucChungChiComponent implements OnInit {

  @ViewChild('DanhMucChungChiSort') DanhMucChungChiSort: MatSort;
  @ViewChild('DanhMucChungChiPaginator') DanhMucChungChiPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucChungChiService: DanhMucChungChiService,
  ) { }

  ngOnInit(): void {    
  }

  DanhMucChungChiSearch() {
    this.DanhMucChungChiService.SearchAll(this.DanhMucChungChiSort, this.DanhMucChungChiPaginator);
  }
  DanhMucChungChiSave(element: DanhMucChungChi) {
    this.DanhMucChungChiService.FormData = element;
    this.NotificationService.warn(this.DanhMucChungChiService.ComponentSaveAll(this.DanhMucChungChiSort, this.DanhMucChungChiPaginator));
  }
  DanhMucChungChiDelete(element: DanhMucChungChi) {
    this.DanhMucChungChiService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucChungChiService.ComponentDeleteAll(this.DanhMucChungChiSort, this.DanhMucChungChiPaginator));
  }  
}