import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { TruyXuatNguonGocDanhMucUngDung } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.model';
import { TruyXuatNguonGocDanhMucUngDungService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.service';

import { DanhMucPhuongThucMuaBan } from 'src/app/shared/TruyXuatNguonGoc/DanhMucPhuongThucMuaBan.model';
import { DanhMucPhuongThucMuaBanService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucPhuongThucMuaBan.service';

@Component({
  selector: 'app-truy-xuat-nguon-goc-danh-muc-phuong-thuc-mua-ban',
  templateUrl: './truy-xuat-nguon-goc-danh-muc-phuong-thuc-mua-ban.component.html',
  styleUrls: ['./truy-xuat-nguon-goc-danh-muc-phuong-thuc-mua-ban.component.css']
})
export class TruyXuatNguonGocDanhMucPhuongThucMuaBanComponent implements OnInit {

  @ViewChild('DanhMucPhuongThucMuaBanSort') DanhMucPhuongThucMuaBanSort: MatSort;
  @ViewChild('DanhMucPhuongThucMuaBanPaginator') DanhMucPhuongThucMuaBanPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public TruyXuatNguonGocDanhMucUngDungService: TruyXuatNguonGocDanhMucUngDungService,

    public DanhMucPhuongThucMuaBanService: DanhMucPhuongThucMuaBanService,
    
  ) { }

  ngOnInit(): void {    
    this.TruyXuatNguonGocDanhMucUngDungService.GetLogin();
  }

  DanhMucPhuongThucMuaBanSearch() {
    this.DanhMucPhuongThucMuaBanService.SearchAll(this.DanhMucPhuongThucMuaBanSort, this.DanhMucPhuongThucMuaBanPaginator);
  }
  DanhMucPhuongThucMuaBanSave(element: DanhMucPhuongThucMuaBan) {
    this.DanhMucPhuongThucMuaBanService.FormData = element;
    this.NotificationService.warn(this.DanhMucPhuongThucMuaBanService.ComponentSaveAll(this.DanhMucPhuongThucMuaBanSort, this.DanhMucPhuongThucMuaBanPaginator));
  }
  DanhMucPhuongThucMuaBanDelete(element: DanhMucPhuongThucMuaBan) {
    this.DanhMucPhuongThucMuaBanService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucPhuongThucMuaBanService.ComponentDeleteAll(this.DanhMucPhuongThucMuaBanSort, this.DanhMucPhuongThucMuaBanPaginator));
  }  
}