import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoHangNhatKy } from './LoHangNhatKy.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class LoHangNhatKyService extends BaseService{

    DisplayColumns001: string[] = ['Name'];
        
    List: LoHangNhatKy[] | undefined;
    ListFilter: LoHangNhatKy[] | undefined;
    FormData!: LoHangNhatKy;

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "LoHangNhatKy";
    }
}

