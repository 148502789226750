import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DanhMucThanhVien } from 'src/app/shared/DanhMucThanhVien.model';
import { DanhMucThanhVienService } from 'src/app/shared/DanhMucThanhVien.service';

import { DanhMucChungChi } from 'src/app/shared/DanhMucChungChi.model';
import { DanhMucChungChiService } from 'src/app/shared/DanhMucChungChi.service';
import { DanhMucDanToc } from 'src/app/shared/DanhMucDanToc.model';
import { DanhMucDanTocService } from 'src/app/shared/DanhMucDanToc.service';
import { DanhMucNgonNgu } from 'src/app/shared/DanhMucNgonNgu.model';
import { DanhMucNgonNguService } from 'src/app/shared/DanhMucNgonNgu.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ThanhVienTapTinDinhKem } from 'src/app/shared/ThanhVienTapTinDinhKem.model';
import { ThanhVienTapTinDinhKemService } from 'src/app/shared/ThanhVienTapTinDinhKem.service';

import { TruyXuatNguonGocDanhMucUngDung } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.model';
import { TruyXuatNguonGocDanhMucUngDungService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.service';
import { DanhMucTinhThanh } from 'src/app/shared/TruyXuatNguonGoc/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/TruyXuatNguonGoc/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/TruyXuatNguonGoc/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucXaPhuong.service';

@Component({
  selector: 'app-thanh-vien-thong-tin',
  templateUrl: './thanh-vien-thong-tin.component.html',
  styleUrls: ['./thanh-vien-thong-tin.component.css']
})
export class ThanhVienThongTinComponent implements OnInit {

  @ViewChild('ThanhVienTapTinDinhKemSort') ThanhVienTapTinDinhKemSort: MatSort;
  @ViewChild('ThanhVienTapTinDinhKemPaginator') ThanhVienTapTinDinhKemPaginator: MatPaginator;

  MatKhauIsActive: boolean = true;

  constructor(
    public NotificationService: NotificationService,

    public DanhMucThanhVienService: DanhMucThanhVienService,
    public DanhMucChungChiService: DanhMucChungChiService,
    public DanhMucDanTocService: DanhMucDanTocService,
    public DanhMucNgonNguService: DanhMucNgonNguService,

    public ThanhVienService: ThanhVienService,
    public ThanhVienTapTinDinhKemService: ThanhVienTapTinDinhKemService,

    public TruyXuatNguonGocDanhMucUngDungService: TruyXuatNguonGocDanhMucUngDungService,
    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,
  ) { }

  ngOnInit(): void {
    this.TruyXuatNguonGocDanhMucUngDungService.GetLogin();
    this.GetByQueryString();
  }
  MatKhauChangeType() {
    this.MatKhauIsActive = !this.MatKhauIsActive;
  }
  DanhMucTinhThanhSearch() {
    if (this.DanhMucTinhThanhService.List.length == 0) {
      this.ThanhVienService.IsShowLoading = true;
      this.DanhMucTinhThanhService.BaseParameter.ParentID = this.ThanhVienService.FormDataLogin.DanhMucQuocGiaID;
      this.DanhMucTinhThanhService.GetByParentIDToListAsync().subscribe(
        res => {
          this.DanhMucTinhThanhService.List = (res as DanhMucTinhThanh[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
          this.DanhMucQuanHuyenSearch();
        },
        err => {
        },
        () => {
          this.ThanhVienService.IsShowLoading = false;
        }
      );
    }
  }
  DanhMucQuanHuyenSearch() {
    this.ThanhVienService.IsShowLoading = true;
    this.DanhMucQuanHuyenService.BaseParameter.ParentID = this.ThanhVienService.FormDataLogin.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.List = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        this.DanhMucXaPhuongSearch();
      },
      err => {
      },
      () => {
        this.ThanhVienService.IsShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongSearch() {
    this.ThanhVienService.IsShowLoading = true;
    this.DanhMucXaPhuongService.BaseParameter.ParentID = this.ThanhVienService.FormDataLogin.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.List = (res as DanhMucXaPhuong[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        let List = this.DanhMucXaPhuongService.List.filter((item: any) => item.Name == this.ThanhVienService.FormDataLogin.DanhMucXaPhuongName);
        if (List.length > 0) {
          this.ThanhVienService.FormDataLogin.DanhMucXaPhuongID = List[0].ID;
        }
      },
      err => {
      },
      () => {
        this.ThanhVienService.IsShowLoading = false;
      }
    );
  }
  DanhMucThanhVienSearch() {
    this.DanhMucThanhVienService.ComponentGetAllToListAsync(this.ThanhVienService);
  }
  DanhMucChungChiSearch() {
    this.DanhMucChungChiService.ComponentGetAllToListAsync(this.ThanhVienService);
  }
  DanhMucDanTocSearch() {
    this.DanhMucDanTocService.ComponentGetAllToListAsync(this.ThanhVienService);
  }
  DanhMucNgonNguSearch() {
    this.DanhMucNgonNguService.ComponentGetAllToListAsync(this.ThanhVienService);
  }
  DateCCCDNgayCap(value) {
    this.ThanhVienService.FormDataLogin.CCCDNgayCap = new Date(value);
  }
  DateNgaySinh(value) {
    this.ThanhVienService.FormDataLogin.NgaySinh = new Date(value);
  }
  DateAnToanVeSinhThucPhamNgayCap(value) {
    this.ThanhVienService.FormDataLogin.AnToanVeSinhThucPhamNgayCap = new Date(value);
  }
  DateNgayBatDau(value) {
    this.ThanhVienService.FormDataLogin.NgayBatDau = new Date(value);
  }
  DateVungTrongNgayCap(value) {
    this.ThanhVienService.FormDataLogin.VungTrongNgayCap = new Date(value);
  }
  DateVuonSamNgayCap(value) {
    this.ThanhVienService.FormDataLogin.VuonSamNgayCap = new Date(value);
  }
  DateChiDanDiaLyNgayCap(value) {
    this.ThanhVienService.FormDataLogin.VuonSamNgayCap = new Date(value);
  }
  GetByQueryString() {
    this.ThanhVienService.IsShowLoading = true;
    this.ThanhVienService.GetLogin();
    if (this.ThanhVienService.FormDataLogin) {
      if (this.ThanhVienService.FormDataLogin.ID > 0) {
        this.ThanhVienService.BaseParameter.ID = this.ThanhVienService.FormDataLogin.ID;
        this.ThanhVienService.GetByIDAsync().subscribe(res => {
          this.ThanhVienService.FormDataLogin = res as ThanhVien;
          this.ThanhVienService.IsShowLoading = false;
          this.DanhMucTinhThanhSearch();
          this.DanhMucThanhVienSearch();
          this.DanhMucChungChiSearch();
          this.DanhMucDanTocSearch();
          this.DanhMucNgonNguSearch();
        },
          err => {
            this.NotificationService.warn(environment.SaveNotSuccess);
          }
        );
      }
    }
  }
  ThanhVienSave() {
    this.ThanhVienService.IsShowLoading = true;
    this.ThanhVienService.FormData = this.ThanhVienService.FormDataLogin;
    this.ThanhVienService.SaveAsync().subscribe(
      res => {
        this.ThanhVienService.FormDataLogin = res as ThanhVien;
        this.NotificationService.warn(environment.SaveSuccess);
        this.ThanhVienService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.ThanhVienService.IsShowLoading = false;
      }
    );
  }

  ThanhVienTapTinDinhKemSearch() {
    this.ThanhVienTapTinDinhKemService.BaseParameter.ParentID = this.ThanhVienService.FormDataLogin.ID;
    this.ThanhVienTapTinDinhKemService.SearchByParentID(this.ThanhVienTapTinDinhKemSort, this.ThanhVienTapTinDinhKemPaginator);
  }
  ThanhVienTapTinDinhKemChangeFileName(files: FileList) {
    if (files) {
      this.ThanhVienTapTinDinhKemService.FileToUpload = files;
    }
  }
  ThanhVienTapTinDinhKemSave(element: ThanhVienTapTinDinhKem) {
    this.ThanhVienService.IsShowLoading = true;
    element.ParentID = this.ThanhVienService.FormDataLogin.ID;
    this.ThanhVienTapTinDinhKemService.FormData = element;
    this.ThanhVienTapTinDinhKemService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.ThanhVienTapTinDinhKemSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.ThanhVienService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.ThanhVienService.IsShowLoading = false;
      }
    );
  }
  ThanhVienTapTinDinhKemDelete(element: ThanhVienTapTinDinhKem) {
    this.ThanhVienTapTinDinhKemService.BaseParameter.ID = element.ID;
    this.ThanhVienTapTinDinhKemService.ComponentDeleteByParentID(this.ThanhVienTapTinDinhKemSort, this.ThanhVienTapTinDinhKemPaginator);
  }
}