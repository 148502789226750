import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseService } from './Base.service';
import { Report } from './Report.model';

@Injectable({
    providedIn: 'root'
})
export class ReportService extends BaseService{   
  
    List: Report[] | undefined;
    ListFilter: Report[] | undefined;
    FormData!: Report;
    
    
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "Report";
    }    

    Report0001Async() {        
        let url = this.APIURL + this.Controller + '/Report0001Async';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.BaseParameter));          
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }     
    Report0002ToListAsync() {        
        let url = this.APIURL + this.Controller + '/Report0002ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.BaseParameter));          
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }   
    Report0003ToListAsync() {        
        let url = this.APIURL + this.Controller + '/Report0003ToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(this.BaseParameter));          
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }   
}




