<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-ThanhVienVungTrongDetail" data-bs-toggle="tab"
                href="#ThanhVienVungTrongDetail" role="tab" aria-controls="ThanhVienVungTrongDetail"
                aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Vùng nguyên liệu | Tiểu vùng nguyên liệu
                    [{{ThanhVienVungTrongService.FormData.ID}}]</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="ThanhVienVungTrongVungNguyenLieu" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="ThanhVienVungTrongSave()"
                        class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="Active"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.Active" />
                        <label class="form-check-label" for="Active">Trụ sở</label>
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Danh mục
                            [{{ThanhVienVungTrongService.FormData.DanhMucVungTrongID}}]</label>
                        <select class="form-select" name="DanhMucVungTrongID"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.DanhMucVungTrongID">
                            <option *ngFor="let item of DanhMucVungTrongService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Vùng nguyên liệu (nếu có)
                            [{{ThanhVienVungTrongService.FormData.ThanhVienVungTrongID}}]</label>
                        <select class="form-select" name="ThanhVienVungTrongID"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.ThanhVienVungTrongID">
                            <option *ngFor="let item of ThanhVienVungTrongService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tỉnh thành
                            [{{ThanhVienVungTrongService.FormData.DanhMucTinhThanhID}}]</label>
                        <select class="form-select" name="DanhMucTinhThanhID"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.DanhMucTinhThanhID" (change)="DanhMucQuanHuyenSearch()">
                            <option *ngFor="let item of DanhMucTinhThanhService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Quận huyện
                            [{{ThanhVienVungTrongService.FormData.DanhMucQuanHuyenID}}]</label>
                        <select class="form-select" name="DanhMucQuanHuyenID"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.DanhMucQuanHuyenID" (change)="DanhMucXaPhuongSearch()">
                            <option *ngFor="let item of DanhMucQuanHuyenService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Xã phường
                            [{{ThanhVienVungTrongService.FormData.DanhMucXaPhuongID}}]</label>
                        <select class="form-select" name="DanhMucXaPhuongID"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.DanhMucXaPhuongID" (change)="DanhMucXaPhuongChange()">
                            <option *ngFor="let item of DanhMucXaPhuongService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Địa chỉ</label>
                        <input name="DiaChi" [(ngModel)]="ThanhVienVungTrongService.FormData.DiaChi" placeholder="Địa chỉ"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã số vườn sâm</label>
                        <input name="VuonSamMaSo" [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamMaSo"
                            placeholder="Mã số vườn sâm" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiêu đề</label>
                        <input name="Name" [(ngModel)]="ThanhVienVungTrongService.FormData.Name"
                            placeholder="Tiêu đề" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày cấp mã số vườn sâm</label>
                        <input [ngModel]="ThanhVienVungTrongService.FormData.VuonSamNgayCap | date:'yyyy-MM-dd'"
                            style="text-align: right;" (ngModelChange)="DateVuonSamNgayCap($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Cần tư vấn mã số vườn sâm</label>
                        <input name="VuonSamTuVan" [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamTuVan"
                            placeholder="Cần tư vấn mã số vườn sâm" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã số vùng trồng (PUC)</label>
                        <input name="VungTrongMaSo" [(ngModel)]="ThanhVienVungTrongService.FormData.VungTrongMaSo"
                            placeholder="Mã số vùng trồng (PUC)" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày cấp mã số vùng trồng (PUC)</label>
                        <input [ngModel]="ThanhVienVungTrongService.FormData.VungTrongNgayCap | date:'yyyy-MM-dd'"
                            style="text-align: right;" (ngModelChange)="DateVungTrongNgayCap($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Cần tư vấn mã số vùng trồng (PUC)</label>
                        <input name="VungTrongTuVan" [(ngModel)]="ThanhVienVungTrongService.FormData.VungTrongTuVan"
                            placeholder="Cần tư vấn mã số vùng trồng (PUC)" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tình trạng sở hữu</label>
                        <input name="VuonSamTinhTrangSoHuu"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamTinhTrangSoHuu"
                            placeholder="Tình trạng sở hữu" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mô hình canh tác sâm Ngọc Linh</label>
                        <input name="VuonSamMoHinhCanhTac"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamMoHinhCanhTac"
                            placeholder="Mô hình canh tác sâm Ngọc Linh" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tự sản xuất giống</label>
                        <input name="VuonSamGiongTuSanXuat"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamGiongTuSanXuat"
                            placeholder="Tự sản xuất giống" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mua cây giống từ nhà cung cấp khác</label>
                        <input name="VuonSamGiongNhaCungCap"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamGiongNhaCungCap"
                            placeholder="Mua cây giống từ nhà cung cấp khác" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tên nhà cung cấp giống</label>
                        <input name="VuonSamGiongNhaCungCapName"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamGiongNhaCungCapName"
                            placeholder="Tên nhà cung cấp giống" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Hiện tượng xấu thường gây hại tại vườn</label>
                        <input name="VuonSamGayHai" [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamGayHai"
                            placeholder="Hiện tượng xấu thường gây hại tại vườn" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tình trạng Internet tại vùng trồng</label>
                        <input name="VuonSamInternet" [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamInternet"
                            placeholder="Tình trạng Internet tại vùng trồng" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tình trạng sóng điện thoại, 3G tại vùng
                            trồng</label>
                        <input name="VuonSamSongDienThoai"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamSongDienThoai"
                            placeholder="Tình trạng sóng điện thoại, 3G tại vùng trồng" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tình trạng nguồn điện 220V</label>
                        <input name="VuonSamNguonDien220"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamNguonDien220"
                            placeholder="Tình trạng nguồn điện 220V" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Thiết bị đo nhiệt độ, độ ẩm thông minh phục vụ
                            nông nghiệp</label>
                        <input name="VuonSamThietBiThongMinh"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamThietBiThongMinh"
                            placeholder="Thiết bị đo nhiệt độ, độ ẩm thông minh phục vụ nông nghiệp" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Camara giám sát an ninh</label>
                        <input name="VuonSamCamera" [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamCamera"
                            placeholder="Camara giám sát an ninh" type="text" class="form-control">
                    </div>         
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Bổ sung phân chuồng</label>
                        <input name="VuonSamPhanChuongBoSung"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamPhanChuongBoSung"
                            placeholder="Bổ sung phân chuồng" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Loại phân chuồng (nếu có bổ sung)</label>
                        <input name="VuonSamPhanChuong"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamPhanChuong"
                            placeholder="Loại phân chuồng nếu có bổ sung" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số lượng/năm (kg,lit) (nếu có bổ sung)</label>
                        <input name="VuonSamPhanChuongSoLuong"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamPhanChuongSoLuong"
                            placeholder="Số lượng/năm (kg,lit) (nếu có bổ sung)" type="number"
                            style="text-align: right;" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Bổ sung phân vi sinh</label>
                        <input name="VuonSamPhanViSinhBoSung"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamPhanViSinhBoSung"
                            placeholder="Bổ sung phân vi sinh" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Loại phân vi sinh (nếu có bổ sung)</label>
                        <input name="VuonSamPhanViSinh"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamPhanViSinh"
                            placeholder="Loại phân vi sinh (nếu có bổ sung)" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số lượng/năm (kg,lit) (nếu có bổ sung)</label>
                        <input name="VuonSamPhanViSinhSoLuong"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamPhanViSinhSoLuong"
                            placeholder="Số lượng/năm (kg,lit) (nếu có bổ sung)" type="number"
                            style="text-align: right;" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Bổ sung phân vô cơ</label>
                        <input name="VuonSamPhanVoCoBoSung"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamPhanVoCoBoSung"
                            placeholder="Bổ sung phân vô cơ" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Loại phân vô cơ (nếu có bổ sung)</label>
                        <input name="VuonSamPhanVoCo" [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamPhanVoCo"
                            placeholder="Loại phân vô cơ (nếu có bổ sung)" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số lượng/năm (kg,lit) (nếu có bổ sung)</label>
                        <input name="VuonSamPhanVoCoSoLuong"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamPhanVoCoSoLuong"
                            placeholder="Số lượng/năm (kg,lit) (nếu có bổ sung)" type="number"
                            style="text-align: right;" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Bổ sung thuốc bảo vệ thực vật gốc vi sinh</label>
                        <input name="VuonSamThuocBaoVeThucVatViSinhBoSung"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamThuocBaoVeThucVatViSinhBoSung"
                            placeholder="Bổ sung thuốc bảo vệ thực vật gốc vi sinh" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Loại thuốc bảo vệ thực vật gốc vi sinh (nếu có bổ
                            sung)</label>
                        <input name="VuonSamThuocBaoVeThucVatViSinh"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamThuocBaoVeThucVatViSinh"
                            placeholder="Loại thuốc bảo vệ thực vật gốc vi sinh (nếu có bổ sung)" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số lượng/năm (kg,lit) (nếu có bổ sung)</label>
                        <input name="VuonSamThuocBaoVeThucVatViSinhSoLuong"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamThuocBaoVeThucVatViSinhSoLuong"
                            placeholder="Số lượng/năm (kg,lit) (nếu có bổ sung)" type="number"
                            style="text-align: right;" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Bổ sung thuốc bảo vệ (hoá học)</label>
                        <input name="VuonSamThuocBaoVeThucVatHoaHocBoSung"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamThuocBaoVeThucVatHoaHocBoSung"
                            placeholder="Bổ sung thuốc bảo vệ (hoá học)" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Loại thuốc bảo vệ (hoá học) (nếu có bổ
                            sung)</label>
                        <input name="VuonSamThuocBaoVeThucVatHoaHoc"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamThuocBaoVeThucVatHoaHoc"
                            placeholder="Loại thuốc bảo vệ (hoá học) (nếu có bổ sung)" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số lượng/năm (kg,lit) (nếu có bổ sung)</label>
                        <input name="VuonSamThuocBaoVeThucVatHoaHocSoLuong"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamThuocBaoVeThucVatHoaHocSoLuong"
                            placeholder="Số lượng/năm (kg,lit) (nếu có bổ sung)" type="number"
                            style="text-align: right;" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tổng số lượng cây giống 01 năm tuổi (đang
                            trồng)</label>
                        <input name="VuonSamCayGiongNamTuoi01"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamCayGiongNamTuoi01"
                            placeholder="Tổng số lượng cây giống 01 năm tuổi (đang trồng)" type="number"
                            style="text-align: right;" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tổng số lượng cây sâm từ 02 đến 04 năm tuổi (đang
                            trồng)</label>
                        <input name="VuonSamCayGiongNamTuoi02"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamCayGiongNamTuoi02"
                            placeholder="Tổng số lượng cây sâm từ 02 đến 04 năm tuổi (đang trồng)" type="number"
                            style="text-align: right;" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tổng số lượng cây sâm từ 05 đến 08 năm tuổi (đang
                            trồng)</label>
                        <input name="VuonSamCayGiongNamTuoi05"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamCayGiongNamTuoi05"
                            placeholder="Tổng số lượng cây sâm từ 05 đến 08 năm tuổi (đang trồng)" type="number"
                            style="text-align: right;" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tổng số lượng cây sâm từ 09 đến 10 năm tuổi (đang
                            trồng)</label>
                        <input name="VuonSamCayGiongNamTuoi09"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamCayGiongNamTuoi09"
                            placeholder="Tổng số lượng cây sâm từ 09 đến 10 năm tuổi (đang trồng)" type="number"
                            style="text-align: right;" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tổng số lượng cây sâm trên 10 năm tuổi (đang
                            trồng)</label>
                        <input name="VuonSamCayGiongNamTuoi10"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamCayGiongNamTuoi10"
                            placeholder="Tổng số lượng cây sâm trên 10 năm tuổi (đang trồng)" type="number"
                            style="text-align: right;" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số lượng sâm củ dự kiến thu hoạch trong
                            năm</label>
                        <input name="VuonSamSoLuongThuHoachDuKien"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamSoLuongThuHoachDuKien"
                            placeholder="Số lượng sâm củ Ngọc linh dự kiến thu hoạch từ nay đến cuối năm (31/12)"
                            type="number" style="text-align: right;" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Thiết bị cảm biến an ninh</label>
                        <input name="VuonSamThietBiAnNinh"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamThietBiAnNinh"
                            placeholder="Thiết bị cảm biến an ninh" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tưới tự động</label>
                        <input name="VuonSamTuoiTuDong"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamTuoiTuDong"
                            placeholder="Tưới tự động" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Chỉ dẫn địa lý (Mã số hoặc Giấy chứng
                            nhận)</label>
                        <input name="ChiDanDiaLyMaSo" [(ngModel)]="ThanhVienVungTrongService.FormData.ChiDanDiaLyMaSo"
                            placeholder="Chỉ dẫn địa lý (Mã số hoặc Giấy chứng nhận)" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tư vấn thêm về Chỉ dẫn địa lý</label>
                        <input name="ChiDanDiaLyTuVan" [(ngModel)]="ThanhVienVungTrongService.FormData.ChiDanDiaLyTuVan"
                            placeholder="Tư vấn thêm về Chỉ dẫn địa lý" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày cấp Giấy chứng nhận Chỉ dẫn địa lý</label>
                        <input [ngModel]="ThanhVienVungTrongService.FormData.ChiDanDiaLyNgayCap | date:'yyyy-MM-dd'"
                            style="text-align: right;" (ngModelChange)="DateChiDanDiaLyNgayCap($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Diện tích sở hữu (m2)</label>
                        <input name="DienTich" [(ngModel)]="ThanhVienVungTrongService.FormData.DienTich"
                            placeholder="Diện tích sở hữu" type="number" class="form-control"
                            style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Diện tích thổ cư (m2)</label>
                        <input name="DienTichThoCu" [(ngModel)]="ThanhVienVungTrongService.FormData.DienTichThoCu"
                            placeholder="Diện tích thổ cư" type="number" class="form-control"
                            style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Diện tích trồng trọt (m2)</label>
                        <input name="DienTichTrongTrot"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.DienTichTrongTrot"
                            placeholder="Diện tích trồng trọt" type="number" class="form-control"
                            style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Diện tích vườn sâm đang khai thác (m2)</label>
                        <input name="VuonSamDienTich" [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamDienTich"
                            placeholder="Diện tích vườn sâm đang khai thác (m2)" type="number" class="form-control"
                            style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số lượng vườn sâm đang khai thác (tiểu vùng nguyên
                            liệu)</label>
                        <input name="VuonSamSoLuong" [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamSoLuong"
                            placeholder="Số lượng vườn sâm đang khai thác (tiểu vùng nguyên liệu)" type="number"
                            class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Diện tích khu vực trồng sâm Ngọc Linh (m2)</label>
                        <input name="VuonSamDienTichTrong"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamDienTichTrong"
                            placeholder="Diện tích khu vực trồng sâm Ngọc Linh (m2)" type="number" class="form-control"
                            style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tỷ lệ độ che của tán rừng tại khu vực trồng
                            (%)</label>
                        <input name="VuonSamTyLeDoCheTanRung"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamTyLeDoCheTanRung"
                            placeholder="Tỷ lệ độ che của tán rừng tại khu vực trồng (%)" type="number"
                            class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Diện tích khu vực ươm giống sâm Ngọc Linh
                            (m2)</label>
                        <input name="VuonSamUomGiongDienTich"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamUomGiongDienTich"
                            placeholder="Diện tích khu vực ươm giống sâm Ngọc Linh (m2)" type="number"
                            class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tỷ lệ độ che của tán rừng tại khu vực ươm
                            (%)</label>
                        <input name="VuonSamUomGiongTyLeDoCheTanRung"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamUomGiongTyLeDoCheTanRung"
                            placeholder="Tỷ lệ độ che của tán rừng tại khu vực ươm (%)" type="number"
                            class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Diện tích đất dốc (m2)</label>
                        <input name="VuonSamDatDocDienTich"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamDatDocDienTich"
                            placeholder="Diện tích đất dốc (m2)" type="number" class="form-control"
                            style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tỷ lệ độ che của tán rừng tại khu đất dốc
                            (%)</label>
                        <input name="VuonSamDatDocTyLeDoCheTanRung"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamDatDocTyLeDoCheTanRung"
                            placeholder="Tỷ lệ độ che của tán rừng tại khu đất dốc (%)" type="number"
                            class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Diện tích đất bằng (m2)</label>
                        <input name="VuonSamDatBangDienTich"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamDatBangDienTich"
                            placeholder="Diện tích đất bằng (m2)" type="number" class="form-control"
                            style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tỷ lệ độ che của tán rừng tại khu đất bằng
                            (%)</label>
                        <input name="VuonSamDatBangTyLeDoCheTanRung"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamDatBangTyLeDoCheTanRung"
                            placeholder="Tỷ lệ độ che của tán rừng tại khu đất bằng (%)" type="number"
                            class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Diện tích nhà bạt khu trồng (m2)</label>
                        <input name="VuonSamDienTichNhaBat"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamDienTichNhaBat"
                            placeholder="Diện tích nhà bạt khu trồng (m2)" type="number" class="form-control"
                            style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Diện tích nhà bạt khu ươm (m2)</label>
                        <input name="VuonSamUomGiongDienTichNhaBat"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamUomGiongDienTichNhaBat"
                            placeholder="Diện tích nhà bạt khu ươm (m2)" type="number" class="form-control"
                            style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Diện tích trồng sâm trên băng trồng (m2)</label>
                        <input name="VuonSamBangTrongDienTich"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamBangTrongDienTich"
                            placeholder="Diện tích trồng sâm trên băng trồng (m2)" type="number" class="form-control"
                            style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Diện tích ươm giống sâm trên dàn (m2)</label>
                        <input name="VuonSamUomGiongDienTichDan"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamUomGiongDienTichDan"
                            placeholder="Diện tích ươm giống sâm trên dàn (m2)" type="number" class="form-control"
                            style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Thông tin ghi chú khác</label>
                        <input name="VuonSamGhiChu" [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamGhiChu"
                            placeholder="Thông tin ghi chú khác" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Áp dụng quy trình</label>
                        <input name="VuonSamQuyTrinhTuVan"
                            [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamQuyTrinhTuVan"
                            placeholder="Áp dụng quy trình" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Loại quy trình áp dụng (nếu có áp dụng)</label>
                        <input name="VuonSamQuyTrinh" [(ngModel)]="ThanhVienVungTrongService.FormData.VuonSamQuyTrinh"
                            placeholder="Loại quy trình áp dụng (nếu có áp dụng)" type="text" class="form-control">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ThanhVienVungTrongService.IsShowLoading"></app-loading>