import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-truy-xuat-nguon-goc-kho-tem',
  templateUrl: './truy-xuat-nguon-goc-kho-tem.component.html',
  styleUrls: ['./truy-xuat-nguon-goc-kho-tem.component.css']
})
export class TruyXuatNguonGocKhoTemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
