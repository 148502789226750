<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThanhVien" data-bs-toggle="tab" href="#ThanhVien"
                                    role="tab" aria-controls="ThanhVien" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Thông tin thành viên [{{ThanhVienService.FormDataLogin.ID}}]</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThanhVien" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Lưu thay đổi" (click)="ThanhVienSave()"
                                            class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu thay đổi</a>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="Active" disabled
                                                [(ngModel)]="ThanhVienService.FormDataLogin.Active" />
                                            <label class="form-check-label" for="Active">Kích hoạt</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12 text-center">
                                        <h3><b>Nông hộ</b></h3>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tài khoản [{{ThanhVienService.FormDataLogin.ID}}]</label>
                                            <input name="TaiKhoan" [(ngModel)]="ThanhVienService.FormDataLogin.TaiKhoan"
                                                [disabled]="ThanhVienService.FormDataLogin && ThanhVienService.FormDataLogin.ID>0"
                                                placeholder="Tài khoản" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mật khẩu</label>
                                            <div class="input-group">
                                                <input name="MatKhau" [(ngModel)]="ThanhVienService.FormData.MatKhau" placeholder="Mật khẩu"
                                                    type="password" class="form-control" [type]="MatKhauIsActive ? 'password' : 'text'">
                                                <button class="btn btn-outline-secondary" type="button" (click)="MatKhauChangeType()">
                                                    <i class="bi bi-eye"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ngày tham gia</label>
                                            <input
                                                [ngModel]="ThanhVienService.FormDataLogin.NgayBatDau | date:'yyyy-MM-dd'"
                                                style="text-align: right;" (ngModelChange)="DateNgayBatDau($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                autocomplete="off" disabled>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Cán bộ duyệt hồ sơ</label>
                                            <input name="ThanhVienPheDuyetName"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.ThanhVienPheDuyetName"
                                                placeholder="Cán bộ duyệt hồ sơ" type="text" class="form-control"
                                                disabled>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mã phiếu</label>
                                            <input name="Code" [(ngModel)]="ThanhVienService.FormDataLogin.Code"
                                                placeholder="Mã phiếu" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Họ tên</label>
                                            <input name="Name" [(ngModel)]="ThanhVienService.FormDataLogin.Name"
                                                placeholder="Họ tên" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ngày sinh</label>
                                            <input
                                                [ngModel]="ThanhVienService.FormDataLogin.NgaySinh | date:'yyyy-MM-dd'"
                                                style="text-align: right;" (ngModelChange)="DateNgaySinh($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                autocomplete="off">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Giới tính</label>
                                            <input name="GioiTinh" [(ngModel)]="ThanhVienService.FormDataLogin.GioiTinh"
                                                placeholder="Giới tính" type="text" class="form-control">
                                        </div>              
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ghi chú</label>
                                            <input name="Note" [(ngModel)]="ThanhVienService.FormDataLogin.Note"
                                                placeholder="Ghi chú" type="text" class="form-control">
                                        </div>                              
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">CCCD</label>
                                            <input name="CCCD" [(ngModel)]="ThanhVienService.FormDataLogin.CCCD"
                                                placeholder="CCCD" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Nơi cấp</label>
                                            <input name="CCCDNoiCap"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.CCCDNoiCap"
                                                placeholder="Nơi cấp" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ngày cấp</label>
                                            <input
                                                [ngModel]="ThanhVienService.FormDataLogin.CCCDNgayCap | date:'yyyy-MM-dd'"
                                                style="text-align: right;" (ngModelChange)="DateCCCDNgayCap($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                autocomplete="off">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Trình độ văn hóa
                                                [{{ThanhVienService.FormDataLogin.DanhMucChungChiID}}]</label>
                                            <select class="form-select" name="DanhMucChungChiID"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.DanhMucChungChiID">
                                                <option *ngFor="let item of DanhMucChungChiService.List;"
                                                    [value]="item.ID">
                                                    {{item.Name}} [{{item.ID}}]</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Dân tộc
                                                [{{ThanhVienService.FormDataLogin.DanhMucDanTocID}}]</label>
                                            <select class="form-select" name="DanhMucDanTocID"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.DanhMucDanTocID">
                                                <option *ngFor="let item of DanhMucDanTocService.List;"
                                                    [value]="item.ID">
                                                    {{item.Name}} [{{item.ID}}]</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tiếng dân tộc
                                                [{{ThanhVienService.FormDataLogin.DanhMucNgonNguID}}]</label>
                                            <select class="form-select" name="DanhMucNgonNguID"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.DanhMucNgonNguID">
                                                <option *ngFor="let item of DanhMucNgonNguService.List;"
                                                    [value]="item.ID">
                                                    {{item.Name}} [{{item.ID}}]</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ngôn ngữ chính
                                                [{{ThanhVienService.FormDataLogin.DanhMucNgonNguChinhID}}]</label>
                                            <select class="form-select" name="DanhMucNgonNguChinhID"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.DanhMucNgonNguChinhID">
                                                <option *ngFor="let item of DanhMucNgonNguService.List;"
                                                    [value]="item.ID">
                                                    {{item.Name}} [{{item.ID}}]</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ngôn ngữ phụ
                                                [{{ThanhVienService.FormDataLogin.DanhMucNgonNguPhuID}}]</label>
                                            <select class="form-select" name="DanhMucNgonNguPhuID"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.DanhMucNgonNguPhuID">
                                                <option *ngFor="let item of DanhMucNgonNguService.List;"
                                                    [value]="item.ID">
                                                    {{item.Name}} [{{item.ID}}]</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Email</label>
                                            <input name="Email" [(ngModel)]="ThanhVienService.FormDataLogin.Email"
                                                placeholder="Email" type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tỉnh thành
                                                [{{ThanhVienService.FormDataLogin.DanhMucTinhThanhID}}]</label>
                                            <select class="form-select" name="DanhMucTinhThanhID"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.DanhMucTinhThanhID">
                                                <option *ngFor="let item of DanhMucTinhThanhService.List;"
                                                    [value]="item.ID">
                                                    {{item.Name}} [{{item.ID}}]</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Quận huyện
                                                [{{ThanhVienService.FormDataLogin.DanhMucQuanHuyenID}}]</label>
                                            <select class="form-select" name="DanhMucQuanHuyenID"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.DanhMucQuanHuyenID">
                                                <option *ngFor="let item of DanhMucQuanHuyenService.List;"
                                                    [value]="item.ID">
                                                    {{item.Name}} [{{item.ID}}]</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Xã phường
                                                [{{ThanhVienService.FormDataLogin.DanhMucXaPhuongID}}]</label>
                                            <select class="form-select" name="DanhMucXaPhuongID"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.DanhMucXaPhuongID">
                                                <option *ngFor="let item of DanhMucXaPhuongService.List;"
                                                    [value]="item.ID">
                                                    {{item.Name}} [{{item.ID}}]</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Địa chỉ</label>
                                            <input name="DiaChi" [(ngModel)]="ThanhVienService.FormDataLogin.DiaChi"
                                                placeholder="Địa chỉ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Điện thoại</label>
                                            <input name="DienThoai"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.DienThoai"
                                                placeholder="Điện thoại" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Zalo</label>
                                            <input name="Zalo" [(ngModel)]="ThanhVienService.FormDataLogin.Zalo"
                                                placeholder="Zalo" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Facebook</label>
                                            <input name="Facebook" [(ngModel)]="ThanhVienService.FormDataLogin.Facebook"
                                                placeholder="Facebook" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Youtube</label>
                                            <input name="Youtube" [(ngModel)]="ThanhVienService.FormDataLogin.Youtube"
                                                placeholder="Youtube" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tiktok</label>
                                            <input name="Tiktok" [(ngModel)]="ThanhVienService.FormDataLogin.Tiktok"
                                                placeholder="Tiktok" type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>                                
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12 text-center">
                                        <h3><b>Kinh doanh</b></h3>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Giấy chứng nhận An toàn vệ sinh thực phẩm
                                                (ATVSTP)</label>
                                            <input name="AnToanVeSinhThucPhamGiayChungNhan"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.AnToanVeSinhThucPhamGiayChungNhan"
                                                placeholder="Giấy chứng nhận An toàn vệ sinh thực phẩm (ATVSTP)"
                                                type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tư vấn thêm về Giấy chứng nhận ATVSTP</label>
                                            <input name="AnToanVeSinhThucPhamTuVan"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.AnToanVeSinhThucPhamTuVan"
                                                placeholder="Tư vấn thêm về Giấy chứng nhận ATVSTP" type="text"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ngày cấp Giấy chứng nhận ATVSTP</label>
                                            <input
                                                [ngModel]="ThanhVienService.FormDataLogin.AnToanVeSinhThucPhamNgayCap | date:'yyyy-MM-dd'"
                                                style="text-align: right;"
                                                (ngModelChange)="DateAnToanVeSinhThucPhamNgayCap($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                autocomplete="off">
                                        </div>                                        
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Sơ chế, chế biến</label>
                                            <input name="CheBien" [(ngModel)]="ThanhVienService.FormDataLogin.CheBien"
                                                placeholder="Sơ chế, chế biến" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Đóng gói</label>
                                            <input name="DongGoi" [(ngModel)]="ThanhVienService.FormDataLogin.DongGoi"
                                                placeholder="Đóng gói" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bảo quản</label>
                                            <input name="BaoQuan" [(ngModel)]="ThanhVienService.FormDataLogin.BaoQuan"
                                                placeholder="Bảo quản" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Vệ sinh, làm sạch</label>
                                            <input name="VeSinh" [(ngModel)]="ThanhVienService.FormDataLogin.VeSinh"
                                                placeholder="Vệ sinh, làm sạch" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bao bì chuyên dụng có thương hiệu riêng</label>
                                            <input name="BaoBi" [(ngModel)]="ThanhVienService.FormDataLogin.BaoBi"
                                                placeholder="Bao bì chuyên dụng có thương hiệu riêng" type="text"
                                                class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tên doanh nghiệp / HTX / liên minh HTX</label>
                                            <input name="ToChucLienKet"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.ToChucLienKet"
                                                placeholder="Tên doanh nghiệp / HTX / liên minh HTX" type="text"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mã số thuế</label>
                                            <input name="MaSoThue" [(ngModel)]="ThanhVienService.FormDataLogin.MaSoThue"
                                                placeholder="Mã số thuế" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mô hình kinh doanh sâm Ngọc Linh</label>
                                            <input name="KinhDoanhMoHinh"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.KinhDoanhMoHinh"
                                                placeholder="Mô hình kinh doanh sâm Ngọc Linh" type="text"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Hình thức kinh doanh</label>
                                            <input name="KinhDoanhHinhThuc"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.KinhDoanhHinhThuc"
                                                placeholder="Hình thức kinh doanh" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Loại hình hoạt động kinh doanh</label>
                                            <input name="KinhDoanhLoaiHinh"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.KinhDoanhLoaiHinh"
                                                placeholder="Loại hình hoạt động kinh doanh" type="text"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Điện thoại bán hàng</label>
                                            <input name="KinhDoanhDienThoai"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.KinhDoanhDienThoai"
                                                placeholder="Điện thoại bán hàng" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Khu vực bàn hàng</label>
                                            <input name="KinhDoanhKhuVuc"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.KinhDoanhKhuVuc"
                                                placeholder="Khu vực bàn hàng" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tên thương hiệu riêng</label>
                                            <input name="KinhDoanhThuongHieu"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.KinhDoanhThuongHieu"
                                                placeholder="Tên thương hiệu riêng" type="text" class="form-control">
                                        </div>                             
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Lao động nam đang hoạt động trồng sâm</label>
                                            <input name="LaoDongNam"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.LaoDongNam"
                                                placeholder="Lao động nam đang hoạt động trồng sâm" type="number"
                                                class="form-control" style="text-align: right;">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Lao động nữ đang hoạt động trồng sâm</label>
                                            <input name="LaoDongNu"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.LaoDongNu"
                                                placeholder="Lao động nữ đang hoạt động trồng sâm" type="number"
                                                class="form-control" style="text-align: right;">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Lao động được đào tạo và hướng dẫn kiến thức trồng
                                                sâm</label>
                                            <input name="LaoDongThamGiaDaoTaoHuongDan"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.LaoDongThamGiaDaoTaoHuongDan"
                                                placeholder="Lao động Đã tham gia đào tạo và hướng dẫn kiến thức trồng sâm"
                                                type="number" class="form-control" style="text-align: right;">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Sản phẩm hình thành sau sơ chế, chế biến</label>
                                            <input name="SanPham" [(ngModel)]="ThanhVienService.FormDataLogin.SanPham"
                                                placeholder="Sản phẩm hình thành sau sơ chế, chế biến" type="text"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Độ tuổi sâm thường thu hoạch</label>
                                            <input name="SanPhamDoTuoiThuHoach"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.SanPhamDoTuoiThuHoach"
                                                placeholder="Độ tuổi sâm thường thu hoạch" type="text"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Số năm kinh nghiệm hoạt động trồng sâm Ngọc
                                                Linh</label>
                                            <input name="SanPhamSoNamKinhNghiem"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.SanPhamSoNamKinhNghiem"
                                                placeholder="Số năm kinh nghiệm hoạt động trồng sâm Ngọc Linh"
                                                type="number" class="form-control" style="text-align: right;">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Sản lượng kinh doanh trung bình/năm (kg)</label>
                                            <input name="KinhDoanhSanLuong"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.KinhDoanhSanLuong"
                                                placeholder="Sản lượng kinh doanh trung bình/năm (kg)" type="number"
                                                class="form-control" style="text-align: right;">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Số năm kinh nghiệm kinh doanh sâm Ngọc
                                                Linh</label>
                                            <input name="KinhDoanhSoNamKinhNghiem"
                                                [(ngModel)]="ThanhVienService.FormDataLogin.KinhDoanhSoNamKinhNghiem"
                                                placeholder="Số năm kinh nghiệm kinh doanh sâm Ngọc Linh" type="number"
                                                class="form-control" style="text-align: right;">
                                        </div>                                        
                                    </div>
                                </div>        
                                <hr/>                                                                        
                                <div class="row gx-4">
                                    <div class="col-lg-11 col-sm-12 col-12">
                                        <input [(ngModel)]="ThanhVienTapTinDinhKemService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ThanhVienTapTinDinhKemSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-6 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="ThanhVienTapTinDinhKemSearch()"><i class="bi bi-search"></i></a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-primary">
                                    <div class="card-header">
                                        <h5 class="card-title text-white bg-primary">Tập tin đính kèm
                                            <span
                                                *ngIf="ThanhVienTapTinDinhKemService.List">({{ThanhVienTapTinDinhKemService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body bg-primary">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ThanhVienTapTinDinhKemSort="matSort"
                                                    [dataSource]="ThanhVienTapTinDinhKemService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="ThanhVienTapTinDinhKemPaginator">
                                                                {{ThanhVienTapTinDinhKemPaginator.pageSize *
                                                                ThanhVienTapTinDinhKemPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Tiêu đề" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name" />
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="FileName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải lên
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input type="file" style="width: 200px;"
                                                                (change)="ThanhVienTapTinDinhKemChangeFileName($event.target.files)">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải về
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a *ngIf="element.ID > 0 && element.FileName"
                                                                class="link-primary" target="_blank"
                                                                href="{{element.FileName}}"><b>Tải về</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ThanhVienTapTinDinhKemSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ThanhVienTapTinDinhKemDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienTapTinDinhKemService.DisplayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienTapTinDinhKemService.DisplayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ThanhVienTapTinDinhKemPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>

                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ThanhVienTapTinDinhKemSort="matSort"
                                                    [dataSource]="ThanhVienTapTinDinhKemService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tiêu đề</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Tiêu đề" name="Name{{element.ID}}"
                                                                        [(ngModel)]="element.Name">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tải lên</label>
                                                                    <input type="file" style="width: 200px;"
                                                                        (change)="ThanhVienTapTinDinhKemChangeFileName($event.target.files)">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a *ngIf="element.ID > 0 && element.FileName"
                                                                        class="link-primary" target="_blank"
                                                                        href="{{element.FileName}}"><b>Tải về</b></a>
                                                                </div>
                                                            </div>
                                                            <div class="row gx-4">
                                                                <div class="col-lg-6 col-sm-6 col-6">
                                                                    <a class="btn btn-info" style="width: 100%;"
                                                                        (click)="ThanhVienTapTinDinhKemSave(element)"><i
                                                                            class="bi bi-sd-card"></i>
                                                                    </a>
                                                                </div>
                                                                <div class="col-lg-6 col-sm-6 col-6">
                                                                    <a class="btn btn-danger" style="width: 100%;"
                                                                        (click)="ThanhVienTapTinDinhKemDelete(element)"
                                                                        *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                                    </a>
                                                                </div>
                                                                <br />
                                                                <br />
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienTapTinDinhKemService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienTapTinDinhKemService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ThanhVienTapTinDinhKemPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ThanhVienService.IsShowLoading"></app-loading>