<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThanhVienLichSuTruyCap" data-bs-toggle="tab"
                                    href="#ThanhVienLichSuTruyCap" role="tab" aria-controls="ThanhVienLichSuTruyCap"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Lịch sử truy cập</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThanhVienLichSuTruyCap" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Bắt đầu</label>
                                        <input
                                            [ngModel]="ThanhVienLichSuTruyCapService.BaseParameter.BatDau | date:'yyyy-MM-dd'"
                                            style="text-align: right;" (ngModelChange)="DateBatDau($event)"
                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                            autocomplete="off">
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Kết thúc</label>
                                        <input
                                            [ngModel]="ThanhVienLichSuTruyCapService.BaseParameter.KetThuc | date:'yyyy-MM-dd'"
                                            style="text-align: right;" (ngModelChange)="DateKetThuc($event)"
                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                            autocomplete="off">
                                    </div>
                                    <div class="col-lg-5 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input [(ngModel)]="ThanhVienLichSuTruyCapService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ThanhVienLichSuTruyCapSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="ThanhVienLichSuTruyCapSearch()"><i class="bi bi-search"></i></a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span
                                                *ngIf="ThanhVienLichSuTruyCapService.List">({{ThanhVienLichSuTruyCapService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ThanhVienLichSuTruyCapSort="matSort"
                                                    [dataSource]="ThanhVienLichSuTruyCapService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="ThanhVienLichSuTruyCapPaginator">
                                                                {{ThanhVienLichSuTruyCapPaginator.pageSize *
                                                                ThanhVienLichSuTruyCapPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayGhiNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Ghi nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: right;">
                                                            {{element.NgayGhiNhan |
                                                            date:'dd/MM/yyyy HH:mm:ss'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            {{element.Name}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tài khoản
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            {{element.Code}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="URL">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            URL
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            {{element.URL}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IPAddress">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            IP Address
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            {{element.IPAddress}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuocGiaName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Quốc gia
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            {{element.DanhMucQuocGiaName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucTinhThanhName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tỉnh thành
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            {{element.DanhMucTinhThanhName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuanHuyenName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Quận huyện
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            {{element.DanhMucQuanHuyenName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KinhDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Kinh độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            {{element.KinhDo}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ViDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Vĩ độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            {{element.ViDo}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TypeName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Thiết bị
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            {{element.TypeName}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienLichSuTruyCapService.DisplayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienLichSuTruyCapService.DisplayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ThanhVienLichSuTruyCapPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ThanhVienLichSuTruyCapSort="matSort"
                                                    [dataSource]="ThanhVienLichSuTruyCapService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">

                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienLichSuTruyCapService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienLichSuTruyCapService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ThanhVienLichSuTruyCapPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ThanhVienLichSuTruyCapService.IsShowLoading"></app-loading>