import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BlockchainDanhMucUngDung } from './DanhMucUngDung.model';
import { BaseService } from './Base.service';

@Injectable({
    providedIn: 'root'
})
export class BlockchainDanhMucUngDungService extends BaseService {

    DisplayColumns001: string[] = ['STT', 'ID', 'Code', 'Name', 'Note', 'TypeName', 'Display', 'FileName', 'SortOrder', 'Active', 'Save'];

    constructor(

        public httpClient: HttpClient,

    ) {
        super(httpClient);
        this.Controller = "DanhMucUngDung";
    }
    GetLogin() {
        this.InitializationHeaders();
        this.BaseParameter.ID = Number(localStorage.getItem(environment.BlockchainDanhMucUngDungID));        
        if (this.BaseParameter.ID > 0) {

        }
        else {
            this.BaseParameter.TypeName = environment.BlockchainDanhMucUngDungToKen;
            this.GetByTypeNameAsync().subscribe(
                res => {
                    this.FormData = (res as BlockchainDanhMucUngDung);
                    localStorage.setItem(environment.BlockchainDanhMucUngDungID, this.FormData.ID.toString());
                    localStorage.setItem(environment.BlockchainToKen, this.FormData.Description);
                    this.InitializationHeaders();
                },
                err => {
                },
                () => {                    
                }
            );
        }        
    }    
    GetByTypeNameAsync() {
        let url = this.APIURL + this.Controller + '/GetByTypeNameAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
}

