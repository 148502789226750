import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BlockchainThanhVien } from './ThanhVien.model';
import { BaseService } from './Base.service';

@Injectable({
    providedIn: 'root'
})
export class BlockchainThanhVienService extends BaseService {

    DisplayColumns001: string[] = ['STT', 'ID', 'Code', 'TaiKhoan', 'DanhMucUngDungName', 'ParentName', 'Active'];

    List: BlockchainThanhVien[] | undefined;
    ListFilter: BlockchainThanhVien[] | undefined;
    FormData!: BlockchainThanhVien;
    FormDataLogin!: BlockchainThanhVien;

    constructor(

        public httpClient: HttpClient,

    ) {
        super(httpClient);
        this.Controller = "ThanhVien";
        this.FormDataLogin = {
            Name: environment.InitializationString,
        };
    }
    SaveSyncAsync() {
        let url = this.APIURL + this.Controller + '/SaveSyncAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.FormData));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    SaveListSyncAsync(list: BlockchainThanhVien[]) {
        let url = this.APIURL + this.Controller + '/SaveListSyncAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(list));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    GetLogin() {
        this.FormDataLogin = {
        }
        this.FormDataLogin.Name = localStorage.getItem(environment.ThanhVienHoTen);
        var LastUpdatedMembershipID = localStorage.getItem(environment.ThanhVienID);
        if (LastUpdatedMembershipID) {
            this.FormDataLogin.ID = Number(LastUpdatedMembershipID);
        }
    }
    AuthenticationAsync() {
        let url = this.APIURL + this.Controller + '/AuthenticationAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.FormData));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    ChangePasswordAsync() {
        let url = this.APIURL + this.Controller + '/ChangePasswordAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.FormData));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
}

