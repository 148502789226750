import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TruyXuatNguonGocToChuc } from './ToChuc.model';
import { BaseService } from './Base.service';



@Injectable({
    providedIn: 'root'
})
export class TruyXuatNguonGocToChucService extends BaseService {

    DisplayColumns001: string[] = ['STT', 'ID', 'Code', 'Name', 'DienThoai', 'DanhMucUngDungName', 'DanhMucQuocGiaName', 'DanhMucTinhThanhName', 'Active'];
    DisplayColumns002: string[] = ['STT', 'ID', 'Name', 'TemPhieuSoLuong', 'TemPhieuSuDung', 'TemPhieuConLai'];

    List: TruyXuatNguonGocToChuc[] | undefined;
    ListFilter: TruyXuatNguonGocToChuc[] | undefined;
    FormData!: TruyXuatNguonGocToChuc;

    List001: TruyXuatNguonGocToChuc[] | undefined;
    ListFilter001: TruyXuatNguonGocToChuc[] | undefined;

    constructor(

        public httpClient: HttpClient,        

    ) {
        super(httpClient);
        this.Controller = "ToChuc";
    }
    
    GetByDanhMucUngDungIDAndIDTokenAsync() {
        let url = this.APIURL + this.Controller + '/GetByDanhMucUngDungIDAndIDTokenAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
}

