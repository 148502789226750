<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-ThanhVienDetail" data-bs-toggle="tab" href="#ThanhVienDetail" role="tab"
                aria-controls="ThanhVienDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Thành viên
                    [{{ThanhVienService.FormData.ID}}]</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-ThanhVienChucNang" data-bs-toggle="tab" href="#ThanhVienChucNang" role="tab"
                aria-controls="ThanhVienChucNang" aria-selected="true">
                <span class="badge bg-warning" style="font-size: 16px;">Chức năng</span>
            </a>
        </li>
        <!-- <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-ThanhVienKho" data-bs-toggle="tab" href="#ThanhVienKho" role="tab"
                aria-controls="ThanhVienKho" aria-selected="true">
                <span class="badge bg-success" style="font-size: 16px;">Kho sâm</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-ThanhVienVungTrong" data-bs-toggle="tab" href="#ThanhVienVungTrong" role="tab"
                aria-controls="ThanhVienVungTrong" aria-selected="true">
                <span class="badge bg-danger" style="font-size: 16px;">Vùng nguyên liệu</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-ThanhVienMap" data-bs-toggle="tab" href="#ThanhVienMap" role="tab"
                aria-controls="ThanhVienMap" aria-selected="true">
                <span class="badge bg-success" style="font-size: 16px;">Bản đồ</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-ThanhVienTapTinDinhKem" data-bs-toggle="tab" href="#ThanhVienTapTinDinhKem"
                role="tab" aria-controls="ThanhVienTapTinDinhKem" aria-selected="true">
                <span class="badge bg-primary" style="font-size: 16px;">Tập tin</span>
            </a>
        </li> -->
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="ThanhVienDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="ThanhVienSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="Active"
                            [(ngModel)]="ThanhVienService.FormData.Active" />
                        <label class="form-check-label" for="Active">Kích hoạt</label>
                    </div>
                </div>
            </div>           
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tài khoản [{{ThanhVienService.FormData.ID}}]</label>
                        <input name="TaiKhoan" [(ngModel)]="ThanhVienService.FormData.TaiKhoan"
                            [disabled]="ThanhVienService.FormDataLogin && ThanhVienService.FormData.ID>0"
                            placeholder="Tài khoản" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mật khẩu</label>
                        <div class="input-group">
                            <input name="MatKhau" [(ngModel)]="ThanhVienService.FormData.MatKhau" placeholder="Mật khẩu"
                                type="password" class="form-control" [type]="MatKhauIsActive ? 'password' : 'text'">
                            <button class="btn btn-outline-secondary" type="button" (click)="MatKhauChangeType()">
                                <i class="bi bi-eye"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày tham gia</label>
                        <input [ngModel]="ThanhVienService.FormData.NgayBatDau | date:'yyyy-MM-dd'"
                            style="text-align: right;" (ngModelChange)="DateNgayBatDau($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Cán bộ duyệt hồ sơ
                            [{{ThanhVienService.FormData.ThanhVienPheDuyetID}}]</label>
                        <select class="form-select" name="ThanhVienPheDuyetID"
                            [(ngModel)]="ThanhVienService.FormData.ThanhVienPheDuyetID">
                            <option *ngFor="let item of ThanhVienService.ListFilter;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã phiếu</label>
                        <input name="Code" [(ngModel)]="ThanhVienService.FormData.Code" placeholder="Mã phiếu"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Họ tên</label>
                        <input name="Name" [(ngModel)]="ThanhVienService.FormData.Name" placeholder="Họ tên" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày sinh</label>
                        <input [ngModel]="ThanhVienService.FormData.NgaySinh | date:'yyyy-MM-dd'"
                            style="text-align: right;" (ngModelChange)="DateNgaySinh($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Truy xuất nguồn gốc - Thành viên</label>
                        <input name="TruyXuatNguonGocID" [(ngModel)]="ThanhVienService.FormData.TruyXuatNguonGocID"
                            placeholder="Truy xuất nguồn gốc - Thành viên" type="number" class="form-control"
                            style="text-align: right;" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Truy xuất nguồn gốc - Tổ chức</label>
                        <input name="TruyXuatNguonGocToChucID"
                            [(ngModel)]="ThanhVienService.FormData.TruyXuatNguonGocToChucID"
                            placeholder="Truy xuất nguồn gốc - Tổ chức" type="number" class="form-control"
                            style="text-align: right;" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Blockchain - Thành viên</label>
                        <input name="BlockchainID" [(ngModel)]="ThanhVienService.FormData.BlockchainID"
                            placeholder="Blockchain - Thành viên" type="number" class="form-control"
                            style="text-align: right;" disabled>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">CCCD</label>
                        <input name="CCCD" [(ngModel)]="ThanhVienService.FormData.CCCD" placeholder="CCCD" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Nơi cấp</label>
                        <input name="CCCDNoiCap" [(ngModel)]="ThanhVienService.FormData.CCCDNoiCap"
                            placeholder="Nơi cấp" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày cấp</label>
                        <input [ngModel]="ThanhVienService.FormData.CCCDNgayCap | date:'yyyy-MM-dd'"
                            style="text-align: right;" (ngModelChange)="DateCCCDNgayCap($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Trình độ văn hóa
                            [{{ThanhVienService.FormData.DanhMucChungChiID}}]</label>
                        <select class="form-select" name="DanhMucChungChiID"
                            [(ngModel)]="ThanhVienService.FormData.DanhMucChungChiID">
                            <option *ngFor="let item of DanhMucChungChiService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Dân tộc
                            [{{ThanhVienService.FormData.DanhMucDanTocID}}]</label>
                        <select class="form-select" name="DanhMucDanTocID"
                            [(ngModel)]="ThanhVienService.FormData.DanhMucDanTocID">
                            <option *ngFor="let item of DanhMucDanTocService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiếng dân tộc
                            [{{ThanhVienService.FormData.DanhMucNgonNguID}}]</label>
                        <select class="form-select" name="DanhMucNgonNguID"
                            [(ngModel)]="ThanhVienService.FormData.DanhMucNgonNguID">
                            <option *ngFor="let item of DanhMucNgonNguService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngôn ngữ chính
                            [{{ThanhVienService.FormData.DanhMucNgonNguChinhID}}]</label>
                        <select class="form-select" name="DanhMucNgonNguChinhID"
                            [(ngModel)]="ThanhVienService.FormData.DanhMucNgonNguChinhID">
                            <option *ngFor="let item of DanhMucNgonNguService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngôn ngữ phụ
                            [{{ThanhVienService.FormData.DanhMucNgonNguPhuID}}]</label>
                        <select class="form-select" name="DanhMucNgonNguPhuID"
                            [(ngModel)]="ThanhVienService.FormData.DanhMucNgonNguPhuID">
                            <option *ngFor="let item of DanhMucNgonNguService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Email</label>
                        <input name="Email" [(ngModel)]="ThanhVienService.FormData.Email" placeholder="Email"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Giới tính</label>
                        <input name="GioiTinh" [(ngModel)]="ThanhVienService.FormData.GioiTinh" placeholder="Giới tính"
                            type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Phân quyền
                            [{{ThanhVienService.FormData.ParentID}}]</label>
                        <select class="form-select" name="ParentID" [(ngModel)]="ThanhVienService.FormData.ParentID">
                            <option *ngFor="let item of DanhMucThanhVienService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tỉnh thành
                            [{{ThanhVienService.FormData.DanhMucTinhThanhID}}]</label>
                        <select class="form-select" name="DanhMucTinhThanhID"
                            [(ngModel)]="ThanhVienService.FormData.DanhMucTinhThanhID" (change)="DanhMucQuanHuyenSearch()">
                            <option *ngFor="let item of DanhMucTinhThanhService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Quận huyện
                            [{{ThanhVienService.FormData.DanhMucQuanHuyenID}}]</label>
                        <select class="form-select" name="DanhMucQuanHuyenID"
                            [(ngModel)]="ThanhVienService.FormData.DanhMucQuanHuyenID" (change)="DanhMucXaPhuongSearch()">
                            <option *ngFor="let item of DanhMucQuanHuyenService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Xã phường
                            [{{ThanhVienService.FormData.DanhMucXaPhuongID}}]</label>
                        <select class="form-select" name="DanhMucXaPhuongID"
                            [(ngModel)]="ThanhVienService.FormData.DanhMucXaPhuongID" (change)="DanhMucXaPhuongChange()">
                            <option *ngFor="let item of DanhMucXaPhuongService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Địa chỉ</label>
                        <input name="DiaChi" [(ngModel)]="ThanhVienService.FormData.DiaChi" placeholder="Địa chỉ"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Điện thoại</label>
                        <input name="DienThoai" [(ngModel)]="ThanhVienService.FormData.DienThoai"
                            placeholder="Điện thoại" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Zalo</label>
                        <input name="Zalo" [(ngModel)]="ThanhVienService.FormData.Zalo" placeholder="Zalo" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Facebook</label>
                        <input name="Facebook" [(ngModel)]="ThanhVienService.FormData.Facebook" placeholder="Facebook"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Youtube</label>
                        <input name="Youtube" [(ngModel)]="ThanhVienService.FormData.Youtube" placeholder="Youtube"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiktok</label>
                        <input name="Tiktok" [(ngModel)]="ThanhVienService.FormData.Tiktok" placeholder="Tiktok"
                            type="text" class="form-control">
                    </div>
                </div>
            </div>          
        </div>
        <div *ngIf="ThanhVienService.FormData.ID>0" class="tab-pane fade" id="ThanhVienChucNang" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-9 col-sm-12 col-12">
                    <input [(ngModel)]="ThanhVienChucNangService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="ThanhVienChucNangSearch()">
                </div>
                <div class="col-lg-1 col-sm-6 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="ThanhVienChucNangSearch()"><i
                            class="bi bi-search"></i></a>
                </div>
                <div class="col-lg-2 col-sm-12 col-12">
                    <div class="form-check form-check-inline">
                        <input type="checkbox" class="form-check-input" name="ActiveAll"
                            (change)="ThanhVienChucNangActiveAllChange()" [(ngModel)]="ActiveAllThanhVienChucNang" />
                        <label class="form-check-label" for="ActiveAll">Tất cả</label>
                    </div>
                </div>
            </div>
            <div class="card mb-4 card-warning">
                <div class="card-header">
                    <h5 class="card-title text-white">Chức năng
                        <span *ngIf="ThanhVienChucNangService.List">({{ThanhVienChucNangService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table mat-table matSort #ThanhVienChucNangSort="matSort"
                                [dataSource]="ThanhVienChucNangService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                        {{ThanhVienChucNangPaginator.pageSize *
                                        ThanhVienChucNangPaginator.pageIndex + i + 1}}
                                        [{{element.ID}}]
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Chức năng
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.Name}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Active">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox"
                                                (change)="ThanhVienChucNangActiveChange(element)"
                                                name="Active{{element.ID}}" [(ngModel)]="element.Active">
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="ThanhVienChucNangService.DisplayColumns001">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: ThanhVienChucNangService.DisplayColumns001;">
                                </tr>
                            </table>
                            <mat-paginator #ThanhVienChucNangPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="ThanhVienService.FormData.ID>0" class="tab-pane fade" id="ThanhVienTapTinDinhKem" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-11 col-sm-12 col-12">
                    <input [(ngModel)]="ThanhVienTapTinDinhKemService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="ThanhVienTapTinDinhKemSearch()">
                </div>
                <div class="col-lg-1 col-sm-6 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm"
                        (click)="ThanhVienTapTinDinhKemSearch()"><i class="bi bi-search"></i></a>
                </div>
            </div>
            <div class="card mb-4 card-primary">
                <div class="card-header">
                    <h5 class="card-title text-white bg-primary">Tập tin đính kèm
                        <span *ngIf="ThanhVienTapTinDinhKemService.List">({{ThanhVienTapTinDinhKemService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body bg-primary">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                #ThanhVienTapTinDinhKemSort="matSort"
                                [dataSource]="ThanhVienTapTinDinhKemService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                        <div *ngIf="ThanhVienTapTinDinhKemPaginator">
                                            {{ThanhVienTapTinDinhKemPaginator.pageSize *
                                            ThanhVienTapTinDinhKemPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ID}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="text" placeholder="Tiêu đề"
                                            name="Name{{element.ID}}" [(ngModel)]="element.Name" />
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="FileName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải lên
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input type="file" style="width: 200px;"
                                            (change)="ThanhVienTapTinDinhKemChangeFileName($event.target.files)">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Note">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải về
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a *ngIf="element.ID > 0 && element.FileName" class="link-primary"
                                            target="_blank" href="{{element.FileName}}"><b>Tải về</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="btn btn-info" (click)="ThanhVienTapTinDinhKemSave(element)"><i
                                                class="bi bi-sd-card"></i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-danger" (click)="ThanhVienTapTinDinhKemDelete(element)"
                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="ThanhVienTapTinDinhKemService.DisplayColumns001">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: ThanhVienTapTinDinhKemService.DisplayColumns001;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile"
                                #ThanhVienTapTinDinhKemPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="6" [showFirstLastButtons]></mat-paginator>

                            <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                #ThanhVienTapTinDinhKemSort="matSort"
                                [dataSource]="ThanhVienTapTinDinhKemService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Tiêu đề</label>
                                                <input class="form-control" type="text" placeholder="Tiêu đề"
                                                    name="Name{{element.ID}}" [(ngModel)]="element.Name">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Tải lên</label>
                                                <input type="file" style="width: 200px;"
                                                    (change)="ThanhVienTapTinDinhKemChangeFileName($event.target.files)">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <a *ngIf="element.ID > 0 && element.FileName" class="link-primary"
                                                    target="_blank" href="{{element.FileName}}"><b>Tải về</b></a>
                                            </div>
                                        </div>
                                        <div class="row gx-4">
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a class="btn btn-info" style="width: 100%;"
                                                    (click)="ThanhVienTapTinDinhKemSave(element)"><i
                                                        class="bi bi-sd-card"></i>
                                                </a>
                                            </div>
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a class="btn btn-danger" style="width: 100%;"
                                                    (click)="ThanhVienTapTinDinhKemDelete(element)"
                                                    *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                </a>
                                            </div>
                                            <br />
                                            <br />
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row
                                    *matHeaderRowDef="ThanhVienTapTinDinhKemService.DisplayColumnsMobile">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: ThanhVienTapTinDinhKemService.DisplayColumnsMobile;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile"
                                #ThanhVienTapTinDinhKemPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>       
        <div *ngIf="ThanhVienService.FormData.ID>0" class="tab-pane fade" id="ThanhVienVungTrong" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-10 col-sm-12 col-12">
                    <input [(ngModel)]="ThanhVienVungTrongService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="ThanhVienVungTrongSearch()">
                </div>
                <div class="col-lg-1 col-sm-6 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="ThanhVienVungTrongSearch()"><i
                            class="bi bi-search"></i></a>
                </div>
                <div class="col-lg-1 col-sm-12 col-12">                    
                    <a style="width: 100%;" class="btn btn-success" title="Thêm mới"
                        (click)="ThanhVienVungTrongAdd(0)"><i class="bi bi-plus"></i></a>
                </div>
            </div>
            <div class="card mb-4 card-danger">
                <div class="card-header">
                    <h5 class="card-title text-white">Vùng nguyên liệu
                        <span *ngIf="ThanhVienVungTrongService.List">({{ThanhVienVungTrongService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                #ThanhVienVungTrongSort="matSort" [dataSource]="ThanhVienVungTrongService.DataSource"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                        <div *ngIf="ThanhVienVungTrongToaDoPaginator">
                                            {{ThanhVienVungTrongPaginator.pageSize *
                                            ThanhVienVungTrongPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ID}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DanhMucVungTrongID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Danh mục
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <select class="form-select" name="element.DanhMucVungTrongID"
                                            [(ngModel)]="element.DanhMucVungTrongID">
                                            <option *ngFor="let item of DanhMucVungTrongService.List;"
                                                [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="VuonSamMaSo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã số vườn sâm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer;" title="{{element.VuonSamMaSo}}" class="link-primary"
                                            (click)="ThanhVienVungTrongAdd(element.ID)"><b>{{element.VuonSamMaSo}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer;" title="{{element.Name}}" class="link-primary"
                                            (click)="ThanhVienVungTrongAdd(element.ID)"><b>{{element.Name}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="SortOrder">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.SortOrder}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Active">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Trụ sở
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" name="Active{{element.ID}}"
                                                [(ngModel)]="element.Active">
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="TypeName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Tọa độ
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="btn btn-success" *ngIf="element.ID>0"
                                            (click)="ThanhVienVungTrongToaDoAdd(element.ID)"><i
                                                class="bi bi-arrows-fullscreen"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="btn btn-danger" (click)="ThanhVienVungTrongDelete(element)"
                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="ThanhVienVungTrongService.DisplayColumns002">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: ThanhVienVungTrongService.DisplayColumns002;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile"
                                #ThanhVienVungTrongPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="6" [showFirstLastButtons]></mat-paginator>

                            <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                #ThanhVienVungTrongSort="matSort" [dataSource]="ThanhVienVungTrongService.DataSource"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Danh mục</label>
                                                <select class="form-select" name="element.DanhMucVungTrongID"
                                                    [(ngModel)]="element.DanhMucVungTrongID">
                                                    <option *ngFor="let item of DanhMucVungTrongService.List;"
                                                        [value]="item.ID">
                                                        {{item.Name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Mã số</label>
                                                {{element.VuonSamMaSo}}
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Tiêu đề</label>
                                                {{element.Name}}
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox"
                                                        name="Active{{element.ID}}" [(ngModel)]="element.Active">
                                                    <label class="form-label">Trụ sở</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <a class="btn btn-success" *ngIf="element.ID>0"
                                                    (click)="ThanhVienVungTrongToaDoAdd(element.ID)"><i
                                                        class="bi bi-arrows-fullscreen"></i> Tọa độ
                                                </a>
                                            </div>
                                        </div>
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-6 col-6">
                                                <a class="btn btn-danger" style="width: 100%;"
                                                    (click)="ThanhVienVungTrongDelete(element)" *ngIf="element.ID>0"><i
                                                        class="bi bi-trash"></i>
                                                </a>
                                            </div>
                                            <br />
                                            <br />
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="ThanhVienVungTrongService.DisplayColumnsMobile">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: ThanhVienVungTrongService.DisplayColumnsMobile;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile"
                                #ThanhVienVungTrongPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="ThanhVienService.FormData.ID>0" class="tab-pane fade" id="ThanhVienKho" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-11 col-sm-12 col-12">
                    <input [(ngModel)]="ThanhVienKhoService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="ThanhVienKhoSearch()">
                </div>
                <div class="col-lg-1 col-sm-6 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="ThanhVienKhoSearch()"><i
                            class="bi bi-search"></i></a>
                </div>
            </div>
            <div class="card mb-4 card-success">
                <div class="card-header">
                    <h5 class="card-title text-white">Kho sâm
                        <span *ngIf="ThanhVienKhoService.List">({{ThanhVienKhoService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort #ThanhVienKhoSort="matSort"
                                [dataSource]="ThanhVienKhoService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                        <div *ngIf="ThanhVienKhoPaginator">
                                            {{ThanhVienKhoPaginator.pageSize *
                                            ThanhVienKhoPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ID}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="NamGhiNhan">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Năm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="Năm"
                                            style="text-align: right;" name="NamGhiNhan{{element.ID}}"
                                            [(ngModel)]="element.NamGhiNhan">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="VuonSamCayGiongHat">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Hạt
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="Hạt"
                                            style="text-align: right;" name="VuonSamCayGiongHat{{element.ID}}"
                                            [(ngModel)]="element.VuonSamCayGiongHat">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="VuonSamCayGiongNamTuoi01">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Giống 01 năm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="Cây giống 01 năm tuổi"
                                            style="text-align: right;" name="VuonSamCayGiongNamTuoi01{{element.ID}}"
                                            [(ngModel)]="element.VuonSamCayGiongNamTuoi01">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="VuonSamCayGiongNamTuoi02">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>02-04 năm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number"
                                            placeholder="Cây sâm từ 02 đến 04 năm tuổi" style="text-align: right;"
                                            name="VuonSamCayGiongNamTuoi02{{element.ID}}"
                                            [(ngModel)]="element.VuonSamCayGiongNamTuoi02">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="VuonSamCayGiongNamTuoi05">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>05-08 năm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number"
                                            placeholder="Cây sâm từ 05 đến 08 năm tuổi" style="text-align: right;"
                                            name="VuonSamCayGiongNamTuoi05{{element.ID}}"
                                            [(ngModel)]="element.VuonSamCayGiongNamTuoi05">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="VuonSamCayGiongNamTuoi09">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>09-10 năm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number"
                                            placeholder="Cây sâm từ 09 đến 10 năm tuổi" style="text-align: right;"
                                            name="VuonSamCayGiongNamTuoi09{{element.ID}}"
                                            [(ngModel)]="element.VuonSamCayGiongNamTuoi09">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="VuonSamCayGiongNamTuoi10">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Trên 10 năm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="Cây sâm trên 10 năm tuổi"
                                            style="text-align: right;" name="VuonSamCayGiongNamTuoi10{{element.ID}}"
                                            [(ngModel)]="element.VuonSamCayGiongNamTuoi10">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="btn btn-info" (click)="ThanhVienKhoSave(element)"><i
                                                class="bi bi-sd-card"></i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-info" (click)="ThanhVienKhoCopy(element)"
                                            *ngIf="element.ID>0"><i class="bi bi-subtract"></i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-danger" (click)="ThanhVienKhoDelete(element)"
                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="ThanhVienKhoService.DisplayColumns001">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: ThanhVienKhoService.DisplayColumns001;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile" #ThanhVienKhoPaginator="matPaginator"
                                [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                [showFirstLastButtons]></mat-paginator>

                            <table *ngIf="NotificationService.IsMobile" mat-table matSort #ThanhVienKhoSort="matSort"
                                [dataSource]="ThanhVienKhoService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Năm</label>
                                                <input class="form-control" type="number" placeholder="Năm"
                                                    style="text-align: right;" name="NamGhiNhan{{element.ID}}"
                                                    [(ngModel)]="element.NamGhiNhan">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Hạt</label>
                                                <input class="form-control" type="number" placeholder="Hạt"
                                                    style="text-align: right;" name="VuonSamCayGiongHat{{element.ID}}"
                                                    [(ngModel)]="element.VuonSamCayGiongHat">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Giống 01 năm</label>
                                                <input class="form-control" type="number"
                                                    placeholder="Cây giống 01 năm tuổi" style="text-align: right;"
                                                    name="VuonSamCayGiongNamTuoi01{{element.ID}}"
                                                    [(ngModel)]="element.VuonSamCayGiongNamTuoi01">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Cây sâm từ 02 đến 04 năm</label>
                                                <input class="form-control" type="number"
                                                    placeholder="Cây sâm từ 02 đến 04 năm tuổi"
                                                    style="text-align: right;"
                                                    name="VuonSamCayGiongNamTuoi02{{element.ID}}"
                                                    [(ngModel)]="element.VuonSamCayGiongNamTuoi02">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Cây sâm từ 05 đến 08 năm</label>
                                                <input class="form-control" type="number"
                                                    placeholder="Cây sâm từ 05 đến 08 năm tuổi"
                                                    style="text-align: right;"
                                                    name="VuonSamCayGiongNamTuoi05{{element.ID}}"
                                                    [(ngModel)]="element.VuonSamCayGiongNamTuoi05">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Cây sâm từ 09 đến 10 năm</label>
                                                <input class="form-control" type="number"
                                                    placeholder="Cây sâm từ 09 đến 10 năm tuổi"
                                                    style="text-align: right;"
                                                    name="VuonSamCayGiongNamTuoi09{{element.ID}}"
                                                    [(ngModel)]="element.VuonSamCayGiongNamTuoi09">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Cây sâm trên 10 năm</label>
                                                <input class="form-control" type="number"
                                                    placeholder="Cây sâm trên 10 năm tuổi" style="text-align: right;"
                                                    name="VuonSamCayGiongNamTuoi10{{element.ID}}"
                                                    [(ngModel)]="element.VuonSamCayGiongNamTuoi10">
                                            </div>
                                        </div>
                                        <div class="row gx-4">
                                            <div class="col-lg-4 col-sm-6 col-6">
                                                <a class="btn btn-info" style="width: 100%;"
                                                    (click)="ThanhVienKhoSave(element)"><i class="bi bi-sd-card"></i>
                                                </a>
                                            </div>
                                            <div class="col-lg-4 col-sm-6 col-6">
                                                <a class="btn btn-info" style="width: 100%;"
                                                    (click)="ThanhVienKhoCopy(element)" *ngIf="element.ID>0"><i
                                                        class="bi bi-subtract"></i>
                                                </a>
                                            </div>
                                            <div class="col-lg-4 col-sm-6 col-6">
                                                <a class="btn btn-danger" style="width: 100%;"
                                                    (click)="ThanhVienKhoDelete(element)" *ngIf="element.ID>0"><i
                                                        class="bi bi-trash"></i>
                                                </a>
                                            </div>
                                            <br />
                                            <br />
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="ThanhVienKhoService.DisplayColumnsMobile">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: ThanhVienKhoService.DisplayColumnsMobile;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile" #ThanhVienKhoPaginator="matPaginator"
                                [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="ThanhVienService.FormData.ID>0" class="tab-pane fade" id="ThanhVienMap" role="tabpanel">
            <div class="row">
                <div class="col-lg-3 col-sm-12 col-12">
                    <a style="width: 100%;" title="Tải lại bản đồ" (click)="MapLoad(0)" class="btn btn-success"><i
                            class="bi bi-arrow-repeat"></i> Tải lại bản
                        đồ</a>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <a style="width: 100%;" title="Vẽ vùng trồng" (click)="OpenWindowVeVungTrong()"
                        class="btn btn-success"><i class="bi bi-brush"></i> Vẽ vùng trồng</a>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="map" #map style="height: 600px; width: 100%;">
                        <div style="z-index: 1000; position: absolute; top: 0;">
                            <img src="{{domainURL}}assets/image/vungtrong.png" />
                        </div>
                        <div
                            style="z-index: 1000; position: absolute; bottom: 0; width: 100%; height: 20px; background-color: #ffffff;">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ThanhVienService.IsShowLoading"></app-loading>