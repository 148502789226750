<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-ThanhVienDetail" data-bs-toggle="tab" href="#ThanhVienDetail" role="tab"
                aria-controls="ThanhVienDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">1. Thông tin</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-ThanhVienVungTrong" data-bs-toggle="tab" href="#ThanhVienVungTrong" role="tab"
                aria-controls="ThanhVienVungTrong" aria-selected="true">
                <span class="badge bg-danger" style="font-size: 16px;">2. Vùng nguyên liệu</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-TruyXuatNguonGocTemPhieuKhoiTao" data-bs-toggle="tab"
                href="#TruyXuatNguonGocTemPhieuKhoiTao" role="tab" aria-controls="LoHang" aria-selected="true">
                <span class="badge bg-warning" style="font-size: 16px;">3. Kho tem</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-ThanhVienKho" data-bs-toggle="tab" href="#ThanhVienKho" role="tab"
                aria-controls="ThanhVienKho" aria-selected="true">
                <span class="badge bg-success" style="font-size: 16px;">4. Kho sâm</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-LoHang" data-bs-toggle="tab" href="#LoHang" role="tab" aria-controls="LoHang"
                aria-selected="true">
                <span class="badge bg-warning" style="font-size: 16px;">5. Truy xuất nguồn gốc</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-ThanhVienMap" data-bs-toggle="tab" href="#ThanhVienMap" role="tab"
                aria-controls="ThanhVienMap" aria-selected="true">
                <span class="badge bg-success" style="font-size: 16px;">6. Bản đồ</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-ThanhVienTapTinDinhKem" data-bs-toggle="tab" href="#ThanhVienTapTinDinhKem"
                role="tab" aria-controls="ThanhVienTapTinDinhKem" aria-selected="true">
                <span class="badge bg-primary" style="font-size: 16px;">7. Tập tin</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="ThanhVienDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="ThanhVienSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>                
                <div class="col-lg-4 col-sm-12 col-12">
                    <a style="width: 100%;" title="Vẽ vùng trồng" (click)="OpenWindowVeVungTrong()"
                        class="btn btn-success"><i class="bi bi-brush"></i> Vẽ vùng trồng</a>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="Active"
                            [(ngModel)]="ThanhVienService.FormData.Active" />
                        <label class="form-check-label" for="Active">Kích hoạt</label>
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12 text-center">
                    <h3><b>Hộ trồng | Doanh nghiệp</b></h3>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tài khoản [{{ThanhVienService.FormData.ID}}]</label>
                        <input name="TaiKhoan" [(ngModel)]="ThanhVienService.FormData.TaiKhoan"
                            [disabled]="ThanhVienService.FormDataLogin && ThanhVienService.FormData.ID>0"
                            placeholder="Tài khoản" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mật khẩu</label>
                        <div class="input-group">
                            <input name="MatKhau" [(ngModel)]="ThanhVienService.FormData.MatKhau" placeholder="Mật khẩu"
                                type="password" class="form-control" [type]="MatKhauIsActive ? 'password' : 'text'">
                            <button class="btn btn-outline-secondary" type="button" (click)="MatKhauChangeType()">
                                <i class="bi bi-eye"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày tham gia</label>
                        <input [ngModel]="ThanhVienService.FormData.NgayBatDau | date:'yyyy-MM-dd'"
                            style="text-align: right;" (ngModelChange)="DateNgayBatDau($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Cán bộ duyệt hồ sơ
                            [{{ThanhVienService.FormData.ThanhVienPheDuyetID}}]</label>
                        <select class="form-select" name="ThanhVienPheDuyetID"
                            [(ngModel)]="ThanhVienService.FormData.ThanhVienPheDuyetID">
                            <option *ngFor="let item of ThanhVienService.ListFilter;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã phiếu</label>
                        <input name="Code" [(ngModel)]="ThanhVienService.FormData.Code" placeholder="Mã phiếu"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Họ tên</label>
                        <input name="Name" [(ngModel)]="ThanhVienService.FormData.Name" placeholder="Họ tên" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày sinh</label>
                        <input [ngModel]="ThanhVienService.FormData.NgaySinh | date:'yyyy-MM-dd'"
                            style="text-align: right;" (ngModelChange)="DateNgaySinh($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Giới tính</label>
                        <input name="GioiTinh" [(ngModel)]="ThanhVienService.FormData.GioiTinh" placeholder="Giới tính"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi chú</label>
                        <input name="Note" [(ngModel)]="ThanhVienService.FormData.Note" placeholder="Ghi chú"
                            type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">CCCD</label>
                        <input name="CCCD" [(ngModel)]="ThanhVienService.FormData.CCCD" placeholder="CCCD" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Nơi cấp</label>
                        <input name="CCCDNoiCap" [(ngModel)]="ThanhVienService.FormData.CCCDNoiCap"
                            placeholder="Nơi cấp" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày cấp</label>
                        <input [ngModel]="ThanhVienService.FormData.CCCDNgayCap | date:'yyyy-MM-dd'"
                            style="text-align: right;" (ngModelChange)="DateCCCDNgayCap($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Trình độ văn hóa
                            [{{ThanhVienService.FormData.DanhMucChungChiID}}]</label>
                        <select class="form-select" name="DanhMucChungChiID"
                            [(ngModel)]="ThanhVienService.FormData.DanhMucChungChiID">
                            <option *ngFor="let item of DanhMucChungChiService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Dân tộc
                            [{{ThanhVienService.FormData.DanhMucDanTocID}}]</label>
                        <select class="form-select" name="DanhMucDanTocID"
                            [(ngModel)]="ThanhVienService.FormData.DanhMucDanTocID">
                            <option *ngFor="let item of DanhMucDanTocService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiếng dân tộc
                            [{{ThanhVienService.FormData.DanhMucNgonNguID}}]</label>
                        <select class="form-select" name="DanhMucNgonNguID"
                            [(ngModel)]="ThanhVienService.FormData.DanhMucNgonNguID">
                            <option *ngFor="let item of DanhMucNgonNguService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngôn ngữ chính
                            [{{ThanhVienService.FormData.DanhMucNgonNguChinhID}}]</label>
                        <select class="form-select" name="DanhMucNgonNguChinhID"
                            [(ngModel)]="ThanhVienService.FormData.DanhMucNgonNguChinhID">
                            <option *ngFor="let item of DanhMucNgonNguService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngôn ngữ phụ
                            [{{ThanhVienService.FormData.DanhMucNgonNguPhuID}}]</label>
                        <select class="form-select" name="DanhMucNgonNguPhuID"
                            [(ngModel)]="ThanhVienService.FormData.DanhMucNgonNguPhuID">
                            <option *ngFor="let item of DanhMucNgonNguService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Email</label>
                        <input name="Email" [(ngModel)]="ThanhVienService.FormData.Email" placeholder="Email"
                            type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tỉnh thành
                            [{{ThanhVienService.FormData.DanhMucTinhThanhID}}]</label>
                        <select class="form-select" name="DanhMucTinhThanhID"
                            [(ngModel)]="ThanhVienService.FormData.DanhMucTinhThanhID" (change)="DanhMucQuanHuyenSearch()">
                            <option *ngFor="let item of DanhMucTinhThanhService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Quận huyện
                            [{{ThanhVienService.FormData.DanhMucQuanHuyenID}}]</label>
                        <select class="form-select" name="DanhMucQuanHuyenID"
                            [(ngModel)]="ThanhVienService.FormData.DanhMucQuanHuyenID" (change)="DanhMucXaPhuongSearch()">
                            <option *ngFor="let item of DanhMucQuanHuyenService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Xã phường
                            [{{ThanhVienService.FormData.DanhMucXaPhuongID}}]</label>
                        <select class="form-select" name="DanhMucXaPhuongID"
                            [(ngModel)]="ThanhVienService.FormData.DanhMucXaPhuongID" (change)="DanhMucXaPhuongChange()">
                            <option *ngFor="let item of DanhMucXaPhuongService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Địa chỉ</label>
                        <input name="DiaChi" [(ngModel)]="ThanhVienService.FormData.DiaChi" placeholder="Địa chỉ"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Điện thoại</label>
                        <input name="DienThoai" [(ngModel)]="ThanhVienService.FormData.DienThoai"
                            placeholder="Điện thoại" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Zalo</label>
                        <input name="Zalo" [(ngModel)]="ThanhVienService.FormData.Zalo" placeholder="Zalo" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Facebook</label>
                        <input name="Facebook" [(ngModel)]="ThanhVienService.FormData.Facebook" placeholder="Facebook"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Youtube</label>
                        <input name="Youtube" [(ngModel)]="ThanhVienService.FormData.Youtube" placeholder="Youtube"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiktok</label>
                        <input name="Tiktok" [(ngModel)]="ThanhVienService.FormData.Tiktok" placeholder="Tiktok"
                            type="text" class="form-control">
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12 text-center">
                    <h3><b>Kinh doanh</b></h3>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Giấy chứng nhận An toàn vệ sinh thực phẩm
                            (ATVSTP)</label>
                        <input name="AnToanVeSinhThucPhamGiayChungNhan"
                            [(ngModel)]="ThanhVienService.FormData.AnToanVeSinhThucPhamGiayChungNhan"
                            placeholder="Giấy chứng nhận An toàn vệ sinh thực phẩm (ATVSTP)" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tư vấn thêm về Giấy chứng nhận ATVSTP</label>
                        <input name="AnToanVeSinhThucPhamTuVan"
                            [(ngModel)]="ThanhVienService.FormData.AnToanVeSinhThucPhamTuVan"
                            placeholder="Tư vấn thêm về Giấy chứng nhận ATVSTP" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ngày cấp Giấy chứng nhận ATVSTP</label>
                        <input [ngModel]="ThanhVienService.FormData.AnToanVeSinhThucPhamNgayCap | date:'yyyy-MM-dd'"
                            style="text-align: right;" (ngModelChange)="DateAnToanVeSinhThucPhamNgayCap($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Sơ chế, chế biến</label>
                        <input name="CheBien" [(ngModel)]="ThanhVienService.FormData.CheBien"
                            placeholder="Sơ chế, chế biến" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đóng gói</label>
                        <input name="DongGoi" [(ngModel)]="ThanhVienService.FormData.DongGoi" placeholder="Đóng gói"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Bảo quản</label>
                        <input name="BaoQuan" [(ngModel)]="ThanhVienService.FormData.BaoQuan" placeholder="Bảo quản"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Vệ sinh, làm sạch</label>
                        <input name="VeSinh" [(ngModel)]="ThanhVienService.FormData.VeSinh"
                            placeholder="Vệ sinh, làm sạch" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Bao bì chuyên dụng có thương hiệu riêng</label>
                        <input name="BaoBi" [(ngModel)]="ThanhVienService.FormData.BaoBi"
                            placeholder="Bao bì chuyên dụng có thương hiệu riêng" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tên doanh nghiệp / HTX / liên minh HTX</label>
                        <input name="ToChucLienKet" [(ngModel)]="ThanhVienService.FormData.ToChucLienKet"
                            placeholder="Tên doanh nghiệp / HTX / liên minh HTX" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã số thuế</label>
                        <input name="MaSoThue" [(ngModel)]="ThanhVienService.FormData.MaSoThue" placeholder="Mã số thuế"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mô hình kinh doanh sâm Ngọc Linh</label>
                        <input name="KinhDoanhMoHinh" [(ngModel)]="ThanhVienService.FormData.KinhDoanhMoHinh"
                            placeholder="Mô hình kinh doanh sâm Ngọc Linh" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Hình thức kinh doanh</label>
                        <input name="KinhDoanhHinhThuc" [(ngModel)]="ThanhVienService.FormData.KinhDoanhHinhThuc"
                            placeholder="Hình thức kinh doanh" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Loại hình hoạt động kinh doanh</label>
                        <input name="KinhDoanhLoaiHinh" [(ngModel)]="ThanhVienService.FormData.KinhDoanhLoaiHinh"
                            placeholder="Loại hình hoạt động kinh doanh" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Điện thoại bán hàng</label>
                        <input name="KinhDoanhDienThoai" [(ngModel)]="ThanhVienService.FormData.KinhDoanhDienThoai"
                            placeholder="Điện thoại bán hàng" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Khu vực bàn hàng</label>
                        <input name="KinhDoanhKhuVuc" [(ngModel)]="ThanhVienService.FormData.KinhDoanhKhuVuc"
                            placeholder="Khu vực bàn hàng" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tên thương hiệu riêng</label>
                        <input name="KinhDoanhThuongHieu" [(ngModel)]="ThanhVienService.FormData.KinhDoanhThuongHieu"
                            placeholder="Tên thương hiệu riêng" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Lao động nam đang hoạt động trồng sâm</label>
                        <input name="LaoDongNam" [(ngModel)]="ThanhVienService.FormData.LaoDongNam"
                            placeholder="Lao động nam đang hoạt động trồng sâm" type="number" class="form-control"
                            style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Lao động nữ đang hoạt động trồng sâm</label>
                        <input name="LaoDongNu" [(ngModel)]="ThanhVienService.FormData.LaoDongNu"
                            placeholder="Lao động nữ đang hoạt động trồng sâm" type="number" class="form-control"
                            style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Lao động được đào tạo và hướng dẫn kiến thức trồng
                            sâm</label>
                        <input name="LaoDongThamGiaDaoTaoHuongDan"
                            [(ngModel)]="ThanhVienService.FormData.LaoDongThamGiaDaoTaoHuongDan"
                            placeholder="Lao động Đã tham gia đào tạo và hướng dẫn kiến thức trồng sâm" type="number"
                            class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Sản phẩm hình thành sau sơ chế, chế biến</label>
                        <input name="SanPham" [(ngModel)]="ThanhVienService.FormData.SanPham"
                            placeholder="Sản phẩm hình thành sau sơ chế, chế biến" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Độ tuổi sâm thường thu hoạch</label>
                        <input name="SanPhamDoTuoiThuHoach"
                            [(ngModel)]="ThanhVienService.FormData.SanPhamDoTuoiThuHoach"
                            placeholder="Độ tuổi sâm thường thu hoạch" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số năm kinh nghiệm hoạt động trồng sâm Ngọc
                            Linh</label>
                        <input name="SanPhamSoNamKinhNghiem"
                            [(ngModel)]="ThanhVienService.FormData.SanPhamSoNamKinhNghiem"
                            placeholder="Số năm kinh nghiệm hoạt động trồng sâm Ngọc Linh" type="number"
                            class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Sản lượng kinh doanh trung bình/năm (kg)</label>
                        <input name="KinhDoanhSanLuong" [(ngModel)]="ThanhVienService.FormData.KinhDoanhSanLuong"
                            placeholder="Sản lượng kinh doanh trung bình/năm (kg)" type="number" class="form-control"
                            style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số năm kinh nghiệm kinh doanh sâm Ngọc
                            Linh</label>
                        <input name="KinhDoanhSoNamKinhNghiem"
                            [(ngModel)]="ThanhVienService.FormData.KinhDoanhSoNamKinhNghiem"
                            placeholder="Số năm kinh nghiệm kinh doanh sâm Ngọc Linh" type="number" class="form-control"
                            style="text-align: right;">
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="ThanhVienService.FormData.ID>0" class="tab-pane fade" id="ThanhVienTapTinDinhKem" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-11 col-sm-12 col-12">
                    <input [(ngModel)]="ThanhVienTapTinDinhKemService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="ThanhVienTapTinDinhKemSearch()">
                </div>
                <div class="col-lg-1 col-sm-6 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm"
                        (click)="ThanhVienTapTinDinhKemSearch()"><i class="bi bi-search"></i></a>
                </div>
            </div>
            <div class="card mb-4 card-primary">
                <div class="card-header">
                    <h5 class="card-title text-white bg-primary">Tập tin đính kèm
                        <span *ngIf="ThanhVienTapTinDinhKemService.List">({{ThanhVienTapTinDinhKemService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body bg-primary">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                #ThanhVienTapTinDinhKemSort="matSort"
                                [dataSource]="ThanhVienTapTinDinhKemService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                        <div *ngIf="ThanhVienTapTinDinhKemPaginator">
                                            {{ThanhVienTapTinDinhKemPaginator.pageSize *
                                            ThanhVienTapTinDinhKemPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ID}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="text" placeholder="Tiêu đề"
                                            name="Name{{element.ID}}" [(ngModel)]="element.Name" />
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="FileName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải lên
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input type="file" style="width: 200px;"
                                            (change)="ThanhVienTapTinDinhKemChangeFileName($event.target.files)">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Note">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải về
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a *ngIf="element.ID > 0 && element.FileName" class="link-primary"
                                            target="_blank" href="{{element.FileName}}"><b>Tải về</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="btn btn-info" (click)="ThanhVienTapTinDinhKemSave(element)"><i
                                                class="bi bi-sd-card"></i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-danger" (click)="ThanhVienTapTinDinhKemDelete(element)"
                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="ThanhVienTapTinDinhKemService.DisplayColumns001">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: ThanhVienTapTinDinhKemService.DisplayColumns001;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile"
                                #ThanhVienTapTinDinhKemPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="6" [showFirstLastButtons]></mat-paginator>

                            <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                #ThanhVienTapTinDinhKemSort="matSort"
                                [dataSource]="ThanhVienTapTinDinhKemService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Tiêu đề</label>
                                                <input class="form-control" type="text" placeholder="Tiêu đề"
                                                    name="Name{{element.ID}}" [(ngModel)]="element.Name">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Tải lên</label>
                                                <input type="file" style="width: 200px;"
                                                    (change)="ThanhVienTapTinDinhKemChangeFileName($event.target.files)">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <a *ngIf="element.ID > 0 && element.FileName" class="link-primary"
                                                    target="_blank" href="{{element.FileName}}"><b>Tải về</b></a>
                                            </div>
                                        </div>
                                        <div class="row gx-4">
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a class="btn btn-info" style="width: 100%;"
                                                    (click)="ThanhVienTapTinDinhKemSave(element)"><i
                                                        class="bi bi-sd-card"></i>
                                                </a>
                                            </div>
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a class="btn btn-danger" style="width: 100%;"
                                                    (click)="ThanhVienTapTinDinhKemDelete(element)"
                                                    *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                </a>
                                            </div>
                                            <br />
                                            <br />
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row
                                    *matHeaderRowDef="ThanhVienTapTinDinhKemService.DisplayColumnsMobile">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: ThanhVienTapTinDinhKemService.DisplayColumnsMobile;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile"
                                #ThanhVienTapTinDinhKemPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="ThanhVienService.FormData.ID>0" class="tab-pane fade" id="ThanhVienVungTrong" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-7 col-sm-12 col-12">
                    <input [(ngModel)]="ThanhVienVungTrongService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="ThanhVienVungTrongSearch()">
                </div>
                <div class="col-lg-1 col-sm-6 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="ThanhVienVungTrongSearch()"><i
                            class="bi bi-search"></i></a>
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Thêm mới"
                        (click)="ThanhVienVungTrongAdd(0)"><i class="bi bi-plus"></i></a>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <a style="width: 100%;" title="Vẽ vùng trồng" (click)="OpenWindowVeVungTrong()"
                        class="btn btn-success"><i class="bi bi-brush"></i> Vẽ vùng trồng</a>
                </div>
            </div>
            <div class="card mb-4 card-danger">
                <div class="card-header">
                    <h5 class="card-title text-white">Vùng nguyên liệu
                        <span *ngIf="ThanhVienVungTrongService.List">({{ThanhVienVungTrongService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                #ThanhVienVungTrongSort="matSort" [dataSource]="ThanhVienVungTrongService.DataSource"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                        <div *ngIf="ThanhVienVungTrongToaDoPaginator">
                                            {{ThanhVienVungTrongPaginator.pageSize *
                                            ThanhVienVungTrongPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ID}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DanhMucVungTrongID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Danh mục
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <select class="form-select" name="element.DanhMucVungTrongID"
                                            [(ngModel)]="element.DanhMucVungTrongID">
                                            <option *ngFor="let item of DanhMucVungTrongService.List;"
                                                [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="VuonSamMaSo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã số
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer;" title="{{element.VuonSamMaSo}}" class="link-primary"
                                            (click)="ThanhVienVungTrongAdd(element.ID)"><b>{{element.VuonSamMaSo}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer;" title="{{element.Name}}" class="link-primary"
                                            (click)="ThanhVienVungTrongAdd(element.ID)"><b>{{element.Name}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="SortOrder">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.SortOrder}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Active">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Trụ sở
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" name="Active{{element.ID}}"
                                                [(ngModel)]="element.Active">
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="TypeName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Tọa độ
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="btn btn-success" *ngIf="element.ID>0"
                                            (click)="ThanhVienVungTrongToaDoAdd(element.ID)"><i
                                                class="bi bi-arrows-fullscreen"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="btn btn-danger" (click)="ThanhVienVungTrongDelete(element)"
                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="ThanhVienVungTrongService.DisplayColumns002">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: ThanhVienVungTrongService.DisplayColumns002;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile"
                                #ThanhVienVungTrongPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="6" [showFirstLastButtons]></mat-paginator>

                            <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                #ThanhVienVungTrongSort="matSort" [dataSource]="ThanhVienVungTrongService.DataSource"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Danh mục</label>
                                                <select class="form-select" name="element.DanhMucVungTrongID"
                                                    [(ngModel)]="element.DanhMucVungTrongID">
                                                    <option *ngFor="let item of DanhMucVungTrongService.List;"
                                                        [value]="item.ID">
                                                        {{item.Name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Mã số</label>
                                                {{element.VuonSamMaSo}}
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Tiêu đề</label>
                                                {{element.Name}}
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox"
                                                        name="Active{{element.ID}}" [(ngModel)]="element.Active">
                                                    <label class="form-label">Trụ sở</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <a class="btn btn-success" *ngIf="element.ID>0"
                                                    (click)="ThanhVienVungTrongToaDoAdd(element.ID)"><i
                                                        class="bi bi-arrows-fullscreen"></i> Tọa độ
                                                </a>
                                            </div>
                                        </div>
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-6 col-6">
                                                <a class="btn btn-danger" style="width: 100%;"
                                                    (click)="ThanhVienVungTrongDelete(element)" *ngIf="element.ID>0"><i
                                                        class="bi bi-trash"></i>
                                                </a>
                                            </div>
                                            <br />
                                            <br />
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="ThanhVienVungTrongService.DisplayColumnsMobile">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: ThanhVienVungTrongService.DisplayColumnsMobile;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile"
                                #ThanhVienVungTrongPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="ThanhVienService.FormData.ID>0" class="tab-pane fade" id="ThanhVienKho" role="tabpanel">

            <div class="row gx-4">
                <div class="col-lg-6 col-sm-6 col-12">
                    <div class="row gx-4">
                        <div class="col-lg-12 col-sm-12 col-12 text-center">
                            <h5><b>Tạo mã truy xuất nguồn gốc (TXNG) rút gọn cho cây sâm</b></h5>
                        </div>
                    </div>
                    <div class="row gx-4">
                        <div class="col-lg-3 col-sm-6 col-12">
                            <a style="width: 100%;" class="btn btn-info" title="Tìm" (click)="LoHangAddRutGon(1)">01
                                năm</a>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="LoHangAddRutGon(2)">02
                                năm</a>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <a style="width: 100%;" class="btn btn-info" title="Tìm" (click)="LoHangAddRutGon(3)">03
                                năm</a>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="LoHangAddRutGon(4)">04
                                năm</a>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="LoHangAddRutGon(5)">05
                                năm</a>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <a style="width: 100%;" class="btn btn-info" title="Tìm" (click)="LoHangAddRutGon(6)">06
                                năm</a>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="LoHangAddRutGon(7)">07
                                năm</a>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <a style="width: 100%;" class="btn btn-info" title="Tìm" (click)="LoHangAddRutGon(8)">08
                                năm</a>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <a style="width: 100%;" class="btn btn-info" title="Tìm" (click)="LoHangAddRutGon(9)">09
                                năm</a>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="LoHangAddRutGon(10)">10
                                năm</a>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <a style="width: 100%;" class="btn btn-info" title="Tìm" (click)="LoHangAddRutGon(15)">Trên
                                10
                                năm</a>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                (click)="LoHangAddRutGon(20)">Trên
                                20
                                năm</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-12">
                    <div class="card mb-4 card-warning">
                        <div class="card-header">
                            <h5 class="card-title text-white">Kho sâm đã khai báo Truy xuất nguồn gốc (TXNG)
                                <span *ngIf="ThanhVienLoHangService.List">({{ThanhVienLoHangService.List.length}}
                                    items)</span>
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #ThanhVienLoHangSort="matSort" [dataSource]="ThanhVienLoHangService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                STT
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;"
                                                style="text-align: center;">
                                                <div *ngIf="ThanhVienLoHangPaginator">
                                                    {{ThanhVienLoHangPaginator.pageSize *
                                                    ThanhVienLoHangPaginator.pageIndex + i + 1}}
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="DoTuoi">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tuổi sâm
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="text-align: center;">
                                                <b>{{element.DoTuoi}}</b>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="SoLuong">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Số lượng sâm đã khai
                                                báo TXNG (Xuất kho)
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="text-align: center;">
                                                <b>{{element.SoLuong}}</b>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="TonKho">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tồn kho
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="text-align: center;">
                                                <b>{{element.TonKho}}</b>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="ThanhVienLoHangService.DisplayColumns001">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: ThanhVienLoHangService.DisplayColumns001;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #ThanhVienLoHangPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                        [pageSize]="100" [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #ThanhVienKhoSort="matSort" [dataSource]="ThanhVienKhoService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Tuổi sâm</label>
                                                        <label class="form-label">{{element.DoTuoi}}</label>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label class="form-label">Số lượng</label>
                                                        <label class="form-label">{{element.SoLuong}}</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="ThanhVienLoHangService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: ThanhVienLoHangService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #ThanhVienLoHangPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                        [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-11 col-sm-12 col-12">
                    <input [(ngModel)]="ThanhVienKhoService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="ThanhVienKhoSearch()">
                </div>
                <div class="col-lg-1 col-sm-6 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="ThanhVienKhoSearch()"><i
                            class="bi bi-search"></i></a>
                </div>
            </div>
            <div class="card mb-4 card-success">
                <div class="card-header">
                    <h5 class="card-title text-white">Khảo sát đầu vào (Nhập kho)
                        <span *ngIf="ThanhVienKhoService.List">({{ThanhVienKhoService.List.length-1}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort #ThanhVienKhoSort="matSort"
                                [dataSource]="ThanhVienKhoService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="NamGhiNhan">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Năm ghi nhận
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="Năm ghi nhận"
                                            style="text-align: right;" name="NamGhiNhan{{element.ID}}"
                                            [(ngModel)]="element.NamGhiNhan">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="VuonSamCayGiongLa">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Lá sâm (kg)
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="Lá sâm (kg)"
                                            style="text-align: right;" name="VuonSamCayGiongHat{{element.ID}}"
                                            [(ngModel)]="element.VuonSamCayGiongHat">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="VuonSamCayGiongHat">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Hạt sâm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="Hạt sâm"
                                            style="text-align: right;" name="VuonSamCayGiongHat{{element.ID}}"
                                            [(ngModel)]="element.VuonSamCayGiongHat">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="VuonSamCayGiongNamTuoi01">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>01 năm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="01 năm"
                                            style="text-align: right;" name="VuonSamCayGiongNamTuoi01{{element.ID}}"
                                            [(ngModel)]="element.VuonSamCayGiongNamTuoi01">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="VuonSamCayGiongNamTuoi02">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>02 năm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="02 năm"
                                            style="text-align: right;" name="VuonSamCayGiongNamTuoi02{{element.ID}}"
                                            [(ngModel)]="element.VuonSamCayGiongNamTuoi02">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="VuonSamCayGiongNamTuoi03">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>03 năm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="03 năm"
                                            style="text-align: right;" name="VuonSamCayGiongNamTuoi03{{element.ID}}"
                                            [(ngModel)]="element.VuonSamCayGiongNamTuoi03">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="VuonSamCayGiongNamTuoi04">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>04 năm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="04 năm"
                                            style="text-align: right;" name="VuonSamCayGiongNamTuoi04{{element.ID}}"
                                            [(ngModel)]="element.VuonSamCayGiongNamTuoi04">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="VuonSamCayGiongNamTuoi05">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>05 năm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="05 năm"
                                            style="text-align: right;" name="VuonSamCayGiongNamTuoi05{{element.ID}}"
                                            [(ngModel)]="element.VuonSamCayGiongNamTuoi05">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="VuonSamCayGiongNamTuoi06">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>06 năm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="06 năm"
                                            style="text-align: right;" name="VuonSamCayGiongNamTuoi06{{element.ID}}"
                                            [(ngModel)]="element.VuonSamCayGiongNamTuoi06">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="VuonSamCayGiongNamTuoi07">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>07 năm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="07 năm"
                                            style="text-align: right;" name="VuonSamCayGiongNamTuoi07{{element.ID}}"
                                            [(ngModel)]="element.VuonSamCayGiongNamTuoi07">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="VuonSamCayGiongNamTuoi08">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>08 năm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="08 năm"
                                            style="text-align: right;" name="VuonSamCayGiongNamTuoi08{{element.ID}}"
                                            [(ngModel)]="element.VuonSamCayGiongNamTuoi08">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="VuonSamCayGiongNamTuoi09">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>09 năm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="09 năm"
                                            style="text-align: right;" name="VuonSamCayGiongNamTuoi09{{element.ID}}"
                                            [(ngModel)]="element.VuonSamCayGiongNamTuoi09">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="VuonSamCayGiongNamTuoi10">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>10 năm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="10 năm"
                                            style="text-align: right;" name="VuonSamCayGiongNamTuoi10{{element.ID}}"
                                            [(ngModel)]="element.VuonSamCayGiongNamTuoi10">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="VuonSamCayGiongNamTuoi11">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Trên 10 năm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="Trên 10 năm"
                                            style="text-align: right;" name="VuonSamCayGiongNamTuoi11{{element.ID}}"
                                            [(ngModel)]="element.VuonSamCayGiongNamTuoi11">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="VuonSamCayGiongNamTuoi21">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Trên 20 năm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" placeholder="Trên 20 năm"
                                            style="text-align: right;" name="VuonSamCayGiongNamTuoi21{{element.ID}}"
                                            [(ngModel)]="element.VuonSamCayGiongNamTuoi21">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="btn btn-info" (click)="ThanhVienKhoSave(element)"><i
                                                class="bi bi-sd-card"></i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-info" (click)="ThanhVienKhoCopy(element)"
                                            *ngIf="element.ID>0"><i class="bi bi-subtract"></i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-danger" (click)="ThanhVienKhoDelete(element)"
                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="ThanhVienKhoService.DisplayColumns002">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: ThanhVienKhoService.DisplayColumns002;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile" #ThanhVienKhoPaginator="matPaginator"
                                [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                [showFirstLastButtons]></mat-paginator>

                            <table *ngIf="NotificationService.IsMobile" mat-table matSort #ThanhVienKhoSort="matSort"
                                [dataSource]="ThanhVienKhoService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Năm ghi nhận</label>
                                                <input class="form-control" type="number" placeholder="Năm ghi nhận"
                                                    style="text-align: right;" name="NamGhiNhan{{element.ID}}"
                                                    [(ngModel)]="element.NamGhiNhan">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Lá sâm (kg)</label>
                                                <input class="form-control" type="number" placeholder="Lá sâm (kg)"
                                                    style="text-align: right;" name="VuonSamCayGiongLa{{element.ID}}"
                                                    [(ngModel)]="element.VuonSamCayGiongLa">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Hạt sâm</label>
                                                <input class="form-control" type="number" placeholder="Hạt sâm"
                                                    style="text-align: right;" name="VuonSamCayGiongHat{{element.ID}}"
                                                    [(ngModel)]="element.VuonSamCayGiongHat">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">01 năm</label>
                                                <input class="form-control" type="number" placeholder="01 năm"
                                                    style="text-align: right;"
                                                    name="VuonSamCayGiongNamTuoi01{{element.ID}}"
                                                    [(ngModel)]="element.VuonSamCayGiongNamTuoi01">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">02 năm</label>
                                                <input class="form-control" type="number" placeholder="02 năm"
                                                    style="text-align: right;"
                                                    name="VuonSamCayGiongNamTuoi02{{element.ID}}"
                                                    [(ngModel)]="element.VuonSamCayGiongNamTuoi02">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">03 năm</label>
                                                <input class="form-control" type="number" placeholder="03 năm"
                                                    style="text-align: right;"
                                                    name="VuonSamCayGiongNamTuoi03{{element.ID}}"
                                                    [(ngModel)]="element.VuonSamCayGiongNamTuoi03">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">04 năm</label>
                                                <input class="form-control" type="number" placeholder="04 năm"
                                                    style="text-align: right;"
                                                    name="VuonSamCayGiongNamTuoi04{{element.ID}}"
                                                    [(ngModel)]="element.VuonSamCayGiongNamTuoi04">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">05 năm</label>
                                                <input class="form-control" type="number" placeholder="05 năm"
                                                    style="text-align: right;"
                                                    name="VuonSamCayGiongNamTuoi05{{element.ID}}"
                                                    [(ngModel)]="element.VuonSamCayGiongNamTuoi05">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">06 năm</label>
                                                <input class="form-control" type="number" placeholder="06 năm"
                                                    style="text-align: right;"
                                                    name="VuonSamCayGiongNamTuoi06{{element.ID}}"
                                                    [(ngModel)]="element.VuonSamCayGiongNamTuoi06">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">07 năm</label>
                                                <input class="form-control" type="number" placeholder="07 năm"
                                                    style="text-align: right;"
                                                    name="VuonSamCayGiongNamTuoi07{{element.ID}}"
                                                    [(ngModel)]="element.VuonSamCayGiongNamTuoi07">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">08 năm</label>
                                                <input class="form-control" type="number" placeholder="08 năm"
                                                    style="text-align: right;"
                                                    name="VuonSamCayGiongNamTuoi08{{element.ID}}"
                                                    [(ngModel)]="element.VuonSamCayGiongNamTuoi08">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">09 năm</label>
                                                <input class="form-control" type="number" placeholder="09 năm"
                                                    style="text-align: right;"
                                                    name="VuonSamCayGiongNamTuoi09{{element.ID}}"
                                                    [(ngModel)]="element.VuonSamCayGiongNamTuoi09">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">10 năm</label>
                                                <input class="form-control" type="number" placeholder="10 năm"
                                                    style="text-align: right;"
                                                    name="VuonSamCayGiongNamTuoi10{{element.ID}}"
                                                    [(ngModel)]="element.VuonSamCayGiongNamTuoi10">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Trên 10 năm</label>
                                                <input class="form-control" type="number" placeholder="Trên 10 năm"
                                                    style="text-align: right;"
                                                    name="VuonSamCayGiongNamTuoi11{{element.ID}}"
                                                    [(ngModel)]="element.VuonSamCayGiongNamTuoi11">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Trên 20 năm</label>
                                                <input class="form-control" type="number" placeholder="Trên 20 năm"
                                                    style="text-align: right;"
                                                    name="VuonSamCayGiongNamTuoi21{{element.ID}}"
                                                    [(ngModel)]="element.VuonSamCayGiongNamTuoi21">
                                            </div>
                                        </div>
                                        <div class="row gx-4">
                                            <div class="col-lg-4 col-sm-6 col-6">
                                                <a class="btn btn-info" style="width: 100%;"
                                                    (click)="ThanhVienKhoSave(element)"><i class="bi bi-sd-card"></i>
                                                </a>
                                            </div>
                                            <div class="col-lg-4 col-sm-6 col-6">
                                                <a class="btn btn-info" style="width: 100%;"
                                                    (click)="ThanhVienKhoCopy(element)" *ngIf="element.ID>0"><i
                                                        class="bi bi-subtract"></i>
                                                </a>
                                            </div>
                                            <div class="col-lg-4 col-sm-6 col-6">
                                                <a class="btn btn-danger" style="width: 100%;"
                                                    (click)="ThanhVienKhoDelete(element)" *ngIf="element.ID>0"><i
                                                        class="bi bi-trash"></i>
                                                </a>
                                            </div>
                                            <br />
                                            <br />
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="ThanhVienKhoService.DisplayColumnsMobile">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: ThanhVienKhoService.DisplayColumnsMobile;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile" #ThanhVienKhoPaginator="matPaginator"
                                [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="ThanhVienService.FormData.ID>0" class="tab-pane fade" id="LoHang" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-11 col-sm-12 col-12">                    
                    <input [(ngModel)]="LoHangService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="LoHangSearch()">
                </div>
                <div class="col-lg-1 col-sm-12 col-12">                   
                    <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="LoHangSearch()"><i
                            class="bi bi-search"></i></a>
                </div>
            </div>
            <div class="card mb-4 card-warning">
                <div class="card-header">
                    <h5 class="card-title text-white">Danh sách
                        <span *ngIf="LoHangService.List">({{LoHangService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort #LoHangSort="matSort"
                                [dataSource]="LoHangService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="text-align: center;">
                                        <b>{{element.ID}}</b>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DoTuoi">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tuổi sâm
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="text-align: center;">
                                        <b>{{element.DoTuoi}}</b>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã lô hàng | Mã sâm (Xuất kho)
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer;" title="{{element.MaLoHang}}" class="link-primary"
                                            (click)="LoHangAddRutGonByID(element.ID)"><b>{{element.MaLoHang}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="HoSo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Hồ sơ
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="text-align: center;">
                                        <a style="width: 100%;" class="btn btn-success" (click)="LoHangAdd(element.ID)"
                                            *ngIf="element.ID > 0"><i class="bi bi-people"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Copy">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Copy
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="text-align: center;">
                                        <a style="width: 100%;" class="btn btn-success" (click)="LoHangCopy(element)"
                                            *ngIf="element.ID > 0"><i class="bi bi-subtract"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Delete">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Xóa
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="text-align: center;">
                                        <a style="width: 100%;" class="btn btn-danger" (click)="LoHangDelete(element)"
                                            *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="LoHangService.DisplayColumns004">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: LoHangService.DisplayColumns004;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile" #LoHangPaginator="matPaginator"
                                [pageSizeOptions]="[100,500,1000,10000]" [pageSize]="6"
                                [showFirstLastButtons]></mat-paginator>
                            <table *ngIf="NotificationService.IsMobile" mat-table matSort #LoHangSort="matSort"
                                [dataSource]="LoHangService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Mã lô hàng:&nbsp;</label>
                                                <a style="cursor: pointer;" title="{{element.MaLoHang}}"
                                                    class="link-primary"
                                                    (click)="LoHangAddRutGonByID(element.ID)"><b>{{element.MaLoHang}}</b></a>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Tuổi sâm:&nbsp;</label>
                                                <label class="form-label">{{element.DoTuoi}}</label>
                                            </div>
                                        </div>
                                        <div class="row gx-4">
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a style="width: 100%;" class="btn btn-success"
                                                    (click)="LoHangAdd(element.ID)" *ngIf="element.ID > 0"><i
                                                        class="bi bi-people"></i> Hồ sơ
                                                </a>
                                            </div>
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a style="width: 100%;" class="btn btn-success"
                                                    (click)="LoHangAddRutGonByID(element.ID)" *ngIf="element.ID > 0"><i
                                                        class="bi bi-subtract"></i> Rút gọn
                                                </a>
                                            </div>
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a style="width: 100%;" class="btn btn-success"
                                                    (click)="LoHangCopy(element)" *ngIf="element.ID > 0"><i
                                                        class="bi bi-subtract"></i> Copy
                                                </a>
                                            </div>
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a style="width: 100%;" class="btn btn-danger"
                                                    (click)="LoHangDelete(element)" *ngIf="element.ID > 0"><i
                                                        class="bi bi-trash"></i> Xóa
                                                </a>
                                            </div>
                                            <br />
                                            <br />
                                            <br />
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="LoHangService.DisplayColumnsMobile">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: LoHangService.DisplayColumnsMobile;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile" #LoHangPaginator="matPaginator"
                                [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="ThanhVienService.FormData.ID>0" class="tab-pane fade" id="TruyXuatNguonGocTemPhieuKhoiTao"
            role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-10 col-sm-12 col-12">                   
                    <input [(ngModel)]="TemPhieuKhoiTaoService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="TemPhieuKhoiTaoSearch()">
                </div>
                <div class="col-lg-1 col-sm-12 col-12">                   
                    <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="TemPhieuKhoiTaoSearch()"><i
                            class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                </div>
                <div class="col-lg-1 col-sm-12 col-12">                    
                    <a style="width: 100%;" class="btn btn-success" title="Thêm mới" (click)="TemPhieuKhoiTaoAdd(0)"><i
                            class="bi bi-plus"></i></a>
                </div>
            </div>
            <div class="card mb-4 card-warning">
                <div class="card-header">
                    <h5 class="card-title text-white">Danh sách
                        <span *ngIf="TemPhieuKhoiTaoService.List">({{TemPhieuKhoiTaoService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                #TemPhieuKhoiTaoSort="matSort" [dataSource]="TemPhieuKhoiTaoService.DataSource"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                        <div *ngIf="TemPhieuKhoiTaoPaginator">
                                            {{TemPhieuKhoiTaoPaginator.pageSize *
                                            TemPhieuKhoiTaoPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer;" title="{{element.Code}}" class="link-primary"
                                            (click)="TemPhieuKhoiTaoAdd(element.ID)"><b>{{element.ID}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="GhiNhan">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Ngày cấp
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer;" title="{{element.Code}}" class="link-primary"
                                            (click)="TemPhieuKhoiTaoAdd(element.ID)"><b>{{element.GhiNhan
                                                |
                                                date:'dd/MM/yyyy'}}</b></a>
                                    </td>
                                </ng-container>                               
                                <ng-container matColumnDef="Code">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã lô tem
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer;" title="{{element.Code}}" class="link-primary"
                                            (click)="TemPhieuKhoiTaoAdd(element.ID)"><b>{{element.Code}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="SoLuong">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Số lượng
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="text-align: right;">
                                        <b>{{element.SoLuong}}</b>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="SuDung">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sử dụng
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="color: red; text-align: right;">
                                        <b>{{element.SuDung}}</b>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ConLai">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Còn lại
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="color: green; text-align: right;">
                                        <b>{{element.ConLai}}</b>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Active">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" name="Active{{element.ID}}"
                                                [(ngModel)]="element.Active">
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="IsThuHoi">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Thu hồi
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox"
                                                name="IsThuHoi{{element.ID}}" [(ngModel)]="element.IsThuHoi">
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="btn btn-danger" (click)="TemPhieuKhoiTaoDelete(element)"
                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="TemPhieuKhoiTaoService.DisplayColumns003">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: TemPhieuKhoiTaoService.DisplayColumns003;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile"
                                #TemPhieuKhoiTaoPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                            <table *ngIf="NotificationService.IsMobile" mat-table matSort #TemPhieuKhoiTaoSort="matSort"
                                [dataSource]="TemPhieuKhoiTaoService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Ghi nhận: &nbsp;</label>
                                                <label class="form-label">{{element.GhiNhan |
                                                    date:'dd/MM/yyyy'}}</label>
                                            </div>                                           
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Mã lô: &nbsp;</label>
                                                <label class="form-label">{{element.Code}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Số lượng: &nbsp;</label>
                                                <label class="form-label">{{element.SoLuong}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Sử dụng: &nbsp;</label>
                                                <label class="form-label"
                                                    style="color: red;">{{element.DaSuDung}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Còn lại: &nbsp;</label>
                                                <label class="form-label"
                                                    style="color: green;">{{element.ConLai}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox"
                                                        name="Active{{element.ID}}" [(ngModel)]="element.Active">
                                                    <label class="form-label">Kích hoạt</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox"
                                                        name="IsThuHoi{{element.ID}}" [(ngModel)]="element.IsThuHoi">
                                                    <label class="form-label">Thu hồi</label>
                                                </div>
                                            </div>
                                            <div class="row gx-4">
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <a class="btn btn-danger" style="width: 100%;"
                                                        (click)="TemPhieuKhoiTaoDelete(element)" *ngIf="element.ID>0"><i
                                                            class="bi bi-trash"></i>
                                                    </a>
                                                </div>
                                                <br />
                                                <br />
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="TemPhieuKhoiTaoService.DisplayColumnsMobile">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: TemPhieuKhoiTaoService.DisplayColumnsMobile;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile" #TemPhieuKhoiTaoPaginator="matPaginator"
                                [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="ThanhVienService.FormData.ID>0" class="tab-pane fade" id="ThanhVienMap" role="tabpanel">
            <div class="row">
                <div class="col-lg-3 col-sm-12 col-12">
                    <a style="width: 100%;" title="Tải lại bản đồ" (click)="MapLoad(0)" class="btn btn-success"><i
                            class="bi bi-arrow-repeat"></i> Tải lại bản
                        đồ</a>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                    <a style="width: 100%;" title="Vẽ vùng trồng" (click)="OpenWindowVeVungTrong()"
                        class="btn btn-success"><i class="bi bi-brush"></i> Vẽ vùng trồng</a>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="map" #map style="height: 600px; width: 100%;">
                        <div style="z-index: 1000; position: absolute; top: 0;">
                            <img src="{{domainURL}}assets/image/vungtrong.png" />
                        </div>
                        <div
                            style="z-index: 1000; position: absolute; bottom: 0; width: 100%; height: 20px; background-color: #ffffff;">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ThanhVienService.IsShowLoading"></app-loading>