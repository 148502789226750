import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';


import { DanhMucVungTrong } from 'src/app/shared/DanhMucVungTrong.model';
import { DanhMucVungTrongService } from 'src/app/shared/DanhMucVungTrong.service';

@Component({
  selector: 'app-danh-muc-vung-trong',
  templateUrl: './danh-muc-vung-trong.component.html',
  styleUrls: ['./danh-muc-vung-trong.component.css']
})
export class DanhMucVungTrongComponent implements OnInit {

  @ViewChild('DanhMucVungTrongSort') DanhMucVungTrongSort: MatSort;
  @ViewChild('DanhMucVungTrongPaginator') DanhMucVungTrongPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucVungTrongService: DanhMucVungTrongService,
  ) { }

  ngOnInit(): void {    
  }

  DanhMucVungTrongSearch() {
    this.DanhMucVungTrongService.SearchAll(this.DanhMucVungTrongSort, this.DanhMucVungTrongPaginator);
  }
  DanhMucVungTrongSave(element: DanhMucVungTrong) {
    this.DanhMucVungTrongService.FormData = element;
    this.NotificationService.warn(this.DanhMucVungTrongService.ComponentSaveAll(this.DanhMucVungTrongSort, this.DanhMucVungTrongPaginator));
  }
  DanhMucVungTrongDelete(element: DanhMucVungTrong) {
    this.DanhMucVungTrongService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucVungTrongService.ComponentDeleteAll(this.DanhMucVungTrongSort, this.DanhMucVungTrongPaginator));
  }  
}