import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';


import { DanhMucDanToc } from 'src/app/shared/DanhMucDanToc.model';
import { DanhMucDanTocService } from 'src/app/shared/DanhMucDanToc.service';

@Component({
  selector: 'app-danh-muc-dan-toc',
  templateUrl: './danh-muc-dan-toc.component.html',
  styleUrls: ['./danh-muc-dan-toc.component.css']
})
export class DanhMucDanTocComponent implements OnInit {

  @ViewChild('DanhMucDanTocSort') DanhMucDanTocSort: MatSort;
  @ViewChild('DanhMucDanTocPaginator') DanhMucDanTocPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucDanTocService: DanhMucDanTocService,
  ) { }

  ngOnInit(): void {    
  }

  DanhMucDanTocSearch() {
    this.DanhMucDanTocService.SearchAll(this.DanhMucDanTocSort, this.DanhMucDanTocPaginator);
  }
  DanhMucDanTocSave(element: DanhMucDanToc) {
    this.DanhMucDanTocService.FormData = element;
    this.NotificationService.warn(this.DanhMucDanTocService.ComponentSaveAll(this.DanhMucDanTocSort, this.DanhMucDanTocPaginator));
  }
  DanhMucDanTocDelete(element: DanhMucDanToc) {
    this.DanhMucDanTocService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucDanTocService.ComponentDeleteAll(this.DanhMucDanTocSort, this.DanhMucDanTocPaginator));
  }  
}