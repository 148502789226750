import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { LoginComponent } from './login/login.component';
import { DanhMucChucNangComponent } from './danh-muc-chuc-nang/danh-muc-chuc-nang.component';
import { DanhMucThanhVienComponent } from './danh-muc-thanh-vien/danh-muc-thanh-vien.component';
import { ThanhVienComponent } from './thanh-vien/thanh-vien.component';
import { ThanhVienChucNangComponent } from './thanh-vien-chuc-nang/thanh-vien-chuc-nang.component';
import { ThanhVienLichSuTruyCapComponent } from './thanh-vien-lich-su-truy-cap/thanh-vien-lich-su-truy-cap.component';
import { ThanhVienTokenComponent } from './thanh-vien-token/thanh-vien-token.component';
import { ThanhVienThongTinComponent } from './thanh-vien-thong-tin/thanh-vien-thong-tin.component';
import { GioiThieuComponent } from './gioi-thieu/gioi-thieu.component';
import { HuongDanComponent } from './huong-dan/huong-dan.component';
import { UploadComponent } from './upload/upload.component';
import { ThanhVien002Component } from './thanh-vien002/thanh-vien002.component';
import { DanhMucNgonNguComponent } from './danh-muc-ngon-ngu/danh-muc-ngon-ngu.component';
import { DanhMucDanTocComponent } from './danh-muc-dan-toc/danh-muc-dan-toc.component';
import { DanhMucChungChiComponent } from './danh-muc-chung-chi/danh-muc-chung-chi.component';
import { TruyXuatNguonGocLoHangComponent } from './truy-xuat-nguon-goc-lo-hang/truy-xuat-nguon-goc-lo-hang.component';
import { TruyXuatNguonGocTemPhieuKhoiTaoComponent } from './truy-xuat-nguon-goc-tem-phieu-khoi-tao/truy-xuat-nguon-goc-tem-phieu-khoi-tao.component';
import { TruyXuatNguonGocKhoTemComponent } from './truy-xuat-nguon-goc-kho-tem/truy-xuat-nguon-goc-kho-tem.component';
import { TruyXuatNguonGocKhoTem001Component } from './truy-xuat-nguon-goc-kho-tem001/truy-xuat-nguon-goc-kho-tem001.component';
import { DanhMucVungTrongComponent } from './danh-muc-vung-trong/danh-muc-vung-trong.component';
import { TruyXuatNguonGocLoHangInfoComponent } from './truy-xuat-nguon-goc-lo-hang-info/truy-xuat-nguon-goc-lo-hang-info.component';
import { TruyXuatNguonGocDanhMucPhuongThucMuaBanComponent } from './truy-xuat-nguon-goc-danh-muc-phuong-thuc-mua-ban/truy-xuat-nguon-goc-danh-muc-phuong-thuc-mua-ban.component';
import { TruyXuatNguonGocLoHangMuaBanComponent } from './truy-xuat-nguon-goc-lo-hang-mua-ban/truy-xuat-nguon-goc-lo-hang-mua-ban.component';
import { DanhMucBaiVietComponent } from './danh-muc-bai-viet/danh-muc-bai-viet.component';
import { BaiVietComponent } from './bai-viet/bai-viet.component';
import { BanDoComponent } from './ban-do/ban-do.component';
import { BanDo001Component } from './ban-do001/ban-do001.component';
import { BanDo002Component } from './ban-do002/ban-do002.component';
import { BanDo003Component } from './ban-do003/ban-do003.component';


const routes: Routes = [
  { path: '', redirectTo: '/Login', pathMatch: 'full' },
  {
    path: 'Homepage', component: HomepageComponent,
  },
  {
    path: 'Login', component: LoginComponent,
  },  
  {
    path: 'GioiThieu', component: GioiThieuComponent,
  },  
  {
    path: 'HuongDan', component: HuongDanComponent,
  },  

  {
    path: 'DanhMucChucNang', component: DanhMucChucNangComponent,
  },  
  {
    path: 'DanhMucThanhVien', component: DanhMucThanhVienComponent,
  },  
  {
    path: 'DanhMucChungChi', component: DanhMucChungChiComponent,
  },  
  {
    path: 'DanhMucDanToc', component: DanhMucDanTocComponent,
  },  
  {
    path: 'DanhMucNgonNgu', component: DanhMucNgonNguComponent,
  },  
  {
    path: 'DanhMucVungTrong', component: DanhMucVungTrongComponent,
  }, 
  {
    path: 'DanhMucBaiViet', component: DanhMucBaiVietComponent,
  }, 

  {
    path: 'BaiViet', component: BaiVietComponent,
  }, 
  {
    path: 'BanDo', component: BanDoComponent,
  }, 
  {
    path: 'BanDo001', component: BanDo001Component,
  }, 
  {
    path: 'BanDo002', component: BanDo002Component,
  }, 
  {
    path: 'BanDo003', component: BanDo003Component,
  }, 
  {
    path: 'BanDo003/:ID', component: BanDo003Component,
  },

  {
    path: 'ThanhVien', component: ThanhVienComponent,
  }, 
  {
    path: 'ThanhVien002', component: ThanhVien002Component,
  }, 
  {
    path: 'ThanhVienChucNang', component: ThanhVienChucNangComponent,
  }, 
  {
    path: 'ThanhVienLichSuTruyCap', component: ThanhVienLichSuTruyCapComponent,
  }, 
  {
    path: 'ThanhVienToken', component: ThanhVienTokenComponent,
  },  
  {
    path: 'ThanhVienThongTin', component: ThanhVienThongTinComponent,
  },  
  {
    path: 'Upload', component: UploadComponent,
  },  

  {
    path: 'TruyXuatNguonGocLoHang', component: TruyXuatNguonGocLoHangComponent,
  },
  {
    path: 'TruyXuatNguonGocTemPhieuKhoiTao', component: TruyXuatNguonGocTemPhieuKhoiTaoComponent,
  },
  {
    path: 'TruyXuatNguonGocKhoTem', component: TruyXuatNguonGocKhoTemComponent,
  },
  {
    path: 'TruyXuatNguonGocKhoTem001', component: TruyXuatNguonGocKhoTem001Component,
  },
  {
    path: 'LoHangInfo/:ID', component: TruyXuatNguonGocLoHangInfoComponent,
  },
  {
    path: 'TruyXuatNguonGocLoHangMuaBan', component: TruyXuatNguonGocLoHangMuaBanComponent,
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }









































































