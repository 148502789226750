import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { DanhMucThanhVien } from 'src/app/shared/DanhMucThanhVien.model';
import { DanhMucThanhVienService } from 'src/app/shared/DanhMucThanhVien.service';

import { DanhMucChungChi } from 'src/app/shared/DanhMucChungChi.model';
import { DanhMucChungChiService } from 'src/app/shared/DanhMucChungChi.service';
import { DanhMucDanToc } from 'src/app/shared/DanhMucDanToc.model';
import { DanhMucDanTocService } from 'src/app/shared/DanhMucDanToc.service';
import { DanhMucNgonNgu } from 'src/app/shared/DanhMucNgonNgu.model';
import { DanhMucNgonNguService } from 'src/app/shared/DanhMucNgonNgu.service';
import { DanhMucVungTrong } from 'src/app/shared/DanhMucVungTrong.model';
import { DanhMucVungTrongService } from 'src/app/shared/DanhMucVungTrong.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ThanhVienTapTinDinhKem } from 'src/app/shared/ThanhVienTapTinDinhKem.model';
import { ThanhVienTapTinDinhKemService } from 'src/app/shared/ThanhVienTapTinDinhKem.service';
import { ThanhVienVungTrong } from 'src/app/shared/ThanhVienVungTrong.model';
import { ThanhVienVungTrongService } from 'src/app/shared/ThanhVienVungTrong.service';
import { ThanhVienVungTrongToaDo } from 'src/app/shared/ThanhVienVungTrongToaDo.model';
import { ThanhVienVungTrongToaDoService } from 'src/app/shared/ThanhVienVungTrongToaDo.service';
import { ThanhVienKho } from 'src/app/shared/ThanhVienKho.model';
import { ThanhVienKhoService } from 'src/app/shared/ThanhVienKho.service';
import { ThanhVienLoHang } from 'src/app/shared/ThanhVienLoHang.model';
import { ThanhVienLoHangService } from 'src/app/shared/ThanhVienLoHang.service';

import { LoHang } from 'src/app/shared/TruyXuatNguonGoc/LoHang.model';
import { LoHangService } from 'src/app/shared/TruyXuatNguonGoc/LoHang.service';
import { TruyXuatNguonGocDanhMucUngDung } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.model';
import { TruyXuatNguonGocDanhMucUngDungService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.service';
import { DanhMucTinhThanh } from 'src/app/shared/TruyXuatNguonGoc/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/TruyXuatNguonGoc/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/TruyXuatNguonGoc/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucXaPhuong.service';

import { TemPhieuKhoiTao } from 'src/app/shared/TruyXuatNguonGoc/TemPhieuKhoiTao.model';
import { TemPhieuKhoiTaoService } from 'src/app/shared/TruyXuatNguonGoc/TemPhieuKhoiTao.service';

import * as maplibregl from 'maplibre-gl';
import { ThanhVienVungTrongToaDoDetailComponent } from '../thanh-vien-vung-trong-toa-do-detail/thanh-vien-vung-trong-toa-do-detail.component';
import { ThanhVienVungTrongDetailComponent } from '../thanh-vien-vung-trong-detail/thanh-vien-vung-trong-detail.component';
import { TruyXuatNguonGocLoHangDetailRutGonComponent } from '../truy-xuat-nguon-goc-lo-hang-detail-rut-gon/truy-xuat-nguon-goc-lo-hang-detail-rut-gon.component';
import { TruyXuatNguonGocLoHangDetailComponent } from '../truy-xuat-nguon-goc-lo-hang-detail/truy-xuat-nguon-goc-lo-hang-detail.component';
import { TruyXuatNguonGocTemPhieuKhoiTaoDetailComponent } from '../truy-xuat-nguon-goc-tem-phieu-khoi-tao-detail/truy-xuat-nguon-goc-tem-phieu-khoi-tao-detail.component';
import { TruyXuatNguonGocTemPhieuKhoiTaoDetail001Component } from '../truy-xuat-nguon-goc-tem-phieu-khoi-tao-detail001/truy-xuat-nguon-goc-tem-phieu-khoi-tao-detail001.component';
import { TruyXuatNguonGocLoHangDetailRutGon001Component } from '../truy-xuat-nguon-goc-lo-hang-detail-rut-gon001/truy-xuat-nguon-goc-lo-hang-detail-rut-gon001.component';

@Component({
  selector: 'app-thanh-vien-detail002',
  templateUrl: './thanh-vien-detail002.component.html',
  styleUrls: ['./thanh-vien-detail002.component.css']
})
export class ThanhVienDetail002Component implements OnInit {

  @ViewChild('ThanhVienTapTinDinhKemSort') ThanhVienTapTinDinhKemSort: MatSort;
  @ViewChild('ThanhVienTapTinDinhKemPaginator') ThanhVienTapTinDinhKemPaginator: MatPaginator;


  @ViewChild('ThanhVienVungTrongSort') ThanhVienVungTrongSort: MatSort;
  @ViewChild('ThanhVienVungTrongPaginator') ThanhVienVungTrongPaginator: MatPaginator;

  @ViewChild('ThanhVienKhoSort') ThanhVienKhoSort: MatSort;
  @ViewChild('ThanhVienKhoPaginator') ThanhVienKhoPaginator: MatPaginator;

  @ViewChild('ThanhVienLoHangSort') ThanhVienLoHangSort: MatSort;
  @ViewChild('ThanhVienLoHangPaginator') ThanhVienLoHangPaginator: MatPaginator;

  @ViewChild('LoHangSort') LoHangSort: MatSort;
  @ViewChild('LoHangPaginator') LoHangPaginator: MatPaginator;

  @ViewChild('TemPhieuKhoiTaoSort') TemPhieuKhoiTaoSort: MatSort;
  @ViewChild('TemPhieuKhoiTaoPaginator') TemPhieuKhoiTaoPaginator: MatPaginator;

  MatKhauIsActive: boolean = true;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ThanhVienDetail002Component>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucThanhVienService: DanhMucThanhVienService,
    public DanhMucChungChiService: DanhMucChungChiService,
    public DanhMucDanTocService: DanhMucDanTocService,
    public DanhMucNgonNguService: DanhMucNgonNguService,
    public DanhMucVungTrongService: DanhMucVungTrongService,

    public ThanhVienService: ThanhVienService,
    public ThanhVienTapTinDinhKemService: ThanhVienTapTinDinhKemService,
    public ThanhVienVungTrongService: ThanhVienVungTrongService,
    public ThanhVienVungTrongToaDoService: ThanhVienVungTrongToaDoService,
    public ThanhVienKhoService: ThanhVienKhoService,
    public ThanhVienLoHangService: ThanhVienLoHangService,

    public LoHangService: LoHangService,
    public TruyXuatNguonGocDanhMucUngDungService: TruyXuatNguonGocDanhMucUngDungService,
    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,

    public TemPhieuKhoiTaoService: TemPhieuKhoiTaoService,

  ) { }

  ngOnInit(): void {
    this.TruyXuatNguonGocDanhMucUngDungService.GetLogin();
    this.ThanhVienSearch();
  }
  MatKhauChangeType() {
    this.MatKhauIsActive = !this.MatKhauIsActive;
  }
  DanhMucTinhThanhSearch() {

    if (this.DanhMucTinhThanhService.List.length == 0) {
      this.ThanhVienService.IsShowLoading = true;
      this.DanhMucTinhThanhService.BaseParameter.ParentID = this.ThanhVienService.FormData.DanhMucQuocGiaID;
      this.DanhMucTinhThanhService.GetByParentIDToListAsync().subscribe(
        res => {
          this.DanhMucTinhThanhService.List = (res as DanhMucTinhThanh[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
          this.DanhMucQuanHuyenSearch();
        },
        err => {
        },
        () => {
          this.ThanhVienService.IsShowLoading = false;
        }
      );
    }

  }
  DanhMucQuanHuyenSearch() {
    this.ThanhVienService.IsShowLoading = true;

    for (let i = 0; i < this.DanhMucTinhThanhService.List.length; i++) {
      if (this.DanhMucTinhThanhService.List[i].ID == this.ThanhVienVungTrongService.FormData.DanhMucTinhThanhID) {
        this.ThanhVienVungTrongService.FormData.DanhMucTinhThanhName = this.DanhMucTinhThanhService.List[i].Name;
        i = this.DanhMucTinhThanhService.List.length;
      }
    }

    this.DanhMucQuanHuyenService.BaseParameter.ParentID = this.ThanhVienService.FormData.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.List = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        this.DanhMucXaPhuongSearch();        
      },
      err => {
      },
      () => {
        this.ThanhVienService.IsShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongSearch() {
    this.ThanhVienService.IsShowLoading = true;

    for (let i = 0; i < this.DanhMucQuanHuyenService.List.length; i++) {
      if (this.DanhMucQuanHuyenService.List[i].ID == this.ThanhVienVungTrongService.FormData.DanhMucQuanHuyenID) {
        this.ThanhVienVungTrongService.FormData.DanhMucQuanHuyenName = this.DanhMucQuanHuyenService.List[i].Name;
        i = this.DanhMucQuanHuyenService.List.length;
      }
    }

    this.DanhMucXaPhuongService.BaseParameter.ParentID = this.ThanhVienService.FormData.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.List = (res as DanhMucXaPhuong[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        let List = this.DanhMucXaPhuongService.List.filter((item: any) => item.Name == this.ThanhVienService.FormData.DanhMucXaPhuongName);
        if (List.length > 0) {
          this.ThanhVienService.FormData.DanhMucXaPhuongID = List[0].ID;
        }        
      },
      err => {
      },
      () => {
        this.ThanhVienService.IsShowLoading = false;
      }
    );
  }

  DanhMucXaPhuongChange() {
    for (let i = 0; i < this.DanhMucXaPhuongService.List.length; i++) {
      if (this.DanhMucXaPhuongService.List[i].ID == this.ThanhVienVungTrongService.FormData.DanhMucXaPhuongID) {
        this.ThanhVienVungTrongService.FormData.DanhMucXaPhuongName = this.DanhMucXaPhuongService.List[i].Name;
        i = this.DanhMucXaPhuongService.List.length;
      }
    }
  }
  
  ThanhVienListFilterSearch() {
    this.ThanhVienService.IsShowLoading = true;
    this.ThanhVienService.BaseParameter.ParentID = 3;
    this.ThanhVienService.GetByParentIDToListAsync().subscribe(
      res => {
        this.ThanhVienService.ListFilter = (res as ThanhVien[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        this.ThanhVienService.IsShowLoading = false;
      },
      err => {
        this.ThanhVienService.IsShowLoading = false;
      }
    );
  }
  DanhMucThanhVienSearch() {
    this.DanhMucThanhVienService.ComponentGetAllToListAsync(this.ThanhVienService);
  }
  DanhMucChungChiSearch() {
    this.DanhMucChungChiService.ComponentGetAllToListAsync(this.ThanhVienService);
  }
  DanhMucDanTocSearch() {
    this.DanhMucDanTocService.ComponentGetAllToListAsync(this.ThanhVienService);
  }
  DanhMucNgonNguSearch() {
    this.DanhMucNgonNguService.ComponentGetAllToListAsync(this.ThanhVienService);
  }
  DanhMucVungTrongSearch() {
    this.DanhMucVungTrongService.ComponentGetAllToListAsync(this.ThanhVienService);
  }
  DateCCCDNgayCap(value) {
    this.ThanhVienService.FormData.CCCDNgayCap = new Date(value);
  }
  DateNgaySinh(value) {
    this.ThanhVienService.FormData.NgaySinh = new Date(value);
  }
  DateAnToanVeSinhThucPhamNgayCap(value) {
    this.ThanhVienService.FormData.AnToanVeSinhThucPhamNgayCap = new Date(value);
  }
  DateNgayBatDau(value) {
    this.ThanhVienService.FormData.NgayBatDau = new Date(value);
  }
  DateVungTrongNgayCap(value) {
    this.ThanhVienService.FormData.VungTrongNgayCap = new Date(value);
  }
  DateVuonSamNgayCap(value) {
    this.ThanhVienService.FormData.VuonSamNgayCap = new Date(value);
  }
  DateChiDanDiaLyNgayCap(value) {
    this.ThanhVienService.FormData.VuonSamNgayCap = new Date(value);
  }

  ThanhVienSearch() {
    this.ThanhVienService.BaseParameter.ID = this.ThanhVienService.FormData.ID;
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.FormData = res as ThanhVien;
        if (this.ThanhVienService.FormData.ID == environment.InitializationNumber) {
          this.ThanhVienService.FormData.DanhMucQuocGiaID = environment.DanhMucQuocGiaIDVietNam;
          this.ThanhVienService.FormData.DanhMucTinhThanhID = environment.DanhMucTinhThanhID;
          this.ThanhVienService.FormData.DanhMucQuanHuyenID = environment.DanhMucQuanHuyenID;
        }
        this.DanhMucTinhThanhSearch();
        this.DanhMucThanhVienSearch();
        this.DanhMucChungChiSearch();
        this.DanhMucDanTocSearch();
        this.DanhMucNgonNguSearch();
        this.DanhMucVungTrongSearch();
        this.ThanhVienKhoSearch();
        this.ThanhVienLoHangSearch();
        this.ThanhVienListFilterSearch();
      },
      err => {
      }
    );
  }


  ThanhVienSave() {
    this.ThanhVienService.IsShowLoading = true;

    let ListDanhMucTinhThanh = this.DanhMucTinhThanhService.List.filter((item: any) => item.ID == this.ThanhVienService.FormData.DanhMucTinhThanhID);
    if (ListDanhMucTinhThanh.length > 0) {
      this.ThanhVienService.FormData.DanhMucTinhThanhName = ListDanhMucTinhThanh[0].Name;
    }

    let ListDanhMucQuanHuyen = this.DanhMucQuanHuyenService.List.filter((item: any) => item.ID == this.ThanhVienService.FormData.DanhMucQuanHuyenID);
    if (ListDanhMucQuanHuyen.length > 0) {
      this.ThanhVienService.FormData.DanhMucQuanHuyenName = ListDanhMucQuanHuyen[0].Name;
    }

    let ListDanhMucXaPhuong = this.DanhMucXaPhuongService.List.filter((item: any) => item.ID == this.ThanhVienService.FormData.DanhMucXaPhuongID);
    if (ListDanhMucXaPhuong.length > 0) {
      this.ThanhVienService.FormData.DanhMucXaPhuongName = ListDanhMucXaPhuong[0].Name;
    }
    this.ThanhVienService.FormData.ParentID = environment.DanhMucThanhVienID;
    this.ThanhVienService.SaveAsync().subscribe(
      res => {
        this.ThanhVienService.FormData = res as ThanhVien;
        this.NotificationService.warn(environment.SaveSuccess);
        this.ThanhVienService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.ThanhVienService.IsShowLoading = false;
      }
    );
  }

  ThanhVienTapTinDinhKemSearch() {
    this.ThanhVienTapTinDinhKemService.BaseParameter.ParentID = this.ThanhVienService.FormData.ID;
    this.ThanhVienTapTinDinhKemService.SearchByParentID(this.ThanhVienTapTinDinhKemSort, this.ThanhVienTapTinDinhKemPaginator);
  }
  ThanhVienTapTinDinhKemChangeFileName(files: FileList) {
    if (files) {
      this.ThanhVienTapTinDinhKemService.FileToUpload = files;
    }
  }
  ThanhVienTapTinDinhKemSave(element: ThanhVienTapTinDinhKem) {
    this.ThanhVienService.IsShowLoading = true;
    element.ParentID = this.ThanhVienService.FormData.ID;
    this.ThanhVienTapTinDinhKemService.FormData = element;
    this.ThanhVienTapTinDinhKemService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.ThanhVienTapTinDinhKemSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.ThanhVienService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.ThanhVienService.IsShowLoading = false;
      }
    );
  }
  ThanhVienTapTinDinhKemDelete(element: ThanhVienTapTinDinhKem) {
    this.ThanhVienTapTinDinhKemService.BaseParameter.ID = element.ID;
    this.ThanhVienTapTinDinhKemService.ComponentDeleteByParentID(this.ThanhVienTapTinDinhKemSort, this.ThanhVienTapTinDinhKemPaginator);
  }

  ThanhVienLoHangSearch() {
    this.ThanhVienLoHangService.BaseParameter.ParentID = this.ThanhVienService.FormData.ID;
    this.ThanhVienLoHangService.SearchByParentIDNotEmpty(this.ThanhVienLoHangSort, this.ThanhVienLoHangPaginator);
  }

  ThanhVienKhoSearch() {
    this.ThanhVienKhoService.BaseParameter.ParentID = this.ThanhVienService.FormData.ID;
    this.ThanhVienKhoService.SearchByParentID(this.ThanhVienKhoSort, this.ThanhVienKhoPaginator);
  }
  ThanhVienKhoSave(element: ThanhVienKho) {
    element.ParentID = this.ThanhVienService.FormData.ID;
    this.ThanhVienKhoService.FormData = element;
    this.ThanhVienKhoService.ComponentSaveByParentID(this.ThanhVienKhoSort, this.ThanhVienKhoPaginator);
  }
  ThanhVienKhoCopy(element: ThanhVienKho) {
    element.ID = environment.InitializationNumber;
    element.ParentID = this.ThanhVienService.FormData.ID;
    element.SortOrder = element.SortOrder + 10;
    this.ThanhVienKhoService.FormData = element;
    this.ThanhVienKhoService.ComponentSaveByParentID(this.ThanhVienKhoSort, this.ThanhVienKhoPaginator);
  }
  ThanhVienKhoDelete(element: ThanhVienKho) {
    this.ThanhVienKhoService.BaseParameter.ID = element.ID;
    this.ThanhVienKhoService.ComponentDeleteByParentID(this.ThanhVienKhoSort, this.ThanhVienKhoPaginator);
  }

  ThanhVienVungTrongSearch() {
    this.ThanhVienVungTrongService.BaseParameter.ParentID = this.ThanhVienService.FormData.ID;
    this.ThanhVienVungTrongService.SearchByParentIDNotEmpty(this.ThanhVienVungTrongSort, this.ThanhVienVungTrongPaginator);
  }
  ThanhVienVungTrongSave(element: ThanhVienVungTrong) {
    element.ParentID = this.ThanhVienService.FormData.ID;
    this.ThanhVienVungTrongService.FormData = element;
    this.ThanhVienVungTrongService.ComponentSaveByParentIDNotEmpty(this.ThanhVienVungTrongSort, this.ThanhVienVungTrongPaginator);
  }
  ThanhVienVungTrongDelete(element: ThanhVienVungTrong) {
    this.ThanhVienVungTrongService.BaseParameter.ID = element.ID;
    this.ThanhVienVungTrongService.ComponentDeleteByParentIDNotEmpty(this.ThanhVienVungTrongSort, this.ThanhVienVungTrongPaginator);
  }
  ThanhVienVungTrongAdd(ID: number) {
    this.ThanhVienVungTrongService.BaseParameter.ID = ID;
    this.ThanhVienVungTrongService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienVungTrongService.FormData = res as ThanhVien;
        this.ThanhVienVungTrongService.FormData.ParentID = this.ThanhVienService.FormData.ID;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ThanhVienVungTrongDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.ThanhVienVungTrongSearch();
        });
      },
      err => {
      }
    );
  }
  ThanhVienVungTrongToaDoAdd(ID: number) {
    this.ThanhVienVungTrongToaDoService.BaseParameter.ParentID = ID;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = environment.DialogConfigWidth;
    dialogConfig.data = { ID: ID };
    const dialog = this.dialog.open(ThanhVienVungTrongToaDoDetailComponent, dialogConfig);
    dialog.afterClosed().subscribe(() => {
    });

  }
  LoHangSearch() {
    this.ThanhVienService.IsShowLoading = true;
    this.LoHangService.BaseParameter.ParentID = this.ThanhVienService.FormData.TruyXuatNguonGocToChucID;
    this.LoHangService.BaseParameter.Active = true;
    this.LoHangService.GetByParentIDAndActiveToListAsync().subscribe(
      res => {
        this.LoHangService.List = (res as LoHang[]).sort((a, b) => (a.DoTuoi > b.DoTuoi ? 1 : -1));
        this.LoHangService.DataSource = new MatTableDataSource(this.LoHangService.List);
        this.LoHangService.DataSource.sort = this.LoHangSort;
        this.LoHangService.DataSource.paginator = this.LoHangPaginator;
        this.ThanhVienLoHangSearch();
        this.ThanhVienService.IsShowLoading = false;
      },
      err => {
        this.ThanhVienService.IsShowLoading = false;
      }
    );
  }
  LoHangAddRutGon(DoTuoi: number) {
    if (this.ThanhVienService.FormData.ID > 0) {
      this.LoHangService.BaseParameter.DanhMucNgonNguID = DoTuoi;
      this.LoHangService.BaseParameter.ParentID = this.ThanhVienService.FormData.ID;
      this.LoHangService.BaseParameter.ID = environment.InitializationNumber;
      this.LoHangService.GetByIDAsync().subscribe(
        res => {
          this.LoHangService.FormData = res as LoHang;
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.width = environment.DialogConfigWidth;
          dialogConfig.data = { ID: DoTuoi };
          const dialog = this.dialog.open(TruyXuatNguonGocLoHangDetailRutGon001Component, dialogConfig);
          dialog.afterClosed().subscribe(() => {
            this.LoHangSearch();
          });
        },
        err => {
        }
      );
    }
    else {
      this.NotificationService.warn(environment.UserNameNotExists);
    }
  }
  LoHangAddRutGonByID(ID: number) {
    if (this.ThanhVienService.FormData.ID > 0) {
      this.LoHangService.BaseParameter.ID = ID;
      this.LoHangService.GetByIDAsync().subscribe(
        res => {
          this.LoHangService.FormData = res as LoHang;
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.width = environment.DialogConfigWidth;
          dialogConfig.data = { ID: ID };
          const dialog = this.dialog.open(TruyXuatNguonGocLoHangDetailRutGon001Component, dialogConfig);
          dialog.afterClosed().subscribe(() => {
            this.LoHangSearch();
          });
        },
        err => {
        }
      );
    }
    else {
      this.NotificationService.warn(environment.UserNameNotExists);
    }
  }

  LoHangCopy(element: LoHang) {
    this.LoHangService.IsShowLoading = true;
    this.LoHangService.FormData = element;
    this.LoHangService.CopyAsync().subscribe(
      res => {
        this.LoHangSearch();
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangDelete(element: LoHang) {
    if (confirm(environment.DeleteConfirm)) {
      this.LoHangService.IsShowLoading = true;
      this.LoHangService.BaseParameter.ID = element.ID;
      this.LoHangService.RemoveAsync().subscribe(
        res => {
          this.LoHangSearch();
          this.LoHangService.IsShowLoading = false;
        },
        err => {
          this.LoHangService.IsShowLoading = false;
        }
      );
    }
  }
  LoHangAdd(ID: number) {
    this.LoHangService.IsShowLoading = true;
    this.LoHangService.BaseParameter.ID = ID;
    this.LoHangService.GetByIDAsync().subscribe(
      res => {
        this.LoHangService.FormData = res as LoHang;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(TruyXuatNguonGocLoHangDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.LoHangSearch();
        });
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }

  TemPhieuKhoiTaoSearch() {

    if (this.TemPhieuKhoiTaoService.BaseParameter.SearchString.length > 0) {
      this.TemPhieuKhoiTaoService.BaseParameter.SearchString = this.TemPhieuKhoiTaoService.BaseParameter.SearchString.trim();
      if (this.TemPhieuKhoiTaoService.DataSource) {
        this.TemPhieuKhoiTaoService.DataSource.filter = this.TemPhieuKhoiTaoService.BaseParameter.SearchString.toLowerCase();
      }
    }
    else {
      this.TemPhieuKhoiTaoService.IsShowLoading = true;
      this.TemPhieuKhoiTaoService.BaseParameter.ToChucID = this.ThanhVienService.FormData.TruyXuatNguonGocToChucID;
      this.TemPhieuKhoiTaoService.GetByToChucIDToListAsync().subscribe(
        res => {
          this.TemPhieuKhoiTaoService.List = (res as TemPhieuKhoiTao[]).sort((a, b) => (a.GhiNhan < b.GhiNhan ? 1 : -1));
          this.TemPhieuKhoiTaoService.DataSource = new MatTableDataSource(this.TemPhieuKhoiTaoService.List);
          this.TemPhieuKhoiTaoService.DataSource.sort = this.TemPhieuKhoiTaoSort;
          this.TemPhieuKhoiTaoService.DataSource.paginator = this.TemPhieuKhoiTaoPaginator;
          this.TemPhieuKhoiTaoService.IsShowLoading = false;
        },
        err => {
          this.TemPhieuKhoiTaoService.IsShowLoading = false;
        }
      );
    }
  }

  TemPhieuKhoiTaoDelete(element: TemPhieuKhoiTao) {
    if (confirm(environment.DeleteConfirm)) {
      if (element.ToChucID > 0) {
        this.NotificationService.warn(environment.TruyXuatNguonGocToChucExists);
      }
      else {
        this.TemPhieuKhoiTaoService.IsShowLoading = true;
        this.TemPhieuKhoiTaoService.BaseParameter.ID = element.ID;
        this.TemPhieuKhoiTaoService.RemoveAsync().subscribe(
          res => {
            this.TemPhieuKhoiTaoSearch();
            this.NotificationService.warn(environment.DeleteSuccess);
            this.TemPhieuKhoiTaoService.IsShowLoading = false;
          },
          err => {
            this.NotificationService.warn(environment.DeleteNotSuccess);
            this.TemPhieuKhoiTaoService.IsShowLoading = false;
          }
        );
      }
    }
  }

  TemPhieuKhoiTaoAdd(ID: number) {
    this.TemPhieuKhoiTaoService.IsShowLoading = true;
    this.TemPhieuKhoiTaoService.BaseParameter.ID = ID;
    this.TemPhieuKhoiTaoService.GetByIDAsync().subscribe(
      res => {
        this.TemPhieuKhoiTaoService.FormData = res as TemPhieuKhoiTao;
        this.TemPhieuKhoiTaoService.FormData.ToChucID = this.ThanhVienService.FormData.TruyXuatNguonGocToChucID;
        this.TemPhieuKhoiTaoService.FormData.Code = this.ThanhVienService.FormData.Code;
        this.TemPhieuKhoiTaoService.FormData.ToChucName = this.ThanhVienService.FormData.Name + " - " + this.ThanhVienService.FormData.CCCD + " - " + this.ThanhVienService.FormData.DanhMucXaPhuongName;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(TruyXuatNguonGocTemPhieuKhoiTaoDetail001Component, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.TemPhieuKhoiTaoSearch();
        });
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      },
      err => {
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      }
    );
  }

  Close() {
    this.dialogRef.close();
  }

  map: maplibregl.Map | undefined;

  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.map?.remove();
  }

  OpenWindowVeVungTrong() {
    let width = screen.width;
    let height = screen.height;
    let width01 = width - 100;
    let height01 = height - 200;
    let left = (width - width01) / 2;
    let top = (height - height01) / 3;
    let url = "/html/MapThanhVien.html?ID=" + this.ThanhVienService.FormData.ID;
    window.open(url, '_blank', 'location=yes, height=' + height01 + ',width=' + width01 + ', scrollbars=yes, status=yes, toolbar=yes, top=' + top + ', left=' + left + '');
  }

  MapInitialization(longitude, latitude) {
    let IDDate = new Date().toISOString();
    let zoom = environment.MapZoom;

    if ((latitude > 90) || (latitude == 0)) {
      latitude = environment.Latitude;
      longitude = environment.Longitude;
    }
    this.map = new maplibregl.Map({
      container: this.mapContainer.nativeElement,
      style: 'https://api.maptiler.com/maps/hybrid/style.json?key=' + environment.MaptilerAPIKey,
      center: [longitude, latitude],
      zoom: zoom,
    });

    this.map.addControl(
      new maplibregl.NavigationControl({
        visualizePitch: true,
        showZoom: true,
        showCompass: true
      })
    );
    this.map.addControl(
      new maplibregl.FullscreenControl({
      })
    );
    this.map.on('load', () => {

      this.map.addSource("HoangSa" + IDDate, {
        "type": "image",
        "url": environment.DomainURL + "assets/image/HoangSa01.png",
        "coordinates": [
          [111.09665858054495, 17.432475898867523],
          [113.11720985517763, 17.38420482529338],
          [112.79285037220984, 15.643938718432054],
          [110.88537855035554, 15.672592116966598],
        ]
      });
      this.map.addLayer({
        "id": "HoangSa" + IDDate,
        "source": "HoangSa" + IDDate,
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

      this.map.addSource("TruongSa" + IDDate, {
        "type": "image",
        "url": environment.DomainURL + "assets/image/TruongSa01.png",
        "coordinates": [
          [112.32373278444106, 12.236103169381323],
          [117.4620551483049, 11.606334626304161],
          [115.59654957671216, 7.357025445897818],
          [110.62186805246108, 7.811210355974268],


        ]
      });
      this.map.addLayer({
        "id": "TruongSa" + IDDate,
        "source": "TruongSa" + IDDate,
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

    });
  }

  MapLoad(ID: number) {
    this.ThanhVienService.IsShowLoading = true;
    this.ThanhVienVungTrongToaDoService.BaseParameter.ThanhVienID = this.ThanhVienService.FormData.ID;
    this.ThanhVienVungTrongToaDoService.GetSQLByThanhVienIDToListAsync().subscribe(
      res => {
        this.ThanhVienVungTrongToaDoService.List = (res as ThanhVienVungTrongToaDo[]);
        if (this.ThanhVienVungTrongToaDoService.List) {
          let longitude = environment.Longitude;
          let latitude = environment.Latitude;
          if (this.ThanhVienVungTrongToaDoService.List.length > 0) {
            let ListThanhVienVungTrongToaDoActive = this.ThanhVienVungTrongToaDoService.List.filter((item: any) => item.Active == true);
            if (ListThanhVienVungTrongToaDoActive.length > 0) {
            }
            else {
              ListThanhVienVungTrongToaDoActive = [];
              ListThanhVienVungTrongToaDoActive.push(this.ThanhVienVungTrongToaDoService.List[0]);
            }

            if (ListThanhVienVungTrongToaDoActive.length > 0) {
              longitude = this.DownloadService.GetKinhDo(Number(ListThanhVienVungTrongToaDoActive[0].KinhDo));
              latitude = this.DownloadService.GetViDo(Number(ListThanhVienVungTrongToaDoActive[0].ViDo));
            }
            this.MapInitialization(longitude, latitude);
            if (latitude <= 90) {

              let popupContent = "<div style='opacity: 0.8; text-align: center; background-color: transparent;'>";
              popupContent = popupContent + "<a class='link-primary form-label' href='#'><h1>" + this.ThanhVienService.FormData.Name + "</h1></a>";
              popupContent = popupContent + "</div>";

              let popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent)
                .setMaxWidth("600px");

              var el = document.createElement('div');
              el.style.backgroundImage = "url(" + environment.DomainURL + "assets/image/logo30.png)";
              el.style.width = '30px';
              el.style.height = '30px';

              let marker = new maplibregl.Marker({ element: el })
                .setLngLat([longitude, latitude])
                .setPopup(popup)
                .addTo(this.map);

            }
            let ListGroup = [];
            let ParentID = environment.InitializationNumber;
            for (let i = 0; i < this.ThanhVienVungTrongToaDoService.List.length; i++) {
              let ThanhVienVungTrongToaDo = this.ThanhVienVungTrongToaDoService.List[i];
              if (ThanhVienVungTrongToaDo.ParentID != ParentID) {
                ParentID = ThanhVienVungTrongToaDo.ParentID;
                ListGroup.push(ParentID);
              }
            }
            for (let i = 0; i < ListGroup.length; i++) {
              let ListThanhVienVungTrongToaDo = this.ThanhVienVungTrongToaDoService.List.filter((item: any) => item.ParentID == ListGroup[i]);
              if (ListThanhVienVungTrongToaDo.length > 0) {
                let listToaDoPolygon = [];
                let listPolygon = [];
                for (let j = 0; j < ListThanhVienVungTrongToaDo.length; j++) {
                  let longitudeSub1 = this.DownloadService.GetKinhDo(Number(ListThanhVienVungTrongToaDo[j].KinhDo));
                  let latitudeSub1 = this.DownloadService.GetViDo(Number(ListThanhVienVungTrongToaDo[j].ViDo));
                  listToaDoPolygon.push([longitudeSub1, latitudeSub1]);
                  listPolygon.push(listToaDoPolygon);
                }
                let IDDate = new Date().toISOString();
                let layerID = 'Layer_' + ListGroup[i] + IDDate;
                let sourceID = 'Source_' + ListGroup[i] + IDDate;
                this.map.on('load', () => {
                  this.map.addSource(sourceID, {
                    'type': 'geojson',
                    'data': {
                      'type': 'Feature',
                      'properties': {
                        "name": "",
                        "address": "",
                      },
                      'geometry': {
                        'type': 'Polygon',
                        'coordinates': listPolygon,
                      }
                    }
                  });
                  let color = this.DownloadService.GetRandomColor(listToaDoPolygon.length);
                  this.map.addLayer({
                    'id': layerID,
                    'type': 'fill',
                    'source': sourceID,
                    'paint': {
                      'fill-color': color,
                      'fill-opacity': 0.5,
                      'fill-outline-color': color,
                    }
                  });
                });
              }
            }
          }
          else {
            this.MapInitialization(longitude, latitude);
          }
        }
      },
      err => {
        this.ThanhVienService.IsShowLoading = false;
      },
      () => {
        this.ThanhVienService.IsShowLoading = false;
      },
    );
  }
}