import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { LoHang } from 'src/app/shared/TruyXuatNguonGoc/LoHang.model';
import { LoHangService } from 'src/app/shared/TruyXuatNguonGoc/LoHang.service';
import { LoHangMuaBan } from 'src/app/shared/TruyXuatNguonGoc/LoHangMuaBan.model';
import { LoHangMuaBanService } from 'src/app/shared/TruyXuatNguonGoc/LoHangMuaBan.service';

import { TruyXuatNguonGocToChuc } from 'src/app/shared/TruyXuatNguonGoc/ToChuc.model';
import { TruyXuatNguonGocToChucService } from 'src/app/shared/TruyXuatNguonGoc/ToChuc.service';
import { TruyXuatNguonGocDanhMucUngDung } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.model';
import { TruyXuatNguonGocDanhMucUngDungService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucUngDung.service';
import { DanhMucPhuongThucMuaBan } from 'src/app/shared/TruyXuatNguonGoc/DanhMucPhuongThucMuaBan.model';
import { DanhMucPhuongThucMuaBanService } from 'src/app/shared/TruyXuatNguonGoc/DanhMucPhuongThucMuaBan.service';

@Component({
  selector: 'app-truy-xuat-nguon-goc-lo-hang-mua-ban',
  templateUrl: './truy-xuat-nguon-goc-lo-hang-mua-ban.component.html',
  styleUrls: ['./truy-xuat-nguon-goc-lo-hang-mua-ban.component.css']
})
export class TruyXuatNguonGocLoHangMuaBanComponent implements OnInit {

  @ViewChild('LoHangMuaBanSort') LoHangMuaBanSort: MatSort;
  @ViewChild('LoHangMuaBanPaginator') LoHangMuaBanPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public LoHangService: LoHangService,
    public LoHangMuaBanService: LoHangMuaBanService,
    public TruyXuatNguonGocToChucService: TruyXuatNguonGocToChucService,
    public TruyXuatNguonGocDanhMucUngDungService: TruyXuatNguonGocDanhMucUngDungService,
    public DanhMucPhuongThucMuaBanService: DanhMucPhuongThucMuaBanService,

  ) { }

  ngOnInit(): void {
    this.TruyXuatNguonGocDanhMucUngDungService.GetLogin();
    this.ToChucSearch();
    this.DanhMucPhuongThucMuaBanSearch();
  }

  DateLoHangMuaBanNgayGhiNhan(element: LoHangMuaBan, value) {
    element.NgayGhiNhan = new Date(value);
  }
  DanhMucPhuongThucMuaBanSearch() {
    this.DanhMucPhuongThucMuaBanService.ComponentGetAllToListAsync(this.LoHangMuaBanService);
  }
  LoHangSearch(ParentID: number) {
    this.LoHangService.BaseParameter.ParentID = ParentID;
    this.LoHangService.BaseParameter.Active = true;
    this.LoHangService.ComponentGetByParentIDAndActiveToListAsync(this.LoHangMuaBanService);
  }
  LoHangFilter(searchString: string) {
    this.LoHangService.Filter(searchString);
  }
  ToChucSearch() {
    this.TruyXuatNguonGocToChucService.ComponentGetByDanhMucUngDungIDAndActiveAndPageAndPageSizeToListAsync(this.LoHangMuaBanService);
  }
  ToChucFilter(searchString: string) {
    this.TruyXuatNguonGocToChucService.FilterDanhMucUngDungIDAndActive(searchString, this.LoHangMuaBanService);
  }
  ToChucChange(ParentID: number) {
    this.LoHangSearch(ParentID);
  }
  LoHangMuaBanSearch() {
    if (this.LoHangMuaBanService.BaseParameter.SearchString.length > 0) {
      this.LoHangMuaBanService.BaseParameter.SearchString = this.LoHangMuaBanService.BaseParameter.SearchString.trim();
      this.LoHangMuaBanService.IsShowLoading = true;
      this.LoHangMuaBanService.GetBySearchStringAndEmptyToListAsync().subscribe(
        res => {
          this.LoHangMuaBanService.List = (res as LoHangMuaBan[]).sort((a, b) => (a.NgayGhiNhan < b.NgayGhiNhan ? 1 : -1));
          this.LoHangMuaBanService.DataSource = new MatTableDataSource(this.LoHangMuaBanService.List);
          this.LoHangMuaBanService.DataSource.sort = this.LoHangMuaBanSort;
          this.LoHangMuaBanService.DataSource.paginator = this.LoHangMuaBanPaginator;
          this.LoHangMuaBanService.IsShowLoading = false;
        },
        err => {
          this.LoHangService.IsShowLoading = false;
        }
      );
    }
    else {
      this.LoHangMuaBanService.IsShowLoading = true;
      this.LoHangMuaBanService.GetByToChucIDAndEmptyToListAsync().subscribe(
        res => {
          this.LoHangMuaBanService.List = (res as LoHangMuaBan[]).sort((a, b) => (a.NgayGhiNhan < b.NgayGhiNhan ? 1 : -1));
          this.LoHangMuaBanService.DataSource = new MatTableDataSource(this.LoHangMuaBanService.List);
          this.LoHangMuaBanService.DataSource.sort = this.LoHangMuaBanSort;
          this.LoHangMuaBanService.DataSource.paginator = this.LoHangMuaBanPaginator;
          this.LoHangMuaBanService.IsShowLoading = false;
        },
        err => {
          this.LoHangService.IsShowLoading = false;
        }
      );
    }
  }
  LoHangMuaBanSave(element: LoHangMuaBan) {
    this.LoHangMuaBanService.FormData = element;
    this.LoHangMuaBanService.IsShowLoading = true;
    this.LoHangMuaBanService.SaveAsync().subscribe(
      res => {
        element = res as LoHangMuaBan;
        this.LoHangMuaBanSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.LoHangMuaBanService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.LoHangMuaBanService.IsShowLoading = false;
      }
    );
  }
  LoHangMuaBanDelete(element: LoHangMuaBan) {
    this.LoHangMuaBanService.BaseParameter.ID = element.ID;
    this.LoHangMuaBanService.IsShowLoading = true;
    this.LoHangMuaBanService.RemoveAsync().subscribe(
      res => {
        element = res as LoHangMuaBan;
        this.LoHangMuaBanSearch();
        this.NotificationService.warn(environment.DeleteSuccess);
        this.LoHangMuaBanService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.DeleteNotSuccess);
        this.LoHangMuaBanService.IsShowLoading = false;
      }
    );
  }

}
