import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/TruyXuatNguonGoc/Download.service';

import { TemPhieu } from 'src/app/shared/TruyXuatNguonGoc/TemPhieu.model';
import { TemPhieuService } from 'src/app/shared/TruyXuatNguonGoc/TemPhieu.service';
import { TemPhieuLichSuTruyCap } from 'src/app/shared/TruyXuatNguonGoc/TemPhieuLichSuTruyCap.model';
import { TemPhieuLichSuTruyCapService } from 'src/app/shared/TruyXuatNguonGoc/TemPhieuLichSuTruyCap.service';

@Component({
  selector: 'app-truy-xuat-nguon-goc-tem-phieu-lich-su-truy-cap-detail',
  templateUrl: './truy-xuat-nguon-goc-tem-phieu-lich-su-truy-cap-detail.component.html',
  styleUrls: ['./truy-xuat-nguon-goc-tem-phieu-lich-su-truy-cap-detail.component.css']
})
export class TruyXuatNguonGocTemPhieuLichSuTruyCapDetailComponent implements OnInit {

  @ViewChild('TemPhieuLichSuTruyCapSort') TemPhieuLichSuTruyCapSort: MatSort;
  @ViewChild('TemPhieuLichSuTruyCapPaginator') TemPhieuLichSuTruyCapPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<TruyXuatNguonGocTemPhieuLichSuTruyCapDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public TemPhieuService: TemPhieuService,
    public TemPhieuLichSuTruyCapService: TemPhieuLichSuTruyCapService,

  ) { }

  ngOnInit(): void {
    this.TemPhieuLichSuTruyCapSearch();
  }
  TemPhieuLichSuTruyCapSearch() {
    this.TemPhieuLichSuTruyCapService.IsShowLoading = true;
    this.TemPhieuLichSuTruyCapService.BaseParameter.ParentID = this.TemPhieuService.FormData.ID;
    this.TemPhieuLichSuTruyCapService.GetByParentIDToListAsync().subscribe(
      res => {
        this.TemPhieuLichSuTruyCapService.List = (res as TemPhieuLichSuTruyCap[]).sort((a, b) => (a.LastUpdatedDate < b.LastUpdatedDate ? 1 : -1));
        this.TemPhieuLichSuTruyCapService.ListFilter = this.TemPhieuService.List.filter(item => item.ID > 0);
        this.TemPhieuLichSuTruyCapService.DataSource = new MatTableDataSource(this.TemPhieuLichSuTruyCapService.List);
        this.TemPhieuLichSuTruyCapService.DataSource.sort = this.TemPhieuLichSuTruyCapSort;
        this.TemPhieuLichSuTruyCapService.DataSource.paginator = this.TemPhieuLichSuTruyCapPaginator;
        this.TemPhieuLichSuTruyCapService.IsShowLoading = false;
      },
      err => {
        this.TemPhieuLichSuTruyCapService.IsShowLoading = false;
      }
    );
  }
  Close() {
    this.dialogRef.close();
  }
}